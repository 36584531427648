import { forwardRef } from 'react';

export const CustomDateInput = forwardRef(({ value, onChange, openCalendar }, ref) => (
    <div className="input-group">
        <div>
            <span className="input-group-addon">
                <i className="la la-calendar" />
            </span>
        </div>
        <input
            onChange={onChange}
            onClick={openCalendar}
            type="text"
            value={value}
            className="form-control"
            id={`date-${value}`}
            placeholder="Selecciona una fecha..."
        />
    </div>
));