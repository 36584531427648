
export const Table = ({ headers, columnsData, pagination }) => {
    return (
        <div
            className="table-responsive table-scroll padding-right-10 over-hidden-outline-none mb-4"
        >
            <table className="table table-sm mb-0 table-hover">
                <thead>
                    {headers}
                </thead>
                <tbody className="border-top-0">
                    {columnsData && columnsData.length > 0 ?
                        columnsData
                        :
                        <span className="p-10">No se han encontrado resultados</span>
                    }
                </tbody>
            </table>
            {pagination}
        </div>
    );
}