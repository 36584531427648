import { createApi } from '@reduxjs/toolkit/query/react';
// import { setMe } from "state/authSlice";
import customFetchBase from './customFetchBase';

export const loggedUserApi = createApi({
  reducerPath: 'loggedUserApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getMe: builder.query({
      query() {
        return {
          url: "me",
          credentials: "include",
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        //   dispatch(setMe(data));
        } catch (error) {
          console.error(error);
        }  
      },
    }),
    changePassword: builder.mutation({
      query(data) {
        return {
          url: `change-password`,
          method: 'PUT',
          body: data,
          credentials: 'include',
        };
      },
    }),

  }),
});

export const {
  useGetMeQuery,
  useChangePasswordMutation
} = loggedUserApi;
