import { useEffect, useState } from 'react';
import Select from "react-select";
import { useDispatch, useSelector } from "state";
import reactSelectCustomProps from "utils/reactSelectCustomProps";
import { getMaterialNameById } from "utils/utils";

export const MaterialSelect = ({
    requestedMaterial,
    newMaterialType,
    disabled,
    chosenInventoriesForMaterial,
    setChosenInventoriesForMaterial,
    previouslyAddedMaterialAndInventory,
    materialsWithEmptyInventories,
    inventory,
    setMaterialsWithNullInventory
}) => {
    // TODO: wtf is this doing in the requests selector?
    const {
        trailerTypes,
        vanTypes,
        boatTypes,
        inventories,
        materialDatesHaveChanged
    } = useSelector("requests");

    const dispatch = useDispatch();

    const materialType = newMaterialType || requestedMaterial?.type || requestedMaterial?.material.type;
    const materials = [...trailerTypes, ...boatTypes, ...vanTypes];
    const removeButtonClassname = "la la-close" + (!disabled ? " clickable" : "");

    /**
     * Converts a material object to a selectable option object.
     * @param {Object} material - The material to be converted.
     * @returns the option.
     */
    const convertMaterialTypeToOption = (material) => {
        let option = null;

        if (material) {
            option = {
                label: material.brand + ' - ' + material.model,
                value: material?.id
            } // option
        } // if

        return option;
    } // convertMaterialTypeToOption

    /**
     * Converts an inventory to an option.
     * @returns the inventory as an option.
    */
    const convertDefaultInventoryToOption = (inventory) => {

        let option = null;

        if (inventory) {
            option = {
                label: inventory?.numberPlate,
                value: inventory?.id
            }; // option
        } // if

        return option;
    } // convertInventoryToOptionsArray

    /**
     * Converts the material list as an option array.
     * @returns {Array} the converted material list.
     */
    const getMaterialsAsOptions = () => {
        let options = [];

        let filteredMaterialsByType = materials.filter(material => material.type === materialType);
        filteredMaterialsByType = filteredMaterialsByType.sort((a, b) => a.brand.localeCompare(b.brand) || a.model - b.model);

        filteredMaterialsByType.forEach(material => {
            if (
                material.enabled && !material.deleted // Ensures that it's available for selection
                && material.type === materialType  // Ensures that only materials of the given type are shown
                && !(
                    chosenInventoriesForMaterial?.hasOwnProperty(material.id)
                    ||
                    previouslyAddedMaterialAndInventory?.hasOwnProperty(material.id)
                ) // Avoids reselecting an already-selected material
            ) {
                options.push(convertMaterialTypeToOption(material));
            } // if
        }); // forEach

        return options;
    } // getMaterialsAsOptions

    const [selectedMaterialTypeId, setSelectedMaterialTypeId] = useState(requestedMaterial?.id);
    const [hideContainer, setHideContainer] = useState(false);
    const [availableMaterials, setAvailableMaterials] = useState(getMaterialsAsOptions());
    /**
     * Returns the inventories which match the selected material id as an array of options.
     * @returns {Array} The converted inventories.
     */
    const getInventoriesAsOptions = () => {
        let options = [];

        if (selectedMaterialTypeId) {

            let inventoriesAccordingToMaterial = 
                inventories?.filter(inventory => inventory.material.id === selectedMaterialTypeId);
            inventoriesAccordingToMaterial.sort((a, b) => a.numberPlate.localeCompare(b.numberPlate));

            inventoriesAccordingToMaterial.forEach(inventory => {
                if (inventories)
                    options.push({
                        label: inventory.numberPlate,
                        value: inventory.id
                    }); // push
            }); // forEach
        } // if

        return options;
    } // getInventoriesAsOptions

    /**
     * Handles the selection of a new material in the select component.
     * @param {Object} newOption - A label-value object with the new option.
     */
    const onMaterialSelectChange = (newOption) => {

        // Saves the previous value and saves the new one
        const previousValue = selectedMaterialTypeId;
        const id = newOption.value;
        setSelectedMaterialTypeId(id);

        // Sets the selected option
        const material = materials.find(material => material.id === id);
        setSelectedMaterialOption(convertMaterialTypeToOption(material));

        // Deletes the previous option from the map and sets the selected inventories for that type to an empty array
        let selectedMaterialAndInventories = { ...chosenInventoriesForMaterial };
        selectedMaterialAndInventories[id] = null;

        delete selectedMaterialAndInventories[previousValue.toString()];

        // Saves the new map and sets the multi-select options to an empty array 
        setChosenInventoriesForMaterial(selectedMaterialAndInventories);
        setSelectedInventory(null);
    } // onSelectChange

    /**
     * Handles the change on the inventories component.
     * @param {Object} newValue - The new option.
     */
    const onInventorySelectChange = (newValue) => {

        let selectedMaterialAndInventories = { ...chosenInventoriesForMaterial };

        selectedMaterialAndInventories[selectedMaterialTypeId] = newValue?.value;
        setChosenInventoriesForMaterial(selectedMaterialAndInventories);
        setSelectedInventory(newValue);
        dispatch({ type: 'MATERIAL_DATES_HAVE_CHANGED', payload: false });
        setMaterialsWithNullInventory([]);
    }; // onMultiSelectChange

    /**
     * Hides the component and removes the material from the map.
     */
    const onRemove = () => {
        if (!disabled) {
            let selectedMaterialAndInventories = { ...chosenInventoriesForMaterial };
            delete selectedMaterialAndInventories[selectedMaterialTypeId.toString()];
            setChosenInventoriesForMaterial(selectedMaterialAndInventories);
            setHideContainer(true);
        }
    } // onRemove

    const [selectedMaterialOption, setSelectedMaterialOption] = useState(convertMaterialTypeToOption(requestedMaterial));
    const [selectedInventory, setSelectedInventory] = useState(convertDefaultInventoryToOption(inventory));

    /**
     * Handles the selection of a new material type 
     * and automatically the first available inventory is assigned.
     */
    useEffect(() => {
        if (newMaterialType) {

            const defaultOption = availableMaterials[0];
            setSelectedMaterialOption(defaultOption);

            const defaultId = defaultOption?.value;
            setSelectedMaterialTypeId(defaultId);

            let newlyAddedMaterialAndInventory = { ...chosenInventoriesForMaterial };
            newlyAddedMaterialAndInventory[defaultId] = null;
            setChosenInventoriesForMaterial(newlyAddedMaterialAndInventory);

            let availableMaterialsCopy = getMaterialsAsOptions(newlyAddedMaterialAndInventory);
            setAvailableMaterials(availableMaterialsCopy);

        } // if
    }, [newMaterialType]);

    /**
     * Removes all selected inventories when the date changes
     */
    useEffect(() => {
        if (materialDatesHaveChanged) {
            setSelectedInventory(null);
        } // if
    }, [materialDatesHaveChanged]);

    return (
        <div className={hideContainer ? "d-none" : ""}>
            <div className={newMaterialType ? "row d-flex align-items-center mt-3 mb-5" : "row d-flex align-items-center mb-4 mt-3"}>
                <label className="col-lg-3 form-control-label">
                    {getMaterialNameById(materialType)}
                </label>
                <div className="col-lg-4">
                    {/* Material selection */}
                    <Select
                        styles={reactSelectCustomProps}
                        options={availableMaterials}
                        noOptionsMessage={() => "No hay opciones disponibles"}
                        escapeClearsValue={true}
                        isDisabled={disabled}
                        placeholder="Seleccione un tipo de material..."
                        value={selectedMaterialOption}
                        onChange={(newOption) => onMaterialSelectChange(newOption)}
                    />
                </div>
                <div className="col-lg-4">
                    <div className="dropdown bootstrap-select show-tick show-menu-arrow w-100">
                        {/* Inventory selection */}
                        <Select
                            styles={reactSelectCustomProps}
                            options={getInventoriesAsOptions()}
                            noOptionsMessage={() => "No hay opciones disponibles"}
                            value={selectedInventory}
                            isClearable={true}
                            isDisabled={disabled}
                            placeholder="Seleccione un inventario..."
                            onChange={(newValues) => {
                                onInventorySelectChange(newValues);
                            }} // onChange
                        />

                        {
                            materialsWithEmptyInventories.includes(selectedMaterialTypeId) &&
                            <div style={{ paddingTop: '0.5rem', textAlign: 'center' }}>
                                <label style={{ color: 'red', fontSize: '1rem' }}>
                                    Seleccione un inventario
                                </label>
                            </div>
                        }
                    </div>
                </div>
                <div className="col-lg-1">
                    <i onClick={onRemove} className={removeButtonClassname} />
                </div>
            </div>
        </div>
    );
}