import { deleteMethod, get, post, put } from "api/client";
import { convertToInputDateFormat } from "utils/dateUtils";

export const getAllRooms = async (dispatch, pageIndex, pageSize, criteria) => {
  try {
    dispatch({ type: "GET_ROOMS" });
    let urlQuery = `rooms/by-criteria?pageIndex=${pageIndex}&pageSize=${pageSize}`;

    if (criteria) {
      urlQuery += `&criteria=${criteria}`
    }
    const data = await get(urlQuery);
    //endpoint call
    dispatch({ type: "GET_ROOMS_SUCCESS", payload: data }); // loading: false y members: action.payload
  } catch (error) {

    dispatch({
      type: "GET_ROOMS_FAILURE",
      error: { code: 500, description: error },
    }); //loading: false, stocks: []
  }
};

export const persistRoom = async (dispatch, data) => {
  try {
    dispatch({ type: "PERSIST_ROOM" });
    let response = null
    if (data.id) {
      response = await put(`rooms/${data.id}`, data);
    } else {
      response = await post(`rooms`, data);
    }

    if (response.status === 204 || response.status === 201) {
      dispatch({ type: "PERSIST_ROOM_SUCCESS" });
    } else {
      dispatch({ type: "PERSIST_ROOM_FAILURE", payload: response });
    }
    //TODO: Tratamiento de errores centralizado
  } catch (error) {

    dispatch({ type: "PERSIST_ROOM_FAILURE", payload: error });
  }
};

export const getRoom = async (dispatch, id) => {
  try {
    dispatch({ type: "GET_ROOM" });
    const response = await get(`rooms/${id}`);


    dispatch({ type: "GET_ROOM_SUCCESS", payload: response });
  } catch (error) {

    dispatch({ type: "GET_ROOM_FAILURE" });
  }
};


export const deleteRoom = async (dispatch, id) => {
  try {
    dispatch({ type: "DELETE_ROOM" });
    const response = await deleteMethod(`rooms/${id}`);

    if (response.status === 204 || response.status === 201) {
      dispatch({ type: "DELETE_ROOM_SUCCESS" });
    } else {
      dispatch({ type: "DELETE_ROOM_FAILURE", payload: response });
    }
    //TODO: Tratamiento de errores centralizado
  } catch (error) {
    dispatch({ type: "DELETE_ROOM_FAILURE", payload: error });
  }
};

export const getAvailableRoomsFromRequest = async (dispatch, request) => {
  try {
    dispatch({ type: 'GET_AVAILABLE_ROOMS' });
    const requestId = request.id;
    const checkinDate = convertToInputDateFormat(request.roomCheckInDate);
    const checkoutDate = convertToInputDateFormat(request.roomCheckOutDate);
    
    const response = await get(`rooms/available-rooms?request-id=${requestId}&checkin-date=${checkinDate}&checkout-date=${checkoutDate}`);
    dispatch({ type: "GET_AVAILABLE_ROOMS_SUCCESS", payload: response });
  } catch (error) {
    dispatch({ type: "GET_AVAILABLE_ROOMS_FAILURE", payload: error });
  }
};

export const getRoomsWithOccupation = async (dispatch, request) => {
  try {
    dispatch({ type: 'GET_ROOMS_WITH_OCCUPATION' });
    const checkinDate = convertToInputDateFormat(request.roomCheckInDate);
    const checkoutDate = convertToInputDateFormat(request.roomCheckOutDate);
    const response = await get(`rooms/occupation?checkin-date=${checkinDate}&checkout-date=${checkoutDate}`);
    dispatch({ type: "GET_ROOMS_WITH_OCCUPATION_SUCCESS", payload: response });
  } catch (error) {
    dispatch({ type: "GET_ROOMS_WITH_OCCUPATION_FAILURE", payload: error });
  }
};

export const getRoomsWithOccupationByDates = async (dispatch, startingDate, endingDate) => {
  try {
    dispatch({ type: 'GET_OCCUPATION_BY_DATE_RANGE' });
    startingDate = convertToInputDateFormat(startingDate);
    endingDate = convertToInputDateFormat(endingDate);
    const response = await get(`rooms/occupation-by-date?starting-date=${startingDate}&ending-date=${endingDate}`);

    dispatch({ type: "GET_OCCUPATION_BY_DATE_RANGE_SUCCESS", payload: response });
  } catch (error) {
    dispatch({ type: "GET_OCCUPATION_BY_DATE_RANGE_FAILURE", payload: error });
  }
};

export const getGuestsInRoomInDate = async (dispatch, roomId, date) => {
  try {
    dispatch({ type: 'GET_GUESTS_IN_ROOM_IN_DATE' });
    date = convertToInputDateFormat(date);
    const response = await get (`rooms/${roomId}/guests?date=${date}`);
    dispatch({ type: "GET_GUESTS_IN_ROOM_IN_DATE_SUCCESS", payload: response });
  } catch(error) {
    dispatch({type: 'GET_GUESTS_IN_ROOM_IN_DATE', payload: error})
  }
}