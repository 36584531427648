export const requestStatus = {
    CONFIRMATION_PENDING: "CONFIRMATION_PENDING",
    CONFIRMED_AND_ACCEPTANCE_PENDING: "CONFIRMED_AND_ACCEPTANCE_PENDING",
    ACCEPTED: "ACCEPTED",
    CANCELLED: "CANCELLED"
}

export const materials = {
    BOAT: "BOAT",
    TRAILER: "TRAILER",
    VAN: "VAN"
}

export const weekDays = ["Lun", "Mar", "Mié", "Jue", "Vie", "Sáb", "Dom"]
export const months = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dec"]

export const requestDateFilters = [
    {
        value: 'createdDate', id: 'created_date', label: 'Solicitud', placeHolderFrom: "Fecha desde", placeHolderUntil: "Fecha hasta"
    },
    {
        value: 'materialCollectionDate', id: 'r_material_collection_date', label: 'Material', placeHolderFrom: "Fecha recogida", placeHolderUntil: "Fecha entrega"
    },
    {
        value: 'roomCheckInDate', id: 'r_room_check_in_date', label: 'Habitación', placeHolderFrom: "Fecha entrada", placeHolderUntil: "Fecha salida"
    }
]

export const requestStatusLabels = {
    CONFIRMATION_PENDING: "Pendiente",
    CONFIRMED_AND_ACCEPTANCE_PENDING: "Prereserva",
    ACCEPTED: "Aprobado",
    CANCELLED: "Cancelado"
}

export const requestStatusFilters = [
    {
        value: requestStatus.ACCEPTED, label: requestStatusLabels.ACCEPTED
    },
    {
        value: requestStatus.CANCELLED, label: requestStatusLabels.CANCELLED
    },
    {
        value: requestStatus.CONFIRMATION_PENDING, label: requestStatusLabels.CONFIRMATION_PENDING
    },
    {
        value: requestStatus.CONFIRMED_AND_ACCEPTANCE_PENDING, label: requestStatusLabels.CONFIRMED_AND_ACCEPTANCE_PENDING
    }
]