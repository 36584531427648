import VanTypeForm from 'components/Form/Entity/VanTypeForm';
import BoatTypeForm from 'pages/boats/types/BoatTypeForm';
import RoomForm from 'pages/rooms/form';
import TrailerTypeForm from 'pages/trailers/types/form';
import UserForm from 'pages/user/form';
import { useEffect } from 'react';
import { materials, requestDateFilters, requestStatus, requestStatusLabels } from "utils/enums";

// TODO: use locale json
export const getMaterialNameById = (materialId) => {
    if (materialId === materials.BOAT) {
        return 'Embarcación'
    } else if (materialId === materials.VAN) {
        return 'Furgoneta'
    } else if (materialId === materials.TRAILER) {
        return 'Remolque'
    } // if-else if

    return null;
} // getMaterialNameById

export const getStatusNameById = (status) => {
    if (status === requestStatus.CONFIRMATION_PENDING) {
        return requestStatusLabels.CONFIRMATION_PENDING
    } else if (status === requestStatus.CONFIRMED_AND_ACCEPTANCE_PENDING) {
        return requestStatusLabels.CONFIRMED_AND_ACCEPTANCE_PENDING
    } else if (status === requestStatus.ACCEPTED) {
        return requestStatusLabels.ACCEPTED
    } else {
        //CANCELLED
        return requestStatusLabels.CANCELLED
    }
}

export const useOutsideAlerter = (ref, action) => {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                action()
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

export const verifyIfObjectIsNullish = (object) => {
    return object === undefined || object === null || object === '';
}

export const getPlaceHolderDates = (dateType) => {
    return requestDateFilters.find((data) => data.id === dateType.id)
}

export const regularExpression = {
    // TODO: check issue -> There is nothing available for repetition. ts(1507)
    PASSWORD: /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/
}


export const PAGE_SIZE = 10;
export const TYPES_TAB = 0;
export const INVENTORY_TAB = 1;

export const forms = {
    boat: BoatTypeForm,
    trailer: TrailerTypeForm,
    van: VanTypeForm,
    user: UserForm,
    room: RoomForm
} // forms

export const PAGE_TITLES = {
    boat: "Embarcaciones",
    trailer: "Remolques",
    van: "Furgonetas",
    user: "Usuarios",
    room: "Habitaciones"
} // PAGE_TITLES