import { getMaterialNameById } from "utils/utils";
import '../invoice.css';

import moment from "moment";
import 'moment/locale/es';
moment.locale('es');

export const MaterialTable = (props) => {
    return (
        <div className="col-xl-12 desc-tables">
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th className="text-left">Material</th>
                            <th className="text-center">Unidades</th>
                            <th className="text-center">Recogida</th>
                            <th className="text-center">Entrega</th>
                        </tr>
                    </thead>
                    <tbody className="table-body-border-none">
                        {props.materials.map((materialObject) => (
                            <tr key={materialObject.id}>
                                <td className="text-left">
                                    {getMaterialNameById(materialObject.material.type) + ": " + materialObject.material.brand + " - " + materialObject.material.model}
                                    <br></br>
                                    <div className="description">
                                        {"REC: " + props.materialCollectionPlace + " - ENT: " + props.materialReturnPlace}
                                    </div>

                                </td>
                                <td className="text-center">{materialObject.units}</td>
                                <td className="text-center">{moment(props.materialCollectionDate, moment.ISO_8601).format("DD.MM.YYYY")}</td>
                                <td className="text-center">{moment(props.materialReturnDate, moment.ISO_8601).format("DD.MM.YYYY")}</td>
                            </tr>
                        ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}