import { deleteMethod, formDataRequest, get } from "api/client";


export const getAllTypes = async (dispatch, type, pageIndex, pageSize, criteria) => {
  try {
    dispatch({ type: "GET_TYPES_CRITERIA" }); // stocks: [], loading: true
    let urlQuery = `types/by-criteria?material-type-code=${type}&pageIndex=${pageIndex}&pageSize=${pageSize}`;

    if (criteria) {
      urlQuery += `&criteria=${criteria}`
    }
    const data = await get(urlQuery);

    // endpoint call
    dispatch({ type: "GET_TYPES_CRITERIA_SUCCESS", payload: data }); // loading: false y members: action.payload
  } catch (error) {
    dispatch({
      type: "GET_TYPES_CRITERIA_FAILURE",
      error: { code: 500, description: error },
    }); //loading: false, stocks: []
  } // try-catch
}; // getAllTypes

export const getEnabledTypes = async (dispatch, type) => {
  try {
    dispatch({ type: "GET_TYPES_ENABLED" }); // stocks: [], loading: true
    const data = await get(`types/by-type?material-type-code=${type}&enabled=true`);
    //endpoint call
    dispatch({ type: "GET_TYPES_ENABLED_SUCCESS", payload: data }); // loading: false y members: action.payload
  } catch (error) {

    dispatch({
      type: "GET_TYPES_ENABLED_FAILURE",
      error: { code: 500, description: error },
    }); //loading: false, stocks: []
  }
};

export const getType = async (dispatch, id) => {
  try {
    dispatch({ type: "GET_TYPE" });
    const response = await get(`types/${id}`);

    dispatch({ type: "GET_TYPE_SUCCESS", payload: response });
  } catch (error) {

    dispatch({ type: "GET_TYPE_FAILURE" });
  }
};


export const deleteType = async (dispatch, id) => {
  try {
    dispatch({ type: "DELETE_TYPE" });
    const response = await deleteMethod(`types/${id}`);

    if (response.status === 204 || response.status === 201) {
      dispatch({ type: "DELETE_TYPE_SUCCESS" });
    } else {
      dispatch({ type: "DELETE_TYPE_FAILURE", payload: response });
    }
    //TODO: Tratamiento de errores centralizado
  } catch (error) {

    dispatch({ type: "DELETE_TYPE_FAILURE", payload: error });
  }
};

export const persistType = async (dispatch, data) => {
  const materialType = data.type.toLowerCase() + 's';
  const materialUrl = `types/${materialType}`;
  try {
    dispatch({ type: "PERSIST_TYPE" });
    let response;
    if (data.id) {
      response = await formDataRequest(`${materialUrl}/${data.id}`, data, "PUT");
    } else {
      response = await formDataRequest(`${materialUrl}`, data, "POST");
    }

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      dispatch({ type: "PERSIST_TYPE_SUCCESS" });
    } else {
      dispatch({ type: "PERSIST_TYPE_FAILURE", payload: response });
    }
    //TODO: Tratamiento de errores centralizado
  } catch (error) {
    dispatch({ type: "PERSIST_TYPE_FAILURE", payload: error });
  }
};
