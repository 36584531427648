import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { NotificationManager } from "react-notifications";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { Col, Form, Row } from "reactstrap";
import { useDispatch, useSelector } from 'state';
import { getInventory, persistInventory } from "state/inventories";
import { getEnabledTypes } from "state/types";
import reactSelectCustomProps from "utils/reactSelectCustomProps";
import * as Yup from "yup";

export const InventoryForm = ({
    materialType,
}) => {

    const { id } = useParams();
    const { enabledTypes } = useSelector('types');
    const { persisting, saved, errorPersisting, inventory } = useSelector('inventories');
    const { currentRenting } = useSelector('inventories');
    const { cities } = useSelector('cities');
    const dispatch = useDispatch();
    const { formatMessage: f } = useIntl();
    const navigate = useNavigate();

    const [enabledOn, setEnabledOn] = useState(inventory?.id ? inventory?.enabled : null);
    const [enabledOff, setEnabledOff] = useState(inventory?.id ? !inventory?.enabled : null);
    const [selectedType, setSelectedType] = useState(inventory?.id ?
        {
            value: inventory?.material.id,
            label: inventory?.material.brand + " - " + inventory?.material.model
        }
        :
        ''
    ); // selectedType

    const [labelClass, setLabelClass] = useState("col-lg-4 form-control-label d-flex justify-content-lg-end");
    const [columnClass, setColumnClass] = useState("col-lg-5");
    const [radioClass, setRadioClass] = useState("col-lg-1");
    const [selectedCity, setSelectedCity] = useState(inventory?.city);
    const [doSave, setDoSave] = useState(false);

    const resetAllFields = () => {
        setSelectedCity(null);
        setSelectedType('');
        setEnabledOff(null);
        setEnabledOn(null);
    } // resetAllFields

    const isCityBlocked = currentRenting !== undefined && currentRenting !== null;

    /**
     * Gets inventory information when the component is rendered for the first time
     */
    useEffect(() => {
        const getInventoryInformation = async () => {
            await getEnabledTypes(dispatch, materialType);

            // Edit form
            if (id) {
                await getInventory(dispatch, id);
            }
        }
        getInventoryInformation();

        if (inventory?.id) {
            setLabelClass("col-lg-3 form-control-label");
            setColumnClass("col-lg-8")
            setRadioClass("col-lg-3")
        }
    }, []);

    useEffect(() => {
        if (doSave) {
            if (!saved) {
                if (!inventory?.id) {
                    NotificationManager.success("Creado correctamente");
                    dispatch({ type: "RESET_SAVED" });
                    dispatch({ type: "SET_SELECTED_TAB", payload: 1 });
                    navigate(`/${materialType}s`);
                } else {
                    NotificationManager.success("Modificado correctamente");
                    dispatch({ type: "RESET_SAVED" });
                }
            } else if (errorPersisting) {
                NotificationManager.error("Se ha producido un error");
                dispatch({ type: "RESET_ERROR" });
            }
            setDoSave(false);
        }
    }, [saved, errorPersisting]);

    const inventorySchema = Yup.object().shape({
        numberPlate: Yup.string().required("Por favor, debes especificar una matrícula."),
        enabled: Yup.string().required("Debes seleccionar una opción."),
        materialId: Yup.string().required("Debes seleccionar un tipo de embarcación."),
        currentLocation: currentRenting ? Yup.object().notRequired() : Yup.object().required("Debes seleccionar una ciudad."),
    }).required();

    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(inventorySchema),
        defaultValues: {
            ...inventory,
        },
    });

    const onEnabledOnChange = () => {
        setEnabledOn(true)
        setEnabledOff(false)
        setValue("enabled", true);
    }

    const onEnabledOffChange = () => {
        setEnabledOff(true)
        setEnabledOn(false)
        setValue("enabled", false);
    }

    const onTypeChange = (selected) => {
        setSelectedType(selected);
        setValue("materialId", selected ? selected.value : '');
    }

    const onSubmit = async (data) => {
        setDoSave(true);
        data["currentLocation"] = data["currentLocation"]?.value || data["city"].name;

        // TODO: Sometimes the inventory.city object is sent, so it has to be removed. It shouldn't be there from the beggining.
        // I mean, it works. But it could be done in a prettier way
        delete data.city;

        let clonedData = {
            ...data,
            enabled: data.enabled === "true",
        }; // clonedData
        
        await persistInventory(dispatch, clonedData);
    };

    const getCurrentCity = () => {
        return cities.find(city => city.value === selectedCity?.name);
    }

    return (
        <Form id="inventory-form" onSubmit={handleSubmit(onSubmit)}>
            <input
                id="id"
                name="id"
                type="hidden"
                {...register('id')}
            />

            <input
                id="enabled"
                name="enabled"
                type="hidden"
                {...register('enabled')}
            />

            <input
                id="materialId"
                name="materialId"
                type="hidden"
                {...register('materialId')}
            />

            <Row className="form-group row d-flex align-items-center mb-5">
                <label className={labelClass}>{f({ id: `${materialType}Inventory.numberPlate.label` })} </label>
                <Col className={columnClass}>
                    <input
                        type="text"
                        name="numberPlate"
                        id="numberPlate"
                        {...register('numberPlate')}
                        className={errors?.numberPlate ? "form-control form-control-invalid" : "form-control"}
                        placeholder={f({ id: `${materialType}Inventory.numberPlate.placeholder` })}
                    />
                    {errors?.numberPlate && (
                        <div className="invalid-feedback">
                            {errors?.numberPlate.message}
                        </div>
                    )
                    }
                </Col>
            </Row>

            <Row className="form-group row d-flex align-items-center mb-5">
                <label className={labelClass}>{f({ id: `${materialType}Inventory.boat.label` })}</label>
                <Col className={columnClass}>
                    <div className="select">
                        <Select
                            options={enabledTypes.map((item) => (
                                { value: item.id, label: item.brand + " - " + item.model }
                            ))}
                            value={selectedType}
                            isClearable={false}
                            isMulti={false}
                            styles={reactSelectCustomProps}
                            placeholder="Selecciona una opción..."
                            onChange={(e) => {
                                onTypeChange(e);
                            }}
                        />
                    </div>
                    {errors?.materialId && (
                        <div className="invalid-feedback">
                            {errors?.materialId.message}
                        </div>
                    )
                    }
                </Col>
            </Row>

            <div className="form-group row d-flex align-items-center mb-5">
                <label className={labelClass}>{f({ id: 'form.comments.label' })}</label>
                <div className={columnClass}>
                    <textarea
                        name="comments"
                        id="comments"
                        {...register('comments')}
                        rows="4"
                        className="form-control"
                        placeholder={f({ id: `${materialType}Inventory.comments.placeholder` })}>
                    </textarea>
                </div>
            </div>

            <div className="form-group row mb-5">
                <label className={labelClass}>{f({ id: `${materialType}Inventory.enabled.label` })}</label>
                <Col className={radioClass}>
                    <div className="custom-control custom-radio styled-radio mb-3">
                        <input
                            className="custom-control-input"
                            type="radio"
                            name="enabled"
                            checked={enabledOn}
                            onChange={() => onEnabledOnChange()}
                            id="opt-01" />
                        <label className="custom-control-descfeedback" htmlFor="opt-01">{f({ id: 'app.yes' })}</label>
                    </div>
                    {errors?.enabled && (
                        <div className="invalid-feedback">
                            {errors?.enabled.message}
                        </div>
                    )
                    }
                </Col>
                <Col className={radioClass}>
                    <div className="custom-control custom-radio styled-radio mb-3">
                        <input
                            className="custom-control-input"
                            type="radio"
                            name="noEnabled"
                            checked={enabledOff}
                            onChange={() => onEnabledOffChange()}
                            id="opt-02" />
                        <label className="custom-control-descfeedback" htmlFor="opt-02">{f({ id: 'app.no' })}</label>
                    </div>
                </Col>
            </div>

            <Row className="form-group row d-flex align-items-center mb-5">
                <label className={labelClass}>Ubicación actual</label>
                <Col className={columnClass}>
                    <div className="select">

                        <Controller
                            control={control}
                            name={'currentLocation'}

                            render={({ field }) => {
                                const handleChange = (selectedOption) => {
                                    field.onChange(selectedOption);
                                    setSelectedCity(selectedOption);
                                };

                                return (
                                    <Select
                                        options={cities}
                                        value={getCurrentCity()}
                                        isClearable={false}
                                        isSearchable={false}
                                        styles={reactSelectCustomProps}
                                        placeholder="Selecciona una ubicación..."
                                        noOptionsMessage={() => "No hay opciones disponibles"}
                                        onChange={handleChange}

                                        // Disabled if there is an active renting
                                        isDisabled={!!currentRenting?.id}
                                        />
                                );
                            }}
                        />
                    </div>
                    {
                        errors?.currentLocation && (
                            <div className="invalid-feedback">
                                {errors?.currentLocation?.message}
                            </div>
                        )
                    } {/* Errors */}
                </Col>
            </Row>

            <div className="em-separator separator-dashed" />

            <div className="d-flex flex-row-reverse">
                {/* TODO: create method that sets all values to null */}
                {
                    !inventory?.id &&
                    <button
                        disabled={persisting}
                        className="btn btn-shadow"
                        type="reset"
                        onClick={resetAllFields}
                    >
                        Limpiar
                    </button>
                }
                <button
                    disabled={persisting}
                    form="inventory-form"
                    className="btn btn-primary mr-1"
                    type="submit"
                >
                    {persisting ? f({ id: 'app.saving' }) : f({ id: 'app.save' })}
                </button>
            </div>
        </Form>
    );
}