import cx from 'classnames';
import { PageHeader } from 'components/PageHeader';
import { RequestsBody } from 'components/RequestsBody';
import moment from "moment";
import styles from 'pages/Views.module.scss';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'state';
import { getAllRequests } from 'state/requests';
import { PAGE_SIZE } from 'utils/utils';

export const RequestManagementPage = () => {

    const {
        pageIndex,
        criteria,
        dateFromFilter,
        dateUntilFilter,
        dateType,
        cleaned,
        statusFilter,
        deleted
    } = useSelector('requests');

    const dispatch = useDispatch();
    const [filterByCity, setFilterByCity] = useState(false);

    const getRequestsFunction = async (dateFromAsString, dateUntilAsString, statusAsString) => {
        
        await getAllRequests(
            dispatch,
            pageIndex - 1,
            PAGE_SIZE,
            criteria.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
            dateFromAsString,
            dateUntilAsString,
            dateType.id,
            statusAsString,
            filterByCity
        );
    }

    const applyFilters = () => {
        const dateFromAsString = dateFromFilter ? moment(dateFromFilter, moment.ISO_8601).startOf('day').format("MMDDyyyy") : '';
        const dateUntilAsString = dateUntilFilter ? moment(dateUntilFilter, moment.ISO_8601).endOf('day').format("MMDDyyyy HH:mm") : '';
        const statusAsString = statusFilter.length > 0 ? statusFilter.map((obj) => obj.value).join(',') : "";
        if (dateType.id === "r_room_check_in_date") {
            setFilterByCity(false);
        }
        getRequestsFunction(dateFromAsString, dateUntilAsString, statusAsString);
    }

    useEffect(() => {
        applyFilters();
    }, [dispatch, criteria, pageIndex]);

    useEffect(() => {
        if (!deleted) {
            applyFilters();
        }
    }, [deleted]);

    useEffect(() => {
        if (cleaned) {
            applyFilters();
            dispatch({ type: "RESET_CLEAN_FILTERS"})
        }
    }, [cleaned]);

    const cleanFilters = () => {
        dispatch({ type: "CLEAN_FILTERS"})
    }

    return (
        <div>
            <PageHeader title="Gestión de solicitudes" />

            <div className={cx(styles["widget"])}>
                <div className={cx(styles["widget-body"])}>
                    <RequestsBody
                        applyFilters={applyFilters}
                        cleanFilters={cleanFilters}
                        filterByCity={filterByCity}
                        setFilterByCity={setFilterByCity}
                    />

                </div>
            </div>
        </div>
    );
}