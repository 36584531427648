import React, { useReducer } from 'react';
import combineReducers from 'react-combine-reducers';
import { authReducer, initialAuthState } from './auth';
import { dashboardReducer, initialDashboardState } from './dashboard';
import { initialLayoutState, layoutReducer } from './layout';
import { initialMaintenancesState, maintenancesReducer } from './maintenances/reducer';
import { initialRequestsState, requestsReducer } from './requests';
import { initialRoomsState, roomsReducer } from './rooms';
import { initialTrailerTypesState, trailerTypesReducer } from './trailerTypes';
import { initialUsersState, usersReducer } from './users';
import { initialVanTypesState, vanTypesReducer } from './vanTypes';
import { typesReducer } from './types';
import { initialTypesState } from './types/reducer';
import { initialInventoriesState, inventoriesReducer } from './inventories';
import citiesSlice, { initialCitiesState } from './citiesSlice';

const StoreStateContext = React.createContext();
const StoreDispatchContext = React.createContext();

export const useSelector = (state) => {
	const context = React.useContext(StoreStateContext);
	if (context === undefined) {
		throw new Error('useSelector must be used within a StoreStateProvider');
	}

	return context[state];
}

export const useDispatch = () => {
	const context = React.useContext(StoreDispatchContext);
	if (context === undefined) {
		throw new Error('useDispatch must be used within a StoreDispatchProvider');
	}

	return context;
}

export const StoreProvider = ({ children }) => {

	const [stateReducer, initialState] = combineReducers({
		auth: [ authReducer, initialAuthState],
		types: [typesReducer, initialTypesState],
		trailerTypes: [trailerTypesReducer, initialTrailerTypesState],
		vanTypes: [vanTypesReducer, initialVanTypesState],
		rooms: [roomsReducer, initialRoomsState],
		requests: [requestsReducer, initialRequestsState],
		layout: [layoutReducer, initialLayoutState],
		dashboard: [dashboardReducer, initialDashboardState],
		users: [usersReducer, initialUsersState],
		maintenances: [maintenancesReducer, initialMaintenancesState],
		inventories: [inventoriesReducer, initialInventoriesState],
		cities: [citiesSlice, initialCitiesState]
	});

	const [state, dispatch] = useReducer(stateReducer, initialState);

	return (
		<StoreStateContext.Provider value={state}>
			<StoreDispatchContext.Provider value={dispatch}>
				{children}
			</StoreDispatchContext.Provider>
		</StoreStateContext.Provider>
	);
}
