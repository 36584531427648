export const initialDashboardState = {
  inventoryHistory: [],
  loadingCalendar: false,
  checkBoatTypes: true,
  checkTrailerTypes: true,
  checkVanTypes: true,
  checkRooms: true,
};

export const dashboardReducer = (initialState = initialDashboardState, action) => {
  switch (action.type) {

    case "GET_INVENTORY_HISTORY":
      return { ...initialState, inventoryHistory: [], loadingCalendar: true };

    case "GET_INVENTORY_HISTORY_SUCCESS":
      return { ...initialState, inventoryHistory: action.payload ? action.payload : [], loadingCalendar: false };

    case "GET_INVENTORY_HISTORY_FAILURE":
      return { ...initialState, inventoryHistory: [], loadingCalendar: false };

    case "UPDATE_CHECK_BOAT_TYPES":
      return { ...initialState, checkBoatTypes: action.payload }

    case "UPDATE_CHECK_TRAILER_TYPES":
      return { ...initialState, checkTrailerTypes: action.payload }

    case "UPDATE_CHECK_VAN_TYPES":
      return { ...initialState, checkVanTypes: action.payload }

    case "UPDATE_CHECK_ROOMS":
      return { ...initialState, checkRooms: action.payload }

    default:
      return { ...initialState };
  }
};
