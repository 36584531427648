import { get } from "api/client";

export const getInventoryHistoryByDates = async (dispatch, dateFrom, dateUntil, materialTypes) => {
  try {
    dispatch({ type: "GET_INVENTORY_HISTORY" }); // stocks: [], loading: true
    const data = await get(`inventories/renting/by-date?date-from=${dateFrom}&date-until=${dateUntil}&material-types=${materialTypes}`);
    dispatch({ type: "GET_INVENTORY_HISTORY_SUCCESS", payload: data }); // loading: false y members: action.payload
  } catch (error) {
    dispatch({
      type: "GET_INVENTORY_HISTORY_FAILURE",
      error: { code: 500, description: error },
    });
  }
};