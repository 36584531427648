import { useEffect, useState } from 'react';
import { useIntl } from "react-intl";
import { NotificationManager } from 'react-notifications';
import { useDispatch, useSelector } from 'state';

import Loading from 'components/Loading/Loading';
import { SearchBarAndButtonHeader } from 'components/SearchBarAndButtonHeader';
import { ConfirmationModal } from 'components/Shared/ConfirmationModal/ConfirmationModal';
import { PaginationComponent } from 'components/Shared/PaginationComponent/PaginationComponent';
import { Table } from 'components/Table';
import { getInventoryForType } from 'state/inventories';
import { PAGE_SIZE } from 'utils/utils';
import { materials } from 'utils/enums';

export const InventoryList = (props) => {

    const {
        inventoryInfo,
        criteria,
        setCriteria,
        inventoryToDelete,
        setInventoryToDelete,
        materialType,
        setPage
    } = props;

    const {
        deleted,
        deleting,
        errorDeleting,
        loading
    } = useSelector('inventories')

    /**
     * Returns the headers for the inventory table according to the material type
     * @returns the headers for the inventory table according to the material type
     */
    const tableHeaders = () => {
        let typeString;
        const materialTypeUpperCase = materialType.toUpperCase();
        if (materialTypeUpperCase === materials.BOAT) {
            typeString = "embarcación";
        } else if (materialTypeUpperCase === materials.TRAILER) {
            typeString = "remolque";
        } else if (materialTypeUpperCase === materials.VAN) {
            typeString = "furgoneta";
        } // if-else if

        if (typeString) {
            return (
                <tr>
                    <th scope="col">Matrícula</th>
                    <th scope="col">Tipo de {typeString}</th>
                    <th scope="col">Estado</th>
                    <th scope="col" className="width-10-percent">Acciones</th>
                </tr>
            ) // return
        } // if
    } // tableHeaders

    /**
     * 
     * @param {Object[]} inventories - The list of inventories
     * @param {function onEdit(id) {}} onEdit - A function which allows to edit the inventory
     * @param {{function onDeleteClick(inventory) {}}} onDeleteClick - A function which allows to set the inventory for deletion
     * @returns 
     */
    const columnsData = (inventories, onEdit, onDeleteClick) => {
        return inventories?.map((inventory) => {
            return (
                <tr key={inventory.numberPlate}>
                    <td>{inventory.numberPlate}</td>
                    <td>{inventory.material.brand + " - " + inventory.material.model}</td>
                    <td>{inventory.enabled ?
                        <span className="badge-text badge-text-small info">Activo</span>
                        :
                        <span className="badge-text badge-text-small danger">No disponible</span>
                    }</td>
                    <td className="td-actions">
                        <a
                            role="button"
                            href={() => false}
                            onClick={() => onEdit(inventory.id)}
                        >
                            <i className="la la-edit edit" />
                        </a>
                        <a role="button" onClick={() => onDeleteClick(inventory)}><i className="la la-trash delete" /></a>
                    </td>
                </tr>
            ); // return
        }) // return inventories.map
    } // columnsData

    const { formatMessage: f } = useIntl();
    const dispatch = useDispatch();

    const [showDeleteForm, setShowDeleteForm] = useState(false);
    const [allowedDelete, setAllowedDelete] = useState(true);

    useEffect(() => {
        if (!allowedDelete) {
            setInventoryToDelete(undefined);
        }
    }, [allowedDelete]);

    useEffect(() => {
        if (deleted) {
            NotificationManager.success("Eliminado correctamente");
            dispatch({ type: "RESET_DELETED" });
            setInventoryToDelete(undefined);
            setShowDeleteForm(false);
            getInventoryForType(dispatch, materialType, inventoryInfo.pageIndex - 1, PAGE_SIZE);
        } else if (errorDeleting) {
            NotificationManager.error("Se ha producido un error");
            dispatch({ type: "RESET_ERROR" });
        }
    }, [deleted, errorDeleting]);

    /**
     * Confirms the deletion of an inventory
     */
    const onDeleteConfirm = async () => {
        inventoryInfo.onDeleteConfirm(inventoryToDelete);
        setShowDeleteForm(false);
    }

    /**
     * Sets up the confirmation modal for deleting an inventory
     * @param {Object} inventory - The inventory to deletw
     */
    const onDeleteClick = async (inventory) => {

        const allowed = inventoryInfo.checkDelete(inventory);
        if (allowed) {
            setInventoryToDelete(inventory);
        }
        setAllowedDelete(allowed);
        setShowDeleteForm(true);
    }

    /**
     * Hides the confirmation modal
     */
    const onCancelDeleteClick = () => {
        setShowDeleteForm(false);
        setInventoryToDelete(undefined);
    }

    return (
        <div className="tab-pane fade show active mt-3" id="j-tab-1" role="tabpanel" aria-labelledby="just-tab-1">
            <ConfirmationModal
                showModal={showDeleteForm}
                onToggleClick={() => setShowDeleteForm(false)}
                header={f({ id: `${materialType}Inventory.delete.title` })}
                message={
                    !allowedDelete ?
                        f({ id: `${materialType}Inventory.delete.notAllowed` })
                        :
                        (
                            inventoryToDelete &&
                            f({ id: `${materialType}Inventory.delete.message` },
                                {
                                    description:
                                        inventoryToDelete.numberPlate +
                                        ' - ' +
                                        inventoryToDelete.material.brand +
                                        ' ' +
                                        inventoryToDelete.material.model
                                } // description
                            ) // f
                        ) // ?
                } // message
                cancelAction={allowedDelete && onCancelDeleteClick}
                cancelButtonLabel={f({ id: "app.cancel" })}
                okAction={allowedDelete ? (inventoryToDelete) => onDeleteConfirm(inventoryToDelete) : onCancelDeleteClick}
                okButtonLabel={allowedDelete ? f({ id: "app.delete" }) : f({ id: "app.close" })}
                loading={deleting}
                loadingMessage={f({ id: "app.deleting" })}
            />
            <SearchBarAndButtonHeader
                text={f({ id: `${materialType}Inventory.bodyTitle` })}
                url={`/${materialType}s/inventories/new`}
                showAddButton={true}
                filterText
                criteria={criteria}
                criteriaChange={setCriteria}
            />
            {
                loading ?
                    <Loading />
                    :
                    <Table
                        headers={tableHeaders()}
                        columnsData={columnsData(inventoryInfo.inventory, inventoryInfo.onEdit, onDeleteClick)}
                        pagination={
                            <PaginationComponent
                                items={inventoryInfo.inventory}
                                pageIndex={inventoryInfo.pageIndex}
                                pageSize={PAGE_SIZE}
                                totalItems={inventoryInfo.totalItems}
                                onPageIndexChange={(newPage) => setPage(newPage)}
                                totalPages={inventoryInfo.totalPages}
                            />
                        }
                    />
            }
        </div>
    );
}