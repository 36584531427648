export const initialLayoutState = {
    navCollapsed: false
};

export const layoutReducer = (initialState = initialLayoutState, action) => {
    switch (action.type) {

        // collapse sidebar
		case 'COLLAPSED_SIDEBAR':
			return { ...initialState, navCollapsed: action.payload };

        default:
            return { ...initialState };
    }
}
