import { buildMaintenancePdfPath } from 'helpers/helpers';
import { convertStringDateToISO8601Date } from './dateUtils';

export const getMaintenanceHistoryHeaders = (inventoryType) => {
    return (
        <tr>
            <th>Fecha de mantenimiento</th>
            <th>
                {
                    inventoryType === 'boat' ?
                        'Horas' :
                        'Kilómetros'
                }
            </th>
            <th>Descripción</th>
            <th>Acciones</th>
        </tr>
    ); // return
} // getMaintenanceHistoryHeaders


export const formatRentHistoryData = (rentList) => {
    return rentList?.map((rentItem) => {
        return (
            <tr key={rentItem.numberPlate}>
                <td>{convertStringDateToISO8601Date(rentItem.request.materialCollectionDate)}</td>
                <td>{convertStringDateToISO8601Date(rentItem.request.materialReturnDate)}</td>
                <td>{rentItem.request.locator}</td>
                <td>{rentItem.request.name + " " + rentItem.request.surname}</td>
                <td>{rentItem.request.materialEventName}</td>
                <td className="td-actions">
                    <a
                        role="button"
                        href={'/requests/' + rentItem.request.id}
                    >
                        <i className="la la-eye edit" />
                    </a>
                </td>
            </tr>
        ); // return
    }); // return map
} // formatRentHistoryData

export const formatMaintenanceHistoryData = (maintenanceHistory, onEdit, onDeleteClick) => {
    return maintenanceHistory?.map(maintenance => {
        return (
            <tr key={maintenance.key}>
                <td>{convertStringDateToISO8601Date(maintenance.date)}</td>
                <td>{maintenance.kilometers?.toLocaleString() || maintenance.hours?.toLocaleString()}</td>
                <td>{maintenance.description}</td>
                {/* TODO: view pdf */}
                <td className='td-actions'>
                    <a
                        rel="noreferrer"
                        target={maintenance.pdfFilePath && "_blank"}
                        href={maintenance.pdfFilePath ? buildMaintenancePdfPath(maintenance.id, maintenance.pdfFilePath) : "#"}
                    >
                        <i className="la la-file-pdf-o edit" />
                    </a>
                    <a
                        href={() => false}
                        onClick={() => onEdit(maintenance.id)}
                    >
                        <i className="la la-edit edit" />
                    </a>
                    <a
                        role="button"
                        onClick={() => onDeleteClick(maintenance)}
                    >
                        <i className="la la-trash delete" />
                    </a>
                </td>
            </tr>
        ); // return
    }); // return map
} // formatMaintenanceHistoryData