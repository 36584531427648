import { useEffect, useState } from 'react';
import { useIntl } from "react-intl";
import { NotificationManager } from 'react-notifications';
import { useDispatch } from 'state';

import Loading from 'components/Loading/Loading';
import { SearchBarAndButtonHeader } from 'components/SearchBarAndButtonHeader';
import { ConfirmationModal } from 'components/Shared/ConfirmationModal/ConfirmationModal';
import { Table } from 'components/Table';

import { PaginationComponent } from 'components/Shared/PaginationComponent';
import { buildMaterialPdfPath, buildPhotoPath } from 'helpers/helpers';
import { PAGE_SIZE } from 'utils/utils';

export const MaterialTypeList = ({
    typesInfo,
    onEdit,
    checkDelete,
    onDeleteConfirm,
    criteria,
    setCriteria,
    deleted,
    deleting,
    errorDeleting,
    loading,
    materialType,
    setPage
}) => {

    const dispatch = useDispatch();
    const { formatMessage: f } = useIntl();

    const [showDeleteForm, setShowDeleteForm] = useState(false);
    const [allowedDelete, setAllowedDelete] = useState(true);
    const [typeToDelete, setTypeToDelete] = useState(undefined);

    const tableHeaders = () => {
        return (
            <tr>
                <th scope="col">Imagen</th>
                <th scope="col">Marca</th>
                <th scope="col">Modelo</th>
                <th scope="col">Estado</th>
                <th scope="col" className="width-10-percent">Acciones</th>
            </tr>
        ); // return
    } // tableHeaders

    const columnsData = (array, onEdit, onDeleteClick) => {
        return array.map((data) => {
            return (
                <tr key={data.brand + data.model}>
                    <td>
                        <div className="thumbnail">
                            {
                                data.photos.length > 0 &&
                                <img
                                    src={buildPhotoPath(data.id, data.photos[0].file)}
                                    alt={data.photos[0].file}
                                    className="avatar rounded-circle d-block mx-auto"
                                />
                            }
                        </div>
                    </td>
                    <td>{data.brand}</td>
                    <td>{data.model}</td>
                    <td>{data.enabled ?
                        <span className="badge-text badge-text-small info">Activo</span>
                        :
                        <span className="badge-text badge-text-small danger">No disponible</span>
                    }</td>
                    <td className="td-actions">
                        <a
                            rel="noreferrer"
                            target={data.datasheet && "_blank"}
                            href={data.datasheet ? buildMaterialPdfPath(data.id, data.datasheet) : "#"}
                        >
                            <i className="la la-file-pdf-o edit" />
                        </a>
                        <a
                            role="button"
                            href={() => false}
                            onClick={() => onEdit(data.id)}
                        >
                            <i className="la la-edit edit" />
                        </a>
                        <a
                            role="button"
                            onClick={() => onDeleteClick(data)}
                        >
                            <i className="la la-trash delete" />
                        </a>
                    </td>
                </tr>
            ); // return
        }); // return array.map
    } // columnsData

    const onDeleteClick = async (type) => {
        const allowed = checkDelete(type);
        if (allowed) {
            setTypeToDelete(type);
        } // if
        setAllowedDelete(allowed);
        setShowDeleteForm(true);
    } // onDeleteClick 

    const onCancelDeleteClick = () => {
        setShowDeleteForm(false);
    } // onCancelDeleteClick

    useEffect(() => {
        if (!allowedDelete) {
            setTypeToDelete(undefined);
        }
    }, [allowedDelete]);

    useEffect(() => {
        if (deleted) {
            NotificationManager.success("Eliminado correctamente");
            dispatch({ type: "RESET_DELETED" });
            setTypeToDelete(undefined);
            setShowDeleteForm(false);
        } else if (errorDeleting) {
            NotificationManager.error("Se ha producido un error");
            dispatch({ type: "RESET_ERROR" });
        }
    }, [deleted, errorDeleting]);

    return (
        <div className="tab-pane fade show active mt-3" id="j-tab-1" role="tabpanel" aria-labelledby="just-tab-1">
            <ConfirmationModal
                showModal={showDeleteForm}
                onToggleClick={() => setShowDeleteForm(false)}
                header={f({ id: `${materialType}.delete.title` })}
                message={!allowedDelete ?
                    f({ id: `${materialType}.delete.notAllowed` })
                    :
                    (typeToDelete && f({ id: `${materialType}.delete.message` }, { description: typeToDelete.brand + ' ' + typeToDelete.model }))}
                cancelAction={allowedDelete && onCancelDeleteClick}
                cancelButtonLabel={f({ id: "app.cancel" })}
                okAction={allowedDelete ? () => onDeleteConfirm(typeToDelete?.id) : onCancelDeleteClick}
                okButtonLabel={allowedDelete ? f({ id: "app.delete" }) : f({ id: "app.close" })}
                loading={deleting}
                loadingMessage={f({ id: "app.deleting" })}
            />

            <SearchBarAndButtonHeader
                text={f({ id: `${materialType}Types.bodyTitle` })}
                url={`/${materialType}s/new`}
                showAddButton={true}
                filterText={true}
                criteria={criteria}
                criteriaChange={setCriteria}
            />

            {
                loading ?
                    <Loading />
                    :
                    <Table
                        headers={tableHeaders()}
                        columnsData={columnsData(typesInfo.types, onEdit, onDeleteClick)}
                        pagination={
                            <PaginationComponent
                                items={typesInfo.types}
                                pageIndex={typesInfo.pageIndex}
                                pageSize={PAGE_SIZE}
                                totalItems={typesInfo.totalItems}
                                onPageIndexChange={(newPage) => setPage(newPage)}
                                totalPages={typesInfo.totalPages}
                            />
                        } // pagination
                    />
            } {/* Loading */}
        </div>
    ); // return
} // TypesBody