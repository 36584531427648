import VanTypeForm from "components/Form/Entity/VanTypeForm";
import BoatTypeForm from "pages/boats/types/BoatTypeForm";
import TrailerTypeForm from "pages/trailers/types/form";
import { materials } from "utils/enums";

export const EditMaterialForm = ({ typeEntity }) => {
    let form;
    switch (typeEntity) {
        case materials.BOAT:
            form = <BoatTypeForm />
            break;
        case materials.TRAILER:
            form = <TrailerTypeForm />
            break;
        case materials.VAN:
            form = <VanTypeForm />
            break;

        default:
            form = null;
    } // switch

    return form;
} // EditMaterialForm