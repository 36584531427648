import { yupResolver } from "@hookform/resolvers/yup";
import { get } from "api/client";
import { ConfirmationModal } from "components/Shared/ConfirmationModal";
import { useEffect, useMemo, useState } from 'react';
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import { Col, Form, Row } from "reactstrap";
import { useDispatch, useSelector } from 'state';
import { persistUser } from "state/users";
import * as types from 'state/users/types';
import * as Yup from "yup";

const MODAL_HEADER = "Revisar correo electrónico";
const MODAL_MESSAGE = "El correo introducido debe existir dentro de azure, en caso contrario no se añadirá al grupo de GESTION-LOGISTICA";
const MODAL_BUTTON_ACCEPT_MESSAGE = "Aceptar";
const MODAL_BUTTON_CANCEL_MESSAGE = "Cancelar";

const UserForm = () => {

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { formatMessage: f } = useIntl();

	const { saved, errorPersisting, user, persisting } = useSelector('users');

	const [formData, setFormData] = useState();
	const [validating, setValidating] = useState(false);
	const [enabledOn, setEnabledOn] = useState(user && user.id ? user.enabled : null);
	const [enabledOff, setEnabledOff] = useState(user && user.id ? !user.enabled : null);

	// If user input is empty, this var is true to hide enabled account (default value = false)

	const creating = useMemo(() => {
		const isUserEmpty = !Boolean(Object.keys(user).length !== 0);
		const isEditingInactiveUser = !Boolean(user?.enabled);

		return isUserEmpty || isEditingInactiveUser;
	}, [user])

	useEffect(() => {
		if (saved) {
			if (!user || !user.id) {
				NotificationManager.success("Creado correctamente");
				dispatch({ type: types.RESET_SAVED });
				navigate("/users");
			} else {
				NotificationManager.success("Modificado correctamente");
				dispatch({ type: types.RESET_SAVED });
			}
		} else if (errorPersisting) {
			NotificationManager.error("Se ha producido un error");
			dispatch({ type: "RESET_ERROR" });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [saved, errorPersisting]);


	Yup.addMethod(Yup.string, 'alreadyExists', function (message) {
		return this.test('alreadyExists', message, async function (value) {
			const { path, createError } = this;
			setValidating(true);
			let urlQuery = 'users/check-email?email=' + value
			if (user.id) {
				urlQuery += '&id=' + user.id;
			}
			const exists = await get(urlQuery);
			setValidating(false);
			if (exists) {
				return createError({ path, message: message });
			} else {
				return true;
			}
		});
	});


	Yup.addMethod(Yup.string, 'allowed', function (message) {
		return this.test('allowed', message, async function (value) {
			if (user.id && value === "true") {
				// se comprueba si el usuario ya ha indicado una contraseña
				const { path, createError } = this;
				setValidating(true);

				const hasPassword = await get('users/check-password?id=' + user.id);
				setValidating(false);
				if (!hasPassword) {
					return createError({ path, message: message });
				} else {
					return true;
				}
			}
			return true;
		});
	});

	const userSchema = Yup.object({
		name: Yup.string().required(f({ id: "form.errors.required" })),
		surname: Yup.string().required(f({ id: "form.errors.required" })),
		surname2: Yup.string(),
		email: Yup
			.string()
			.required(f({ id: "form.errors.required" }))
			.email(f({ id: "form.errors.format" }))
			.alreadyExists(f({ id: "form.errors.alreadyExists" })),
		enabled: Yup
			.string()
			.required(f({ id: "form.errors.required" }))
			.allowed(f({ id: "form.errors.notAllowed" }, { reason: 'El usuario no ha indicado una contraseña' }))
	}).required();

	const { register, handleSubmit, formState: { errors }, setValue } = useForm({
		mode: "onSubmit",
		resolver: yupResolver(userSchema),
		defaultValues: {
			...user,
			enabled: user.enabled ?? false
		},
	});

	const onEnabledOnChange = () => {
		setEnabledOn(true)
		setEnabledOff(false)
		setValue("enabled", true);
	}

	const onEnabledOffChange = () => {
		setEnabledOff(true)
		setEnabledOn(false)
		setValue("enabled", false);
	}

	const onCloseModalHandler = () => {
		setFormData(undefined);
	};

	const onModalAcceptHandler = async () => {

		setFormData(undefined);
		let clonedData = {
			...formData,
			enabled: formData.enabled === "true",
		};

		await persistUser(dispatch, clonedData);
	};

	const onSubmit = async (data) => {
		setFormData(data);
	};

	return (
		<Form id="user-form" onSubmit={handleSubmit(onSubmit)}>
			<ConfirmationModal
				showModal={formData}
				onToggleClick={onCloseModalHandler}
				header={MODAL_HEADER}
				message={MODAL_MESSAGE}
				okButtonLabel={MODAL_BUTTON_ACCEPT_MESSAGE}
				okAction={onModalAcceptHandler}
				cancelAction={onCloseModalHandler}
				cancelButtonLabel={MODAL_BUTTON_CANCEL_MESSAGE}
			/>
			<input
				{...register('id')}
				id="id"
				name="id"
				type="hidden"
			/>

			<input
				{...register('enabled')}
				id="enabled"
				name="enabled"
				type="hidden"
			/>

			<Row className="form-group row d-flex align-items-center mb-5">
				<label className="col-lg-4 form-control-label d-flex justify-content-lg-end">{f({ id: 'users.email.label' })} </label>
				<Col className="col-lg-5">
					<input
						type="text"
						name="email"
						id="email"
						{...register('email')}
						className={errors?.email ? "form-control form-control-invalid" : "form-control"}
						placeholder={f({ id: 'users.email.placeholder' })}
					/>
					{errors?.email && (
						<div className="invalid-feedback">
							{errors?.email.message}
						</div>
					)
					}
				</Col>
			</Row>

			<Row className="form-group row d-flex align-items-center mb-5">
				<label className="col-lg-4 form-control-label d-flex justify-content-lg-end">{f({ id: 'users.name.label' })} </label>
				<Col className="col-lg-5">
					<input
						type="text"
						name="name"
						id="name"
						{...register('name')}
						className={errors?.name ? "form-control form-control-invalid" : "form-control"}
						placeholder={f({ id: 'users.name.placeholder' })}
					/>
					{errors?.name && (
						<div className="invalid-feedback">
							{errors?.name.message}
						</div>
					)
					}
				</Col>
			</Row>

			<Row className="form-group row d-flex align-items-center mb-5">
				<label className="col-lg-4 form-control-label d-flex justify-content-lg-end">{f({ id: 'users.surname.label' })} </label>
				<Col className="col-lg-5">
					<input
						type="text"
						name="surname"
						id="surname"
						{...register('surname')}
						className={errors?.surname ? "form-control form-control-invalid" : "form-control"}
						placeholder={f({ id: 'users.surname.placeholder' })}
					/>
					{errors?.surname && (
						<div className="invalid-feedback">
							{errors?.surname.message}
						</div>
					)
					}
				</Col>
			</Row>

			<Row className="form-group row d-flex align-items-center mb-5">
				<label className="col-lg-4 form-control-label d-flex justify-content-lg-end">{f({ id: 'users.surname2.label' })} </label>
				<Col className="col-lg-5">
					<input
						type="text"
						name="surname2"
						id="surname2"
						{...register('surname2')}
						className={errors?.surname2 ? "form-control form-control-invalid" : "form-control"}
						placeholder={f({ id: 'users.surname2.placeholder' })}
					/>
					{errors?.surname2 && (
						<div className="invalid-feedback">
							{errors?.surname2.message}
						</div>
					)
					}
				</Col>
			</Row>


			{!creating &&
				<div className="form-group row mb-5">
					<label className="col-lg-4 form-control-label d-flex justify-content-lg-end">{f({ id: 'users.enabled.label' })}</label>
					<Col className="col-lg-1">
						<div className="custom-control custom-radio styled-radio mb-3">
							<input
								className="custom-control-input"
								type="radio"
								name="enabled"
								checked={enabledOn}
								onChange={() => onEnabledOnChange()}
								id="opt-01" />
							<label className="custom-control-descfeedback" htmlFor="opt-01">{f({ id: 'users.enabled.yes' })}</label>
						</div>
						{errors?.enabled && (
							<div className="invalid-feedback">
								{errors?.enabled.message}
							</div>
						)
						}
					</Col>
					<Col className="col-lg-1">
						<div className="custom-control custom-radio styled-radio mb-3">
							<input
								className="custom-control-input"
								type="radio"
								name="noEnabled"
								checked={enabledOff}
								onChange={() => onEnabledOffChange()}
								id="opt-02" />
							<label className="custom-control-descfeedback" htmlFor="opt-02">{f({ id: 'users.enabled.no' })}</label>
						</div>
					</Col>

				</div>

			}
			<div>
			</div>
			<div className="em-separator separator-dashed" />
			<div className="d-flex align-items-center" style={{ justifyContent: creating ? "space-between" : "flex-end", width: "100%" }}>
				{creating &&
					<div className="d-flex align-items-center" style={{ gap: "5px" }}>
						<i class="las la-info-circle" style={{ fontSize: "22px" }}/>
						<span>
							Al guardar el usuario se permitirá enviar un email de verificación a la dirección de correo indicada. El usuario deberá, mediante este correo, introducir una contraseña para activar la cuenta.
						</span>
					</div>
				}
				<div className="d-flex flex-row-reverse">
					<button disabled={persisting} className="btn btn-shadow" type="reset">Limpiar</button>
					<button disabled={persisting || validating} form="user-form" className="btn btn-primary mr-1" type="submit">{persisting || validating ? f({ id: 'app.saving' }) : f({ id: 'app.save' })}</button>
				</div>
			</div>
		</Form>
	);
}

export default UserForm;