import { AvailableRooms } from "./AvailableRooms/AvailableRooms";
import { BedProposalDates } from "./BedProposalDates";

export const RoomSelection = ({
    modifiedGuests,
    roomsWithOccupation,
    availableRoomsByGuest,
    changeGuestRoom,
    invalidGuests,
    isRequestReadOnly,
    modifiedRequest,
    setModifiedRequest
}) => {
    return (
        <>
            <AvailableRooms
                guests={modifiedGuests}
                rooms={roomsWithOccupation}
                availableRoomsByGuest={availableRoomsByGuest}
                changeGuestRoom={changeGuestRoom}
                invalidGuests={invalidGuests}
                isDisabled={isRequestReadOnly()}
            />

            <BedProposalDates
                request={modifiedRequest}
                disabled={isRequestReadOnly()}
                setRequest={setModifiedRequest}
            />
        </>
    ); // return
};