import { MaterialManagement } from 'components/Shared/MaterialManagement/MaterialManagement';
import { PAGE_TITLES } from '../../utils/utils';

export const BoatManagementPage = () => {
    const materialType = 'boat';
    return (
        <MaterialManagement
            pageTitle={PAGE_TITLES[materialType]}
            materialType={materialType}
        />
    ); // return
} // BoatManagement