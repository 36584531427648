import React from "react";
import * as Yup from "yup";
import cx from "classnames";
import { useIntl } from "react-intl";
import { actions } from "state/auth";
import styles from './Login.module.scss';
import { useForm } from "react-hook-form";
import { FormText, Alert } from "reactstrap";
import { useDispatch, useSelector } from 'state';
import { yupResolver } from "@hookform/resolvers/yup";

const LoginForm = (props) => {
  const { formatMessage: f } = useIntl();
  const dispatch = useDispatch();
  const { loading, error } = useSelector('auth');

  const LOGIN_TITLE = f({ id: "login.title" });
  const REMEMBER_PASSWORD_LABEL = f({ id: "login.rememberPassword" });

  const loginSchema = Yup.object().shape({
    username: Yup.string().required(f({ id: "form.errors.required" })),
    password: Yup.string().required(f({ id: "form.errors.required" }))
  });

  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: "onBlur",
    resolver: yupResolver(loginSchema)
  });

  const onSubmit = async (data) => {
    await actions.login(dispatch, data);
  }

  return (
    <>
      <h3>{LOGIN_TITLE}</h3>
      {error && <Alert className="mt-1" color="danger">
        {error.description}
      </Alert>}
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className={cx(styles.group, "material-input")}>
          <input
            type="text"
            name="username"
            id="username"
            {...register('username')}
            placeholder={f({ id: "login.username" })}
          />
          {errors?.username && (
            <>
              <FormText color="red" className="text-danger">{errors?.username.message}</FormText>
            </>
          )}
        </div>
        <div className={cx(styles.group, "material-input")}>
          <input
            type="password"
            name="password"
            id="password"
            {...register('password')}
            placeholder={f({ id: "login.password" })}
          />

          {errors?.password && (
            <FormText color="red" className="text-danger">{errors?.password.message}</FormText>
          )}
        </div>
        <div className="button text-center">
          <button
            disabled={loading}
            className="btn btn-primary mr-1"
            type="submit"
          >
            {f({ id: loading ? "login.submitting" : "login.submit" })}
          </button>
        </div>
      </form>
      <div className={cx(styles.back)}>
        <a
          role="button"
          onClick={() => props.setShowRecoverPasswordForm(true)}
        >
          {REMEMBER_PASSWORD_LABEL}
        </a>
      </div>
    </>

  );
};

export default LoginForm;
