
import { BoatManagementPage } from 'pages/management/BoatManagementPage';
import { TrailerManagementPage } from 'pages/management/TrailerManagementPage';
import { VanManagementPage } from 'pages/management/VanManagementPage';

// Rooms
import RoomsManagement from 'pages/rooms';
import RoomEdit from 'pages/rooms/edit';

// dashboard
import DashboardPage from 'pages/dashboard/DashboardPage';

//  users
import UsersManagement from 'pages/user';
import UserEdit from 'pages/user/edit';

// login
import { LoginScreen } from 'pages/auth/login/LoginScreen';
import ResetPasswordScreen from 'pages/auth/reset-password/ResetPasswordForm';
import AddInventoryPage from 'pages/inventory/AddInventoryPage';
import { EditInventoryPage } from 'pages/inventory/EditInventoryPage';
import { AddMaintenancePage } from 'pages/maintenances/AddMaintenancePage';
import { EditMaintenancePage } from 'pages/maintenances/EditMaintenancePage';
import EditMaterialPage from 'pages/material/EditMaterialPage';
import { EditRequestPage } from 'pages/requests/EditRequestPage';
import { RequestManagementPage } from 'pages/requests/RequestManagementPage';
import { AddNewEntityPage } from 'pages/types/AddNewEntityPage';
import ChangePasswordPage from 'pages/password/ChangePasswordPage';

export const privateRoutes = [
	{
		path: '/boats',
		title: "boats.title",
		component: BoatManagementPage,
	},
	{
		path: '/boats/new',
		title: 'boat.add.title',
		previousPath: '/boats',
		component: AddNewEntityPage,
		entityType: "boat",
	},
	{
		path: '/boats/:id',
		previousPath: '/boats',
		title: 'Editar embarcación',
		component: EditMaterialPage,
	},
	// trailers
	{
		path: '/trailers',
		title: 'trailers.title',
		component: TrailerManagementPage,
	},
	{
		path: '/trailers/new',
		title: 'trailerTypes.add.title',
		previousPath: '/trailers',
		component: AddNewEntityPage,
		entityType: "trailer",
	},
	{
		path: '/trailers/:id',
		title: 'trailerTypes.edit.title',
		previousPath: '/trailers',
		component: EditMaterialPage,
	},
	// vans
	{
		path: '/vans',
		title: 'vans.title',
		component: VanManagementPage,
	},
	{
		path: '/vans/new',
		title: 'van.add.title',
		previousPath: '/vans',
		component: AddNewEntityPage,
		entityType: "van",
	},
	{
		path: '/vans/:id',
		title: 'van.edit.title',
		previousPath: '/vans',
		component: EditMaterialPage,
	},
	{
		path: '/:materialType/inventories/:id',
		title: 'inventory.edit.title',
		previousPath: '/:materialType',
		component: EditInventoryPage,
	},
	{
		exact: true,
		path: '/:materialType/inventories/new',
		title: `inventory.add.title`,
		previousPath: '/:materialType',
		component: AddInventoryPage,
	},
	// rooms
	{
		path: '/rooms',
		title: 'rooms.title',
		component: RoomsManagement,
	},
	{
		path: '/rooms/new',
		title: 'room.add.title',
		previousPath: '/rooms',
		component: AddNewEntityPage,
		entityType: "room",
	},
	{
		path: '/rooms/:id',
		title: 'room.edit.title',
		previousPath: '/rooms',
		component: RoomEdit,
	},
	// requests
	{
		path: '/requests',
		title: 'requests.title',
		component: RequestManagementPage,
	},
	{
		path: '/requests/:id',
		title: 'request.edit.title',
		previousPath: '/requests',
		component: EditRequestPage,
	},
	// dashboard
	{
		path: '/calendar',
		title: 'calendar.title',
		component: DashboardPage,
	},
	//  users
	{
		path: '/users',
		title: 'users.title',
		component: UsersManagement,
	},
	{
		path: '/users/new',
		title: 'user.add.title',
		previousPath: '/users',
		component: AddNewEntityPage,
		entityType: "user",
	},
	{
		path: '/users/:id',
		title: 'users.edit.title',
		previousPath: '/users',
		component: UserEdit,
	},
	// maintenances
	{
		path: '/maintenances/:id',
		title: 'maintenances.edit.title',
		previousPath: '/inventories',
		component: EditMaintenancePage,
	},
	{
		path: ':materialType/inventories/:inventoryId/maintenances/new',
		title: 'maintenances.add.title',
		previousPath: '/:materialType/inventories/:id',
		component: AddMaintenancePage,
	},
	// Password
	{
		path: '/change-password',
		title: 'app.changePassword',
		component: ChangePasswordPage,
	},

];

export const publicRoutes = [
	{
		path: '/login',
		component: LoginScreen,
	},
	{
		path: '/recover-password/:token',
		component: ResetPasswordScreen,
	}
];