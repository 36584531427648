import { PageHeader } from 'components/PageHeader';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'state';
import { getInventoryHistoryByDates } from "state/dashboard";
import { materials } from 'utils/enums';

import { Dashboard } from 'components/Dashboard';
import { getAllRooms, getGuestsInRoomInDate, getRoomsWithOccupationByDates } from 'state/rooms/actions';
import { convertToInputDateFormat } from 'utils/dateUtils';
import Loading from 'components/Loading/Loading';

const getStringOfMaterialTypes = (checkBoatTypes, checkVanTypes, checkTrailerTypes) => {
    let stringOfTypes = "";
    if (checkBoatTypes) {
        stringOfTypes += materials.BOAT
    }
    if (checkTrailerTypes) {
        stringOfTypes += stringOfTypes ? ',' + materials.TRAILER : materials.TRAILER
    }
    if (checkVanTypes) {
        stringOfTypes += stringOfTypes ? ',' + materials.VAN : materials.VAN
    }

    return stringOfTypes;
}

const getFormattedDate = (date) => {
    return moment(date, moment.ISO_8601).format("yyyy-MM-DD HH:mm")
}

const DashboardPage = () => {
    const {
        inventoryHistory,
        checkBoatTypes,
        checkTrailerTypes,
        checkVanTypes,
        checkRooms
    } = useSelector('dashboard');
    const { roomsWithOccupationByDate, rooms, guestsInRoom } = useSelector('rooms');
    const dispatch = useDispatch();
    const isInitialMount = useRef(true);

    const [startCalendarDay, setStartCalendarDay] = useState(new Date().setHours(0, 0, 0, 0));

    const [dateFrom, setDateFrom] = useState(moment(startCalendarDay).startOf('month').toDate());
    const [dateUntil, setDateUntil] = useState(moment(startCalendarDay).endOf('month').toDate());

    const [selectedRoomEvent, setSelectedRoomEvent] = useState(undefined);
    const [view, setView] = useState('month');

    const getGuestsInRoom = async (room) => {
        await getGuestsInRoomInDate(dispatch, room.id, room.start);
    } // getGuestsInRoom

    const getRoomsWithOccupationFunction = async () => {
        await getRoomsWithOccupationByDates(dispatch, convertToInputDateFormat(dateFrom), convertToInputDateFormat(dateUntil));
    } // getRoomsWithOccupationFunction

    useEffect(() => {
        let stringOfTypes = getStringOfMaterialTypes(checkBoatTypes, checkVanTypes, checkTrailerTypes);
        const getInventories = async () => {
            await getInventoryHistoryByDates(dispatch, getFormattedDate(dateFrom), getFormattedDate(dateUntil),
                stringOfTypes);
        } // getInventoriesAndRooms
        getInventories();
    }, [checkBoatTypes, checkVanTypes, checkTrailerTypes, dispatch]);

    useEffect(() => {
        if (checkRooms) {
            getRoomsWithOccupationFunction();
        }
    }, [checkRooms]);

    useEffect(() => {
        const getAllRoomsFunction = () => {
            getAllRooms(dispatch, 0, 1000);
        }

        getAllRoomsFunction();
    }, []);

    useEffect(() => {
        //Obtenemos las reservas desde el inicio mes anterior
        //hasta el fin de mes porterior del mes seleccionado en el calendario (o el mes actual si es el primer render)
        //buscamos por todos los tipos materiales
        let stringOfTypes = getStringOfMaterialTypes(checkBoatTypes, checkVanTypes, checkTrailerTypes);

        const getInventories = async () => {
            await getInventoryHistoryByDates(dispatch, getFormattedDate(dateFrom), getFormattedDate(dateUntil),
                stringOfTypes);
        } // getInventories

        getInventories();
        getRoomsWithOccupationFunction();
        isInitialMount.current = false;
    }, [dateFrom, dateUntil, dispatch]);

    /**
     * Changes the date to be displayed when switching views (month, week, day)
     * @param {*} newDate - The date to be changed to
     */
    const onViewChange = (newDate) => {
        newDate = new Date(newDate);
        newDate.setHours(0, 0, 0, 0);
        setStartCalendarDay(newDate);

        setDateFrom(moment(newDate).startOf(view).toDate());
        setDateUntil(moment(newDate).endOf(view).toDate());
    } // onViewChange

    useEffect(() => {
        onViewChange(startCalendarDay);
    }, [view])

    /**
     * Concats the first character in each word in a sentence
     * @param {string} sentence - The sentence whose first characters in the word are to be concatenated
     * @returns a string with the first character in each word concatenated 
     */
    const concatFirstCharacter = (sentence) => {
        let words = sentence?.split(" ");

        // Iterates over each word and concatenates the first character with the rest of the word
        for (let i = 0; i < words.length; i++) {
            let firstChar = words[i].charAt(0).toUpperCase();
            words[i] = firstChar;
        } // for

        // Joins the modified words back into a single string
        return words.join("");
    } // concatFirstCharacter

    const getRentingInfo = (renting) => {
        let info = renting.inventory.numberPlate + " - " + renting.request.name + " " + renting.request.surname;
        if (renting.request.materialEventName) {
            info += " - " + renting.request.materialEventName;
        } // if
        return info;
    } // getRentingInfo

    /**
     * Returns all the events in the calendar
     * @returns all the events in the calendar
     */
    const getEvents = () => {
        let events = [];

        inventoryHistory.forEach((renting) => {
            events.push({
                title: getRentingInfo(renting),
                start: new Date(renting.request.materialCollectionDate),
                end: new Date(renting.request.materialReturnDate),
                type: renting.inventory.material.type
            }); // push
        }); // forEach

        if (checkRooms) {
            for (const roomIdString in roomsWithOccupationByDate) {
                roomsWithOccupationByDate[roomIdString].forEach(entry => {
                    const roomId = parseInt(roomIdString);
                    const room = rooms.find(room => room.id === roomId);
                    const occupation = entry.occupation;
                    const numberOfBeds = room?.numberOfBeds;

                    // Only prints rooms with 1+ guests
                    if (occupation > 0) {
                        let gender = '';
                        if (entry.gender === 'M') {
                            gender = '- H'
                        } else if (entry.gender === 'F') {
                            gender = '- M';
                        } else {
                            gender = '- ?' // error!
                        } // if-else

                        events.push({
                            title: `${concatFirstCharacter(room?.name)} (${occupation}/${numberOfBeds}) ` + gender,
                            start: new Date(entry.date),
                            end: new Date(entry.date),
                            type: 'ROOM',
                            id: room?.id,
                            occupation: entry.occupation,
                            maxOccupation: room?.numberOfBeds
                        }); // push
                    }
                }); // forEach
            } // for
        } // if
        return events;
    } // getEvents

    return (
        <div>
            <PageHeader title="Reservas" />
            <Dashboard
                onViewChange={onViewChange}
                events={getEvents()}
                getGuestsInRoom={getGuestsInRoom}
                guestsInRoom={guestsInRoom}
                setSelectedRoomEvent={setSelectedRoomEvent}
                selectedRoomEvent={selectedRoomEvent}
                rooms={rooms}
                setView={setView}
            />
        </div>
    );
}

export default DashboardPage;