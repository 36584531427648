import Cookies from 'js-cookie';
import * as types from './types';
import { post, get } from "api/client";

/**
 * Redux Action To login user
 */
export const login = async (dispatch, user) => {
  try {
    dispatch({ type: types.LOGIN });
    const response = await post(`auth/login`, user);
    if (response.status === 200) {
      sessionStorage.setItem('user_id', response.data.token);
      Cookies.set('cbGbcRf', response.data.refreshToken, { expires: response.data.expiresAt });
      dispatch({ type: types.LOGIN_SUCCESS, payload: response.data.token });
      await meRequest(dispatch);
    } else {
      dispatch({
        type: types.LOGIN_FAILURE,
        error: { code: response.status, description: response.data.message },
      });
    }
  } catch (error) {
    dispatch({
      type: types.LOGIN_FAILURE,
      error: { code: 500, description: error.message },
    });
  }
};

/**
 * Redux Action To Signout User
 */
export const logout = (dispatch) => {
  dispatch({ type: types.LOGOUT });
  // localStorage.removeItem('user_id');
  sessionStorage.clear();
  Cookies.remove('cbGbcRf');
  dispatch({ type: types.LOGOUT_SUCCESS });
};

/**
 * Redux Action To me request
 */
export const meRequest = async (dispatch) => {
  try {
    dispatch({ type: types.ME_REQUEST });
    const data = await get(`auth/me`);
    dispatch({ type: types.ME_REQUEST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: types.ME_REQUEST_FAILURE,
      error: { code: 500, description: error },
    });
  }
};

export const recoverPasswordRequest = async (dispatch, email) => {
  try {
    dispatch({ type: types.RECOVER_PASSWORD_REQUEST });
    const response = await post(`auth/recover-password`, email);
    if (response.status === 201) {
      dispatch({ type: types.RECOVER_PASSWORD_REQUEST_SUCCESS });
    } else {
      dispatch({
        type: types.RECOVER_PASSWORD_REQUEST_FAILURE,
        error: { code: response.status, description: response.data.message }
      });
    }
  } catch (error) {
    dispatch({
      type: types.RECOVER_PASSWORD_REQUEST_FAILURE,
      error: { code: 500, description: error },
    });
  }
};

export const resetPassword = async (dispatch, data) => {
  try {
    dispatch({ type: types.RESET_PASSWORD });
    const response = await post(`auth/reset-password`, data);

    if (response.status === 201) {
      dispatch({ type: types.RESET_PASSWORD_SUCCESS });
    } else {
      dispatch({
        type: types.RESET_PASSWORD_FAILURE,
        error: { code: response.status, description: response.data.message }
      });
    }
  } catch (error) {
    dispatch({
      type: types.RESET_PASSWORD_FAILURE,
      error: { code: 500, description: error },
    });
  }
};

export const reset = async (dispatch) => {
  dispatch({ type: types.RESET_OPERATION_OK });
};