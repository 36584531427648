export const initialTypesState = {
  selectedTab: 0,
  types: [],
  member: null,
  loading: false,
  deleting: false,
  deleted: false,
  errorDeleting: false,
  saved: false,
  errorPersisting: false,
  persisting: false,
  enabledTypes: [],
  typesPageIndex: 1,
  typesTotal: 0,
  typesTotalPages: 0,
  historyPageIndex: 1,
  historyTotal: 0,
  historyTotalPages: 0,
  maintenanceHistory: [],
  type: undefined,
  typesMap: {},
};

export const typesReducer = (initialState = initialTypesState, action) => {
  switch (action.type) {
    case "SET_SELECTED_TAB":
      return { ...initialState, selectedTab: action.payload };

    case "GET_TYPES_CRITERIA":
      return { ...initialState, types: [], loading: true, type: { materialType: 'BOAT' } };

    case "GET_TYPES_CRITERIA_SUCCESS":
      const typesResponse = action.payload.content;
      const typeResponse = typesResponse[0]?.type;
      if (!initialState.typesMap[typeResponse]) {
        initialState.typesMap[typeResponse] = typesResponse;
      }

      return {
        ...initialState,
        types: action.payload.content,
        typesTotal: action.payload.totalElements,
        typesTotalPages: action.payload.totalPages,
        loading: false
      };

    case "GET_TYPES_CRITERIA_FAILURE":
      return { ...initialState, types: [], loading: false };

    case "PERSIST_TYPE":
      return { ...initialState, persisting: true, saved: false, errorPersisting: false };

    case "PERSIST_TYPE_SUCCESS":
      return { ...initialState, persisting: false, saved: true, errorPersisting: false };

    case "PERSIST_TYPE_FAILURE":
      return { ...initialState, persisting: false, saved: false, errorPersisting: true };

    case "RESET_SAVED":
      return { ...initialState, saved: false };

    case "RESET_ERROR":
      return { ...initialState, saved: false, deleted: false, errorPersisting: false, errorDeleting: false };

    case "GET_TYPE":
      return { ...initialState, loading: true };

    case "GET_TYPE_SUCCESS":
      return {
        ...initialState,
        type: action.payload,
        loading: false,
      };

    case "GET_TYPE_FAILURE":
      return { ...initialState, type: undefined, loading: false };


    case "GET_TYPES_ENABLED":
      return { ...initialState, enabledTypes: [], loading: true };

    case "GET_TYPES_ENABLED_SUCCESS":
      return { ...initialState, enabledTypes: action.payload, loading: false };

    case "GET_TYPES_ENABLED_FAILURE":
      return { ...initialState, enabledTypes: [], loading: false };

    case "CHANGE_TYPES_PAGE_INDEX":
      return { ...initialState, typesPageIndex: action.payload };

    case "CHANGE_HISTORY_PAGE_INDEX":
      return { ...initialState, historyPageIndex: action.payload };
    case "SET_INVENTORY_HISTORY":
      return {
        ...initialState,
        inventoryHistory: action.payload.content,
        historyTotal: action.payload.totalElements,
        historyTotalPages: action.payload.totalPages,
      };

    case "DELETE_TYPE":
      return { ...initialState, deleting: true };

    case "DELETE_TYPE_SUCCESS":
      return { ...initialState, deleting: false, deleted: true };

    case "DELETE_TYPE_FAILURE":
      return { ...initialState, deleting: false, errorDeleting: true };

    case "RESET_DELETED":
      return { ...initialState, deleted: false, errorDeleting: false };

    default:
      return { ...initialState };
  }
};
