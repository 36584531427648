
import { forwardRef } from 'react';

export const DatePickerInput = forwardRef(({ value, openCalendar }, ref) => (
    <div className="input-group">
        <div>
            <span className="input-group-addon">
                <i className="la la-calendar" />
            </span>
        </div>
        <input onClick={openCalendar} type="text" value={value} className="form-control" id="date-2" placeholder="Selecciona"/>
    </div>
));