
import cx from 'classnames';
import styles from 'pages/Views.module.scss';

export const Tabs = ({ selectedTab, setSelectedTab, tabTitles }) => {

    const getSelectedTabStyles = (tabIndex, selectedTab, text) => {
        let style;
        
        if (tabIndex === selectedTab) {
            style = (
                <a
                    className={
                        cx(
                            "text-uppercase",
                            styles["nav-link"],
                            "active",
                            styles["active"],
                            "show"
                        ) // cx
                    } // className
                    id="just-tab-1"
                    data-toggle="tab"
                    role="button"
                    aria-controls="j-tab-1"
                >
                    {text}
                </a>
            ); // style
        } else {
            style = (
                <a
                    className={
                        cx(
                            "text-uppercase",
                            styles["nav-link"]
                        ) // cx
                    } // className
                    id="just-tab-2"
                    data-toggle="tab"
                    role="button"
                    aria-controls="j-tab-2"
                >
                    {text}
                </a>
            ); // style
        } // if-else

        return style;
    } // getSelectedTabStyles

    return (
        <ul className={cx("nav nav-fill", styles["nav-tabs"])}>
            {
                tabTitles.map((title, index) => {
                    return (
                        <li key={title} className="nav-item" onClick={() => setSelectedTab(index)}>
                            {getSelectedTabStyles(index, selectedTab, title)}
                        </li>
                    ); // return
                }) // map
            }
        </ul>
    ); // return
} // Tabs
