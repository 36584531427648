
import cx from 'classnames';
export const RentHistoryTable = ({
    styles,
    columnsData,
    pagination
}) => {
    return (
        <div className="d-flex flex-column justify-content-between h-700px">
            <div className={cx(styles["widget-body"])}>
                <div className={cx(styles["history-table-container"], "table-responsive table-scroll padding-right-10")} tabIndex="2">
                    <table className="table table-striped mb-0">
                        <thead className="bg-white">
                            <tr>
                                <th>Fecha recogida</th>
                                <th>Fecha entrega</th>
                                <th>ID pedido</th>
                                <th>Cliente</th>
                                <th>Concentración</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                columnsData && columnsData.length > 0 ?
                                    columnsData
                                    :
                                    <span className="p-10">No se han encontrado resultados</span>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            {pagination}
        </div>
    ); // return
} // RentHistoryTable