
import cx from 'classnames';
import { DetailFormHeader } from '../DetailFormHeader/DetailFormHeader';
import { InventoryForm } from 'components/Form/Inventory';

export const FormContainer = ({
    styles,
    inventory,
    materialType,
}) => {
    return (
        <div className={cx(styles["widget"], "has-shadow", "h-100")}>
            <div className={cx(styles["widget-body"])}>
                <DetailFormHeader
                    inventory={inventory}
                />
                <InventoryForm
                    materialType={materialType}
                />
            </div>
        </div>
    )
}