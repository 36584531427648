const { buildPhotoPath } = require("helpers/helpers")

export const DetailFormHeader = ({inventory}) => {
    return (
        <div className="mt-5">
            {
                inventory.material.photos && inventory.material.photos.length > 0 &&
                <div className="mt-5 d-flex justify-content-center">
                    <div className="feature-img">
                        <img
                            src={buildPhotoPath(inventory.material.id, inventory.material.photos[0].file)}
                            alt={"foto"}
                            className="avatar rounded-circle d-block mx-auto"
                        />
                    </div>
                </div>
            }
            <div>
                <h3 className="text-center mt-3 mb-1">{inventory.numberPlate}</h3>
            </div>
            <div>
                <p className="text-center">{inventory.material.brand + " - " + inventory.material.model}</p>
            </div>
            <div className="em-separator separator-dashed" />
        </div>
    )
} 