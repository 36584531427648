import React, { useEffect, useState } from 'react';
import { useIntl } from "react-intl";
import cx from 'classnames';
import { getUsers } from 'state/users';
import { useSelector, useDispatch } from 'state';
import styles from 'pages/Views.module.scss';
import { PageHeader } from 'components/PageHeader';
import UsersBody from './list';
import { PAGE_SIZE } from 'utils/utils';

const UserManagement = () => {
    const { formatMessage: f } = useIntl();

    const { users, pageIndex, deleted } = useSelector('users');
    const dispatch = useDispatch();

    const [ criteria, setCriteria ] = useState(undefined);

    useEffect(() => {
        if (!deleted) {
            updateData();
        }
    }, [deleted]);

    useEffect(() => {
        updateData();
    }, [dispatch, pageIndex, criteria]);

    const updateData = () => {
        const getUsersFunction = async () => {
            await getUsers(dispatch, { pageIndex: pageIndex - 1, pageSize: PAGE_SIZE, criteria: criteria});
        }
        getUsersFunction();
    }
    return (
        <div>
            <PageHeader title={ f({ id: "users.title" }) } />
            <div className={cx(styles["widget"])}>
                <div className={cx(styles["widget-body"])}>
                    <UsersBody users={users} criteria={criteria} setCriteria={setCriteria} />
                </div>
            </div>
        </div>
    );
}

export default UserManagement;