/**
* Auth User Reducers
*/
import * as types from './types';
/**
 * initial auth user
 */
export const initialAuthState = {
  token: (sessionStorage.getItem('user_id') && sessionStorage.getItem('user_id') !== "undefined" ? sessionStorage.getItem('user_id') : null),
  me: JSON.parse(localStorage.getItem('me')),
  initializing: true,
  loading: false,
  registerUser: null,
  loginError: false,
  logged: false,
  error: null,
  admin: false,
  initials: null,
  username: null,
  userId: null,
  tokenExpired: false,
  emailDoesNotExist: false,
  sentMail: false,
  company: null,
  roles: [],
  name: '',
  surname: '',
  user900: false,
  type: '',
  icass: false,
  residence: false,
  ok: false
};

export const authReducer = (initialState = initialAuthState, action) => {
  switch (action.type) {

    case types.LOGIN:
      return { ...initialState, loading: true };

    case types.LOGIN_SUCCESS:
      return { ...initialState, loading: false, token: action.payload, error: null, logged: true };

    case types.LOGIN_FAILURE:
      return { ...initialState, loading: false, error: action.error };

    case types.LOGOUT:
      return { ...initialState };

    case types.LOGOUT_SUCCESS:
      return { ...initialState, me: null, token: null, logged: false };

    case types.LOGOUT_FAILURE:
      return { ...initialState };

    case types.ME_REQUEST:
      return { ...initialState, loading: true, logged: false, initializing: true };

    case types.ME_REQUEST_SUCCESS:
      localStorage.setItem('me', JSON.stringify(action.payload));
      return { ...initialState, loading: false, logged: true, initializing: false };

    case types.ME_REQUEST_FAILURE:
      return { ...initialState, loading: false, residence: false, icass: false, type: '', user900: false, admin: false, logged: false, me: null, initializing: false };

    case types.RESET_OPERATION_OK:
      return { ...initialState, loading: false, initializing: false, error: null, ok: false };

    case types.RECOVER_PASSWORD_REQUEST:
      return { ...initialState, loading: true, ok: false, error: undefined };

    case types.RECOVER_PASSWORD_REQUEST_SUCCESS:
      return { ...initialState, loading: false, ok: true };

    case types.RECOVER_PASSWORD_REQUEST_FAILURE:
      return { ...initialState, loading: false, error: action.error };

    case types.RESET_PASSWORD:
      return { ...initialState, loading: true, ok: false, error: undefined };

    case types.RESET_PASSWORD_SUCCESS:
      return { ...initialState, loading: false, ok: true };

    case types.RESET_PASSWORD_FAILURE:
      return { ...initialState, loading: false, error: action.error };

    default:
      return { ...initialState };
  }
}



