import { post, get, put, formDataRequest, deleteMethod } from "api/client";

export const getAllTrailerTypes = async (dispatch, pageIndex, pageSize, criteria) => {
  try {
    dispatch({ type: "GET_TRAILER_TYPES_CRITERIA" });
    let urlQuery = `material/by-criteria?material-type-code=TRAILER&pageIndex=${pageIndex}&pageSize=${pageSize}`;
    if (criteria) {
      urlQuery += `&criteria=${criteria}`
    }
    const data = await get(urlQuery);
    //endpoint call
    dispatch({ type: "GET_TRAILER_TYPES_CRITERIA_SUCCESS", payload: data }); // loading: false y members: action.payload
  } catch (error) {

    dispatch({
      type: "GET_TRAILER_TYPES_CRITERIA_FAILURE",
      error: { code: 500, description: error },
    }); //loading: false, stocks: []
  }
};

export const getAllTrailersInventory = async (dispatch, pageIndex, pageSize, criteria) => {
  try {
    dispatch({ type: "GET_TRAILER_INVENTORIES_CRITERIA" });
    let urlQuery = `inventories?type=TRAILER&pageIndex=${pageIndex}&pageSize=${pageSize}`;
    if (criteria) {
      urlQuery += `&criteria=${criteria}`
    }
    const data = await get(urlQuery);
    //endpoint call
    dispatch({ type: "GET_TRAILER_INVENTORIES_CRITERIA_SUCCESS", payload: data }); // loading: false y members: action.payload
  } catch (error) {
    dispatch({
      type: "GET_TRAILER_INVENTORIES_CRITERIA_FAILURE",
      error: { code: 500, description: error },
    }); //loading: false, stocks: []
  }
};

export const persistTrailerType = async (dispatch, data) => {
  try {
    dispatch({ type: "PERSIST_TRAILER_TYPE" });
    let response = null
    if (data.id) {
      response = await formDataRequest(`trailer-types/${data.id}`, data, "PUT");
    } else {
      response = await formDataRequest(`trailer-types`, data, "POST");
    }

    if (response.status === 204 || response.status === 201) {
      dispatch({ type: "PERSIST_TRAILER_TYPE_SUCCESS" });
    } else {
      dispatch({ type: "PERSIST_TRAILER_TYPE_FAILURE", payload: response });
    }
    //TODO: Tratamiento de errores centralizado
  } catch (error) {

    dispatch({ type: "PERSIST_TRAILER_TYPE_FAILURE", payload: error });
  }
};

export const getTrailerType = async (dispatch, id) => {
  try {
    dispatch({ type: "GET_TRAILER_TYPE" });
    const response = await get(`trailer-types/${id}`);


    dispatch({ type: "GET_TRAILER_TYPE_SUCCESS", payload: response });
  } catch (error) {

    dispatch({ type: "GET_TRAILER_TYPE_FAILURE" });
  }
};

export const persistTrailerInventory = async (dispatch, data) => {
  try {
    dispatch({ type: "PERSIST_TRAILER_INVENTORY" });
    let response = null
    if (data.id) {
      response = await put(`inventories/${data.id}`, data);
    } else {
      response = await post(`inventories`, data);
    }

    if (response.status === 204 || response.status === 201) {
      dispatch({ type: "PERSIST_TRAILER_INVENTORY_SUCCESS" });
    } else {
      dispatch({ type: "PERSIST_TRAILER_INVENTORY_FAILURE", payload: response });
    }
    //TODO: Tratamiento de errores centralizado
  } catch (error) {

    dispatch({ type: "PERSIST_TRAILER_INVENTORY_FAILURE", payload: error });
  }
};

export const getTrailerTypesEnabled = async (dispatch) => {
  try {
    dispatch({ type: "GET_TRAILER_TYPES_ENABLED" }); // stocks: [], loading: true
    const data = await get(`types/by-type?material-type-code=TRAILER&enabled=true`);
    //endpoint call
    dispatch({ type: "GET_TRAILER_TYPES_ENABLED_SUCCESS", payload: data }); // loading: false y members: action.payload
  } catch (error) {

    dispatch({
      type: "GET_TRAILER_TYPES_ENABLED_FAILURE",
      error: { code: 500, description: error },
    }); //loading: false, stocks: []
  }
};

export const getTrailerInventory = async (dispatch, id, pageIndex, pageSize) => {
  try {
    dispatch({ type: "GET_TRAILER_INVENTORY" });
    const response = await get(`inventories/${id}`);
    const responseHistory = await get(`inventories/${response.id}&materialType=${response.material.materialType}&pageIndex=${pageIndex}&pageSize=${pageSize}`);
    dispatch({ type: "GET_TRAILER_INVENTORY_SUCCESS", payload: response });
    dispatch({ type: "SET_TRAILER_INVENTORY_HISTORY", payload: responseHistory });

  } catch (error) {

    dispatch({ type: "GET_TRAILER_INVENTORY_FAILURE" });
  }
};

export const deleteTrailerType = async (dispatch, id) => {
  try {
    dispatch({ type: "DELETE_TRAILER_TYPE" });
    const response = await deleteMethod(`trailer-types/${id}`);

    if (response.status === 204 || response.status === 201) {
      dispatch({ type: "DELETE_TRAILER_TYPE_SUCCESS" });
    } else {
      dispatch({ type: "DELETE_TRAILER_TYPE_FAILURE", payload: response });
    }
    //TODO: Tratamiento de errores centralizado
  } catch (error) {

    dispatch({ type: "DELETE_TRAILER_TYPE_FAILURE", payload: error });
  }
};

export const deleteTrailerInventory = async (dispatch, id) => {
  try {
    dispatch({ type: "DELETE_TRAILER_INVENTORY" });
    const response = await deleteMethod(`inventories/${id}`);

    if (response.status === 204 || response.status === 201) {
      dispatch({ type: "DELETE_TRAILER_INVENTORY_SUCCESS" });
    } else {
      dispatch({ type: "DELETE_TRAILER_INVENTORY_FAILURE", payload: response });
    }
    //TODO: Tratamiento de errores centralizado
  } catch (error) {

    dispatch({ type: "DELETE_TRAILER_INVENTORY_FAILURE", payload: error });
  }
};

export const getTrailerMaintenanceHistory = async (dispatch, id, pageIndex, pageSize) => {
  try {
    dispatch({ type: "GET_TRAILER_INVENTORY_MAINTENANCE_HISTORY" });
    const response = await get(`maintenance?inventory_id=${id}&pageIndex=${pageIndex}&pageSize=${pageSize}`);


    dispatch({ type: "GET_TRAILER_INVENTORY_MAINTENANCE_HISTORY_SUCCESS", payload: response });

  } catch (error) {
    dispatch({ type: "GET_TRAILER_INVENTORY_MAINTENANCE_HISTORY_FAILURE" });
  }
};