import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'state';
import cx from "classnames";
import styles from './Login.module.scss';
import { FormText, Alert } from "reactstrap";

import { useIntl } from "react-intl";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { actions } from "state/auth";

const RecoverPasswordForm = (props) => {
    const { formatMessage: f } = useIntl();
    const dispatch = useDispatch();

    const { loading, error, ok } = useSelector('auth');

    const recoverPasswordSchema = Yup.object().shape({
        email: Yup.string().required(f({ id: "form.errors.required" })).email(f({ id: "form.errors.format" }))
    });

    const { register: registerRecoverPassword, handleSubmit: handleSubmitRecoverPassword, errors: errorsRecoverPassword } = useForm({
        mode: "onBlur",
        resolver: yupResolver(recoverPasswordSchema)
    });

    const onSubmitRecoverPassword = async (email) => {
        await actions.recoverPasswordRequest(dispatch, email);
    }

    return (
        <React.Fragment>
            <h3>{ f({ id: "recoverPassword.title" })}</h3>
            <span className="text-align-center">{ f({ id: "recoverPassword.subtitle" }) }</span>
            {error && <Alert className="mt-1" color="danger">
                {error.description}
            </Alert>}
            {ok && <Alert className="mt-1" color="success">
                {f({id: "recoverPassword.success"})}
            </Alert>}
            <form onSubmit={handleSubmitRecoverPassword(onSubmitRecoverPassword)} autoComplete="off">
                <div className={cx(styles.group, "material-input")}>
                <input
                    type="text"
                    name="email"
                    id="email"
                    {...registerRecoverPassword('email')}
                    placeholder={ f({ id: "recoverPassword.email"})}
                    />

                {errorsRecoverPassword?.email && (
                    <FormText color="red" className="text-danger">{errorsRecoverPassword.email.message}</FormText>
                )}
                </div>
                <div className="button text-center">
                <button disabled={loading}  className="btn btn-primary mr-1" type="submit">{f({id: loading ? "recoverPassword.submitting" : "recoverPassword.submit"})}</button>
                </div>
            </form>
            <div className={cx(styles.back)}>
                <a role="button" onClick={() => props.setShowRecoverPasswordForm(false)}>{ f({ id: "app.back" })}</a>
            </div>
        </React.Fragment>
    )
}

export default RecoverPasswordForm;