import Cookies from 'js-cookie';
const API_URL = process.env.REACT_APP_API_URL_FILES;


export function isEmptyFunction(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key)) {
            if (obj[key]) {
                return false
            }
        }
    }
    return true;
}

export const buildPhotoPath = (id, fileName) => {
    return API_URL + "/material/" + id + "/images/" + fileName;
}

export const buildMaterialPdfPath = (id, fileName) => {
    return API_URL + "/material/" + id + "/pdf/" + fileName;
}

export const buildMaintenancePdfPath = (id, fileName) => {
    return API_URL + "/maintenance/" + id + "/pdf/" + fileName;
}



const buildHeaderGeneric = (token, contentType) => {
    let header = {};
    if (contentType === "multipart/form-data") {
        header = {};
    } else {
        header = {
            "Content-Type": contentType ? contentType : "application/json",
            // "Accept": "application/json"
        }
    }

    if (token) {
        header = { ...header, "Authorization":  `Bearer ${token}`};
    }

    return header;

}

export const buildHeader = (contentType) => {
    return buildHeaderGeneric(sessionStorage.getItem('user_id'), contentType);
}

export const buildHeaderRefresh = () => {
    return buildHeaderGeneric(Cookies.get("cbGbcRf"));
}

export const bytesToMegabytes = (sizeInBytes) => {
    return Math.round(((sizeInBytes / 1024 / 1024) + Number.EPSILON) * 100) / 100;
}

export const formatDecimal = (decimal) => {
    return decimal.toLocaleString('es-ES', { maximumFractionDigits: 2 });
}

export const calculateFilesSize = (images, pdf) => {
    let docsSize = images.reduce((a,b) => a + (b.size ? b.size : 0), 0);
    const isTherePdf = pdf?.[0];

    if (isTherePdf && pdf[0].size) {
        docsSize += pdf[0].size;
    }

    return docsSize;
}

export const filesSizeExceedMaxAllowed = (docsSize) => {
    return docsSize > process.env.REACT_APP_MAX_ATTACHED_FILES_SIZE;
}

