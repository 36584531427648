import React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

function ErrorMessage({ message }) {
  // Verifica si el mensaje contiene '#'
  const containsHash = message.includes('#');
  
  // Divide el mensaje en un array si contiene '#', de lo contrario devuelve un array con el mensaje original
  const messages = containsHash ? message.split('#') : [message];

  return (
    <Stack sx={{ width: '100%', marginBottom: '2rem' }} spacing={2}>
      <Alert severity="error">
        {containsHash ? (
          // Si el mensaje contiene '#', muestra cada mensaje como un elemento de una lista sin bullets
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            {messages.map((msg, index) => (
              <div key={index}>{msg}</div>
            ))}
          </div>
        ) : (
          // Si no, muestra el mensaje único tal cual
          message
        )}
      </Alert>
    </Stack>
  );
}

export default ErrorMessage;
