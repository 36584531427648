import { get } from 'api/client';
import cx from 'classnames';
import { InventoryList } from 'components/InventoryList';
import { MaterialTypeList } from 'components/MaterialTypeList';
import { PageHeader } from 'components/PageHeader';
import styles from 'pages/Views.module.scss';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'state';
import { deleteInventory, getInventoryForType } from 'state/inventories';
import { deleteType, getAllTypes } from 'state/types';
import { INVENTORY_TAB, PAGE_SIZE, TYPES_TAB } from 'utils/utils';
import { Tabs } from '../Tabs/Tabs';

export const MaterialManagement = ({
    pageTitle,
    materialType
}) => {

    const [criteria, setCriteria] = useState(undefined);
    const [inventoryToDelete, setInventoryToDelete] = useState(undefined);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { selectedTab } = useSelector('types');
    const setSelectedTab = (selectedTab) => {
        dispatch({ type: "SET_SELECTED_TAB", payload: selectedTab });
    }

    const {
        types,
        typesPageIndex,
        typesTotal,
        typesTotalPages,
        deleted,
        errorDeleting,
        deleting,
        loading
    } = useSelector('types');

    const {
        inventories,
        inventoryPageIndex,
        inventoryTotal,
        inventoryTotalPages
    } = useSelector('inventories');

    /**
     * Changes the inventory page index in the state
     * @param {number} newPageIndex - The page index to set
     */
    const onInventoryPageIndexChange = (newPageIndex) => {
        dispatch({ type: `CHANGE_INVENTORY_PAGE_INDEX`, payload: newPageIndex });
    }

    /**
     * Changes the types page index in the state
     * @param {number} newPageIndex - The page index to set
     */
    const onTypePageIndexChange = (newPage) => {
        dispatch({ type: `CHANGE_TYPES_PAGE_INDEX`, payload: newPage });
    }

    /**
     * Checks if a type can be deleted
     * @param {string} type - The type to be deleted
     * @returns true if the type can be deleted, 
    *               false otherwise
        */
    const checkDeleteType = async (type) => {
        const urlQuery = 'types/check-delete/' + type.id;
        return await get(urlQuery);
    } // checkDeleteType

    /**
     * Checks if an inventory can be deleted
     * @param {*} inventory - The inventory to delete
     */
    const checkDeleteInventory = async (inventory) => {
        const urlQuery = 'inventories/check-delete/' + inventory.id;
        await get(urlQuery);
    } // checkDeleteInventory

    /**
     * Deletes a given material type (boat, trailer or van) from the database
     * @param {*} typeIdToDelete - The material type's ID to delete
     */
    const onDeleteConfirmType = async (typeIdToDelete) => {
        await deleteType(dispatch, typeIdToDelete);
    } // onDeleteConfirmType

    /**
     * Navigates to the page that edits the inventory
     * @param {number} id - The inventory's ID
     */
    const onEditInventory = (id) => {
        navigate(`/${materialType}s/inventories/${id}`);
    }

    /**
     * Handles the edition of a type
     * @param {number} id - The type's ID
     */
    const onEditType = (id) => {
        navigate(`/${materialType}s/` + id);
    } // onEdit

    const typesInfo = {
        types,
        pageIndex: typesPageIndex,
        totalItems: typesTotal,
        totalPages: typesTotalPages,
        onEdit: onEditType,
        checkDelete: checkDeleteType,
        onDeleteConfirm: onDeleteConfirmType
    };

    useEffect(() => {
        setCriteria(undefined);
    }, [selectedTab]);

    useEffect(() => {
        updateData();
    }, [selectedTab, typesPageIndex, inventoryPageIndex, dispatch, criteria]);

    useEffect(() => {
        if (!deleted) {
            updateData();
        }
    }, [deleted]);

    useEffect(() => {
        onTypePageIndexChange(1);
        onInventoryPageIndexChange(1);
    }, []);

    const tabTitles = [`Tipos de ${pageTitle.toLowerCase()}`, "Inventario"];

    const updateData = () => {
        if (selectedTab === TYPES_TAB) {
            const getTypesFunction = async () => {
                await getAllTypes(dispatch, materialType, typesPageIndex - 1, PAGE_SIZE, criteria);
            }
            getTypesFunction();
        } else if (selectedTab === INVENTORY_TAB) {
            const getInventoryFunction = async () => {
                await getInventoryForType(dispatch, materialType, inventoryPageIndex - 1, PAGE_SIZE, criteria);
            }
            getInventoryFunction();
        } // if-else if
    } // updateData


    const onDeleteConfirmInventory = async (inventoryToDelete) => {
        await deleteInventory(dispatch, inventoryToDelete.id);
    } // onDeleteConfirmInventory


    return (
        <>
            <PageHeader title={pageTitle} />
            <div className={cx(styles["widget"])}>
                <div className={cx(styles["widget-body"])}>
                    <Tabs
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        tabTitles={tabTitles}
                    />
                    {
                        selectedTab === TYPES_TAB ?

                            <MaterialTypeList
                                typesInfo={{
                                    types,
                                    pageIndex: typesPageIndex,
                                    totalItems: typesTotal,
                                    totalPages: typesTotalPages,
                                    onEdit: onEditType,
                                    checkDelete: checkDeleteType,
                                    onDeleteConfirm: onDeleteConfirmType
                                }}
                                onEdit={typesInfo.onEdit}
                                checkDelete={typesInfo.checkDelete}
                                onDeleteConfirm={typesInfo.onDeleteConfirm}
                                criteria={criteria}
                                setCriteria={setCriteria}
                                deleted={deleted}
                                deleting={deleting}
                                errorDeleting={errorDeleting}
                                loading={loading}
                                materialType={materialType}
                                setPage={onTypePageIndexChange}
                            />

                            :

                            <InventoryList
                                inventoryInfo={{
                                    inventory: inventories,
                                    pageIndex: inventoryPageIndex,
                                    totalItems: inventoryTotal,
                                    totalPages: inventoryTotalPages,
                                    onEdit: onEditInventory,
                                    checkDelete: checkDeleteInventory,
                                    onDeleteConfirm: onDeleteConfirmInventory
                                }} // inventoryInfo
                                criteria={criteria}
                                setCriteria={setCriteria}
                                deleted={deleted}
                                deleting={deleting}
                                errorDeleting={errorDeleting}
                                loading={loading}
                                inventoryToDelete={inventoryToDelete}
                                setInventoryToDelete={setInventoryToDelete}
                                materialType={materialType}
                                setPage={onInventoryPageIndexChange}
                            />
                    }
                </div>
            </div>
        </>
    ); // return
} // MaterialType