import moment from "moment-timezone";

export const convertStringDateToISO8601Date = (date) => {
  return moment(date, moment.ISO_8601).format("DD/MM/YYYY");
}

export const convertToInputDateFormat = (date) => {
  if (!date || isNaN(new Date(date))) {
    return "";
  }
  const aux = new Date(date).toLocaleDateString()?.split("/");

  if (aux[0].length < 2) {
    aux[0] = "0" + aux[0];
  }
  if (aux[1].length < 2) {
    aux[1] = "0" + aux[1];
  }

  return aux[2] + "-" + aux[1] + "-" + aux[0];
};

export const formatDateSimpleString = (date) => {
  if (!date) {
    return null;
  }

  const formattedDate = moment(date)
    .tz(moment.tz.guess())
    .format("DD/MM/YYYY");

  return formattedDate;
}

export const createDateObject = (inputDate) => {
  const date = moment(inputDate);
  const timezone = moment.tz.guess();

  return {
    date: date,
    timezone: timezone,
  };
};

/**
 * Adds a number of days to a date
 * @param {Object} date - The date to which the days should be added
 * @param {number} days - The number of days to add to the date
 * @returns: the date with the added days
 */
export const addDaysToDate = (date, days) => {
  let newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
}

/**
 * Returns today's date
 * @returns today's date
 */
export const getTodaysDate = () => {
  let date = new Date();
  date.setHours(0,0,0,0);
  return new Date(date);
}