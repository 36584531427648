import cx from 'classnames';
import { useRef } from 'react';
import { requestStatus } from "utils/enums";
import { getStatusNameById, useOutsideAlerter } from "utils/utils";
import '../invoice.css';

export const ActionButton = (props) => {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, props.hideOptions);

    const getBtnColor = () => {
        let color;
        switch (props.request.status) {
            case requestStatus.CONFIRMATION_PENDING:
                color = "alert";
                break;

            case requestStatus.CONFIRMED_AND_ACCEPTANCE_PENDING:
                color = "info";
                break;

            case requestStatus.ACCEPTED:
                color = "success";
                break;

            case requestStatus.CANCELLED:
                color = "danger";
                break;

            default:
                color = null;
        }

        return color;
    }

    if (props.request.status === requestStatus.CANCELLED) {
        return (
            <div className="col-xl-6 col-md-6 col-sm-6 col-6 d-flex justify-content-end">
                <div >
                    <button
                        disabled={true}
                        type="button"
                        className={"btn mr-1 mb-2" + cx("btn", getBtnColor())}
                    >
                        {getStatusNameById(props.request.status)}
                    </button>
                </div>
            </div>
        )
    }

    return (
        <div className="col-xl-6 col-md-6 col-sm-6 col-6 d-flex justify-content-end">
            <button
                disabled={props.updatingRequest}
                className={"btn mr-1 mb-2 " + cx("btn", getBtnColor())}
            >
                {
                    props.updatingRequest ?
                        props.f({ id: 'app.updating' })
                        :
                        getStatusNameById(props.request.status)
                }
            </button>
        </div>
    )
}