import { Link } from "react-router-dom"
import { useIntl } from 'react-intl';


export const AddButton = ({url}) => {

    const { formatMessage: f } = useIntl();
    
    return (
        <Link to={url}>
            <button
                className="btn btn-primary buttons-copy buttons-html5"
                tabIndex="0"
                aria-controls="export-table"
            >
                <i className="la la-plus" />
                <span>{f({ id: 'app.add' })}</span>
            </button>
        </Link>
    )
}