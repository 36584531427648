import cx from 'classnames';
import { MaterialSelect } from 'components/EditRequestContainer/ModifyRequest/MaterialSelection/MaterialSelect';
import { NewMaterialTypeForRequestContainer } from 'components/EditRequestContainer/ModifyRequest/MaterialSelection/NewMaterialTypeForRequest';
import styles from 'pages/Views.module.scss';
import { useState } from 'react';
import { useDispatch, useSelector } from 'state';
import { MaterialProposalDates } from './MaterialProposalDates';

export const MaterialSelection = ({
    modifiedRequest,
    setModifiedRequest,
    setNewAddedInventories,
    setNewMaterials,
    isRequestReadOnly,
    chosenInventoriesForMaterials,
    setChosenInventoriesForMaterials,
    materialsWithNullInventory,
    newMaterials,
    newAddedInventories,
    setMaterialsWithNullInventory
}) => {

    const { inventoriesForRequest } = useSelector("inventories");
    const dispatch = useDispatch();

    const {
        loading,
        rentingsForRequest,
        request
    } = useSelector("requests");

    const requestedMaterials = request.materialRequest;
    const [showAddMaterials, setShowAddMaterials] = useState(false);

    /**
     * Removes a material which had been added manually.
     */
    const removeFromNewMaterials = () => {
        setNewMaterials([]);
    } // removeFromNewMaterials

    /**
     * Handles the addition of a new material type.
     * @param {string} material - The material type to be added.
    */
    const onAddNewMaterial = (material) => {
        dispatch({ type: "RESET_DATE_HAS_CHANGE" });
        setNewMaterials([...newMaterials, material]);
        setShowAddMaterials(false);
    }

    /**
     * Shows the new materials to be added to the request.
     */
    const onShowAddMaterials = () => {
        setShowAddMaterials(!showAddMaterials);
    } // onShowAddMaterials

    /**
     * Hides the new materials that were to be added to the request.
    */
    const hideMaterials = () => {
        setShowAddMaterials(false);
    } // hideMaterials

    return (
        <>
            <div className={cx(styles["widget-body"], styles["min-height-0"], "pb-0")}>
                {
                    (requestedMaterials.length !== 0 || rentingsForRequest.length !== 0) &&
                    <>
                        <MaterialProposalDates

                            request={modifiedRequest}
                            setRequest={setModifiedRequest}
                            disabled={isRequestReadOnly()}
                        />
                        {
                            // No rentings
                            rentingsForRequest.length === 0
                                ?
                                requestedMaterials.map((requestedMaterial) => {
                                    return (
                                        <div key={requestedMaterial.id}>
                                            <MaterialSelect
                                                requestedMaterial={requestedMaterial.material}
                                                chosenInventoriesForMaterial={chosenInventoriesForMaterials}
                                                setChosenInventoriesForMaterial={setChosenInventoriesForMaterials}
                                                materialsWithEmptyInventories={materialsWithNullInventory}
                                                disabled={isRequestReadOnly()}
                                                removeFromNewMaterials={removeFromNewMaterials}
                                                setMaterialsWithNullInventory={setMaterialsWithNullInventory}
                                            />
                                        </div>
                                    ); // return
                                }) // map
                                :
                                // Rentings exist
                                Object.keys(chosenInventoriesForMaterials).map(materialId => {
                                    const renting = rentingsForRequest.find(
                                        renting => renting.inventory.material.id === parseInt(materialId)
                                    ); // renting

                                    // In case there is no renting for the selected inventory
                                    const inventory = inventoriesForRequest.find(
                                        inventoryToSearch => inventoryToSearch.material.id === parseInt(materialId)
                                    ); // find

                                    return (
                                        <div key={materialId}>
                                            <MaterialSelect
                                                disabled={isRequestReadOnly()}
                                                requestedMaterial={renting?.inventory.material || inventory?.material}
                                                inventory={renting?.inventory}
                                                chosenInventoriesForMaterial={chosenInventoriesForMaterials}
                                                setChosenInventoriesForMaterial={setChosenInventoriesForMaterials}
                                                materialsWithEmptyInventories={materialsWithNullInventory}
                                                removeFromNewMaterials={removeFromNewMaterials}
                                                setMaterialsWithNullInventory={setMaterialsWithNullInventory}
                                            />
                                        </div>
                                    ); // return
                                }) // map
                        } {/* rentingsForRequest.length === 0 */}
                    </>
                } { /* (requestedMaterials.length !== 0 || rentingsForRequest.length !== 0) */}
            </div>

            {
                newMaterials.length > 0 &&
                <div className={cx(styles["widget-body"], styles["min-height-0"])}>
                    {
                        /*
                         * If no rentings exist and there is no material request,
                         * the date selection fields are shown when adding a new material
                         */

                        (rentingsForRequest.length === 0 && request.materialRequest.length === 0) &&
                        <MaterialProposalDates
                            request={modifiedRequest}
                            setRequest={setModifiedRequest}
                            disabled={isRequestReadOnly()}
                        />
                    }

                    {
                        newMaterials.map((newMaterialType) => {
                            return (
                                <MaterialSelect
                                    disabled={isRequestReadOnly()}
                                    newMaterialType={newMaterialType}
                                    chosenInventoriesForMaterial={newAddedInventories}
                                    setChosenInventoriesForMaterial={setNewAddedInventories}
                                    previouslyAddedMaterialAndInventory={chosenInventoriesForMaterials}
                                    materialsWithEmptyInventories={materialsWithNullInventory}
                                    removeFromNewMaterials={removeFromNewMaterials}
                                    setMaterialsWithNullInventory={setMaterialsWithNullInventory}
                                />
                            ); // return
                        }) // map
                    }
                </div>
            } {/*  newMaterials.length > 0 */}


            <NewMaterialTypeForRequestContainer
                onAddNewMaterial={onAddNewMaterial}
                loading={loading}
                disabled={isRequestReadOnly()}
                onShowAddMaterials={onShowAddMaterials}
                showAddMaterials={showAddMaterials}
                hideMaterials={hideMaterials}
                styles={styles} />
        </>
    ); // return
};