import { useIntl } from 'react-intl';
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import Select from "react-select";
import {
    months,
    requestDateFilters,
    requestStatusFilters,
    weekDays
} from "utils/enums";

import reactSelectCustomProps from "utils/reactSelectCustomProps";
import { getPlaceHolderDates } from 'utils/utils';
import { FromDateCustomInput } from './FromDateCustomInput';
import { UntilDateCustomInput } from './UntilDateCustomInput/UntilDateCustomInput';
import { useDispatch, useSelector } from 'state';

export const RequestFilters = ({
    url,
    applyFilters,
    filterByCity,
    cleanFilters,
    setFilterByCity
}) => {

    const { formatMessage: f } = useIntl();
    const dispatch = useDispatch();

    const {
        dateFromFilter,
        dateUntilFilter,
        dateType,
        statusFilter,
    } = useSelector('requests');

    return (
        <div className="row request-filters">

            <div className="col-xxl-3 col-xl-3 col-lg-12 col-md-12 padding-top-button-filter-lg">
                <div className="dropdown bootstrap-select show-tick show-menu-arrow w-100">
                    <Select
                        options={requestStatusFilters}
                        noOptionsMessage={() => ""}
                        value={statusFilter}
                        isClearable={false}
                        isMulti={true}
                        styles={reactSelectCustomProps}
                        placeholder="Estado/s"
                        onChange={(data) => dispatch({ type: "SET_STATUS_FILTER", payload: data })}
                    />
                </div>
            </div>

            <div className="d-flex align-items-center col-xxl-3 col-xl-3  col-lg-4 col-md-12 padding-top-button-filter-lg">
                <label className="label-filter">{f({ id: 'search.dates.label' })}</label>

                <Select
                    options={requestDateFilters}
                    noOptionsMessage={() => ""}
                    value={dateType}
                    isClearable={false}
                    isMulti={false}
                    styles={reactSelectCustomProps}
                    placeholder="Estado/s"
                    onChange={(type) => dispatch({ type: "SET_DATE_TYPE", payload: type })}
                />
            </div>

            <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 padding-top-button-filter-lg">
                <div className="form-group">
                    <DatePicker
                        key="from"
                        value={dateFromFilter}
                        render={<FromDateCustomInput placeholder={getPlaceHolderDates(dateType).placeHolderFrom} />}
                        containerClassName="d-block"
                        plugins={[
                            <DatePanel
                                removeButton={true}
                                header={f({ id: 'search.datepicker.selection' })}
                            />
                        ]}
                        weekDays={weekDays}
                        months={months}
                        format={"DD/MM/YYYY"}
                        onFocusedDateChange={(date) => dispatch({ type: "SET_DATE_FROM_FILTER", payload: date ? new Date(date) : '' })}
                    />
                </div>
            </div>

            <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 padding-top-button-filter-lg">
                <div className="form-group">
                    <DatePicker
                        key="until"
                        value={dateUntilFilter}
                        render={<UntilDateCustomInput placeholder={getPlaceHolderDates(dateType).placeHolderUntil} />}
                        containerClassName="d-block"
                        plugins={[
                            <DatePanel
                                removeButton={true}
                                header={f({ id: 'search.datepicker.selection' })}
                            />
                        ]}
                        weekDays={weekDays}
                        months={months}
                        format={"DD/MM/YYYY"}
                        onFocusedDateChange={(date) => dispatch({ type: "SET_DATE_UNTIL_FILTER", payload: date ? new Date(date) : '' })}
                    />
                </div>
            </div>

            <div className="col-xxl-2 col-lg-12 col-md-12 d-flex justify-content-end align-items-center padding-top-button-filter-lg">
                <a className="padding-right-10"
                    href={url}
                >
                    <button
                        onClick={applyFilters}
                        className="btn btn-primary buttons-copy buttons-html5"
                        tabIndex="0"
                        aria-controls="export-table">
                        <i className="la la-filter" />
                        <span>{f({ id: 'search.apply' })}</span>
                    </button>
                </a>
                <a href={url}>
                    <button onClick={cleanFilters}
                        className="btn btn-primary buttons-copy buttons-html5 clean-filters"
                        tabIndex="0"
                        aria-controls="export-table">
                        <i className="la la-close" />
                        <span>{f({ id: 'search.clean' })}</span>
                    </button>
                </a>
            </div>
            <div className='col-4 mt-20'>
                {
                    dateType.value !== 'roomCheckInDate' &&
                    <div className='d-flex' style={{ gap: '10px' }}>
                        <input type="checkbox" onChange={() => setFilterByCity(!filterByCity)} />
                        <label>Filtrar sólo por Santander</label>
                    </div>
                }
            </div>
        </div>
    )
}