import MenuItem from "./MenuItem";
import { styled } from '@mui/material/styles';

// Definir el componente StyledDropdown utilizando el método styled de MUI
const StyledDropdown = styled('ul')(({ theme, depthLevel }) => ({
  position: 'absolute',
  right: 'auto',
  left: 0,
  boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
  fontSize: '0.875rem',
  zIndex: '9999',
  minWidth: '11rem',
  padding: '0.5rem 0.5rem',
  listStyle: 'none',
  backgroundColor: '#fff',
  borderRadius: '0.375rem',
  letterSpacing: '0.2px',
  display: 'none',
  opacity: 0,
  transform: 'translateY(-20px)', /* Ajusta este valor según necesites */
  transition: 'opacity 0.9s ease, transform 0.9s ease', /* Ajusta la duración y la función de timing según prefieras */
  '&.show': {
    display: 'block',
    opacity: '1',
    transform: 'translateY(0)'
  },
  ...(depthLevel > 1 && {
    position: 'absolute',
    left: '100%',
    top: '-7px'
  }),
  // Condición para mostrar u ocultar el dropdown, similar a la clase "show"
  // Aquí puedes añadir los estilos que se aplicarían cuando el dropdown está visible
}));

const Dropdown = ({ submenus, dropdown, depthLevel }) => {
  depthLevel = depthLevel + 1;
  
  return (
    <StyledDropdown depthLevel={depthLevel} className={(dropdown) ? 'show' : '' }>
      {submenus.map((submenu, index) => (
        <MenuItem items={submenu} key={index} depthLevel={depthLevel} />
      ))}
    </StyledDropdown>
  );
};

export default Dropdown;
