import { MaterialManagement } from 'components/Shared/MaterialManagement/MaterialManagement';
import { PAGE_TITLES } from 'utils/utils';

export const VanManagementPage = () => {
    const materialType = 'van';
    return (
        <MaterialManagement
            pageTitle={PAGE_TITLES[materialType]}
            materialType={materialType}
        />
    ); // return
} // VanManagementPage