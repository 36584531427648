import { deleteMethod, formDataRequest, get } from "api/client";

export const getMaintenance = async (dispatch, id) => {
  try {
    dispatch({ type: "GET_MAINTENANCE_BY_ID" });
    let urlQuery = `maintenances/${id}`;
    
    const data = await get(urlQuery);
    //endpoint call
    dispatch({ type: "GET_MAINTENANCE_BY_ID_SUCCESS", payload: data }); // loading: false y members: action.payload
  } catch (error) {

    dispatch({
      type: "GET_MAINTENANCE_BY_ID_FAILURE",
      error: { code: 500, description: error },
    }); //loading: false, stocks: []
  }
};

export const persistMaintenance = async (dispatch, data) => {
  try {
    dispatch({ type: "PERSIST_MAINTENANCE" });
    let response = null
    if (data.id) {
      response = await formDataRequest(`maintenances/${data.id}`, data, "PUT");
    } else {
      response = await formDataRequest(`maintenances`, data, "POST");
    }

    if (response.status === 204 || response.status === 201) {
      dispatch({ type: "PERSIST_MAINTENANCE_SUCCESS" });
    } else {
      dispatch({ type: "PERSIST_MAINTENANCE_FAILURE", payload: response });
    }
    //TODO: Tratamiento de errores centralizado
  } catch (error) {
    dispatch({ type: "PERSIST_MAINTENANCE_FAILURE", payload: error });
  }
};

export const getMaintenanceHistoryForInventory = async (dispatch, id, pageIndex, pageSize, criteria) => {
  // Prevents criteria being sent as undefined in the query string
  let url = `maintenances?inventory-id=${id}&page-index=${pageIndex}&page-size=${pageSize}`;
  if (criteria) {
    criteria += `&criteria=${criteria}`;
  }
  try {
    dispatch({ type: "GET_MAINTENANCE_HISTORY_FOR_INVENTORY" });
    const response = await get(url);

    dispatch({ type: "GET_MAINTENANCE_HISTORY_FOR_INVENTORY_SUCCESS", payload: response });

  } catch (error) {
    dispatch({ type: "GET_MAINTENANCE_HISTORY_FOR_INVENTORY_FAILURE" });
  }
};

export const deleteMaintenance = async (dispatch, id) => {
  try {
    dispatch({ type: "DELETE_MAINTENANCE" });
    const response = await deleteMethod(`maintenances/${id}`);

    if (response.status === 204 || response.status === 201) {
      dispatch({ type: "DELETE_MAINTENANCE_SUCCESS" });
    } else {
      dispatch({ type: "DELETE_MAINTENANCE_FAILURE", payload: response });
    }
    //TODO: Tratamiento de errores centralizado
  } catch (error) {
    dispatch({ type: "DELETE_MAINTENANCE_FAILURE", payload: error });
  }
};
