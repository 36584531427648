import { yupResolver } from "@hookform/resolvers/yup";
import { Dropzone } from 'components/Shared/Dropzone';
import {
    buildPhotoPath,
    bytesToMegabytes,
    calculateFilesSize,
    filesSizeExceedMaxAllowed,
    formatDecimal
} from 'helpers/helpers';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import { Col, Form, Row } from "reactstrap";
import { useDispatch, useSelector } from 'state';
import { persistType } from 'state/types';
import { materials } from "utils/enums";
import * as Yup from "yup";

const TrailerTypeForm = () => {

    const { persisting, saved, errorPersisting, type } = useSelector('types');
    const dispatch = useDispatch();
    const { formatMessage: f } = useIntl();
    const navigate = useNavigate();
    const [files, setFiles] = useState([]);
    const [filesToDeleteInDB, setFilesToDeleteInDB] = useState([]);

    useEffect(() => {
        //Si tenemos fotos persistidas, construimos json para mostrarlas
        setFiles(type.photos && type.photos.length > 0 ?
            type.photos.map(fileData => Object.assign(fileData, {
                preview: buildPhotoPath(type.id, fileData.file),
                name: fileData.file,
                customId: fileData.id, //id para manejar los borrados
                fromDb: true
            }))
            :
            []
        )
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': []
        },
        onDrop: acceptedFiles => {
            setFiles([...files, ...acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file),
                customId: Math.random()
            }))]);
        }
    });

    const [enabledOn, setEnabledOn] = useState(type.id ? type.enabled : null);
    const [enabledOff, setEnabledOff] = useState(type.id ? !type.enabled : null);
    const [pdfName, setPdfName] = useState(type.id ? type.datasheet : '');

    useEffect(() => {
        if (saved) {
            if (!type.id) {
                NotificationManager.success("Creado correctamente");
                dispatch({ type: "RESET_SAVED" });
                navigate("/trailers");
            } else {
                NotificationManager.success("Modificado correctamente");
                dispatch({ type: "RESET_SAVED" });
            }
            setFilesToDeleteInDB([])
        } else if (errorPersisting) {
            NotificationManager.error("Se ha producido un error");
            dispatch({ type: "RESET_ERROR" });
        }
    }, [saved, errorPersisting]);

    const trailerTypeSchema = Yup.object({
        brand: Yup.string().required("Por favor, debes especificar una marca."),
        model: Yup.string().required("Por favor, debes especificar un modelo."),
        enabled: Yup.string().required("Debes seleccionar una opción."),
        pdf: Yup.mixed().test("fileFormat", "El documento debe ser un PDF", value => {
            // skips test if no file is selected
            if (!value?.length) {
                return true;
            } // if
            return value[0]?.type === "application/pdf";
        }), // pdf
    }).required();

    const { register, handleSubmit, formState: {errors}, setValue, setError, clearErrors } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(trailerTypeSchema),
        defaultValues: {
            ...type,
            pdfName: type.datasheet || ""  //FIXME:
        },
    });

    const onEnabledOnChange = () => {
        setEnabledOn(true)
        setEnabledOff(false)
        setValue("enabled", true);
    }

    const onEnabledOffChange = () => {
        setEnabledOff(true)
        setEnabledOn(false)
        setValue("enabled", false);
    }

    const removeFile = (customId) => {
        //Si es una foto guardada en bd
        const fileToRemove = files.find((el) => el.fromDb && el.customId === customId);
        if (fileToRemove) {
            setFilesToDeleteInDB([...filesToDeleteInDB, fileToRemove]);
        }
        const newList = files.filter((el) => { URL.revokeObjectURL(el.preview); return el.customId !== customId })
        setFiles(newList);
    }

    const onSubmit = async (data) => {
        clearErrors('pdf');
        const docsSize = calculateFilesSize(files, data.pdf);
        if (filesSizeExceedMaxAllowed(docsSize)) {
            setError('pdf', { type: 'custom', message: f({ id: "form.errors.maxSize" }, { size: formatDecimal(bytesToMegabytes(docsSize)), maxSize: formatDecimal(bytesToMegabytes(process.env.REACT_APP_MAX_ATTACHED_FILES_SIZE)) }) })
        } else {
            let clonedData = {
                ...data,
                enabled: data.enabled === "true",
                type: materials.TRAILER,
                photos: files,
                photoToDelete: filesToDeleteInDB
            };
            if (data.pdf?.[0]) {
                clonedData.pdf = data.pdf[0];
            }
            await persistType(dispatch, clonedData);
        }
    };

    return (
        <Form id="trailer-type-form" onSubmit={handleSubmit(onSubmit)}>
            <input
                id="id"
                name="id"
                type="hidden"
                {...register('id')}
            />

            <input
                id="enabled"
                name="enabled"
                type="hidden"
                {...register('enabled')}
            />

            <Row className="form-group row d-flex align-items-center mb-5">
                <label className="col-lg-4 form-control-label d-flex justify-content-lg-end">{f({ id: 'types.brand.label' })} </label>
                <Col className="col-lg-5">
                    <input
                        type="text"
                        name="brand"
                        id="brand"
                        {...register('brand')}
                        className={errors?.brand ? "form-control form-control-invalid" : "form-control"}
                        placeholder={f({ id: 'boat.brand.placeholder' })}
                    />
                    {errors?.brand && (
                        <div className="invalid-feedback">
                            {errors?.brand.message}
                        </div>
                    )
                    }
                </Col>
            </Row>

            <Row className="form-group row d-flex align-items-center mb-5">
                <label className="col-lg-4 form-control-label d-flex justify-content-lg-end">{f({ id: 'boat.model.label' })} </label>
                <Col className="col-lg-5">
                    <input
                        type="text"
                        name="model"
                        id="model"
                        {...register('model')}
                        className={errors?.model ? "form-control form-control-invalid" : "form-control"}
                        placeholder={f({ id: 'boat.model.placeholder' })}
                    />
                    {errors?.model && (
                        <div className="invalid-feedback">
                            {errors?.model.message}
                        </div>
                    )
                    }
                </Col>
            </Row>

            <Row className="form-group row d-flex align-items-center mb-5">
                <label className="col-lg-4 form-control-label d-flex justify-content-lg-end">{f({ id: 'trailerType.features.label' })} </label>
                <Col className="col-lg-5">
                    <input
                        type="text"
                        name="features"
                        id="features"
                        {...register('features')}
                        className={"form-control"}
                        placeholder={f({ id: 'trailerType.features.placeholder' })}
                    />
                </Col>
            </Row>

            <div className="form-group row mb-5">
                <label className="col-lg-4 form-control-label d-flex justify-content-lg-end">{f({ id: 'boat.enabled.label' })}</label>
                <Col className="col-lg-1">
                    <div className="custom-control custom-radio styled-radio mb-3">
                        <input
                            className="custom-control-input"
                            type="radio"
                            name="enabled"
                            checked={enabledOn}
                            onChange={() => onEnabledOnChange()}
                            id="opt-01" />
                        <label className="custom-control-descfeedback" htmlFor="opt-01">{f({ id: 'app.yes' })}</label>
                    </div>
                    {errors?.enabled && (
                        <div className="invalid-feedback">
                            {errors?.enabled.message}
                        </div>
                    )
                    }
                </Col>
                <Col className="col-lg-1">
                    <div className="custom-control custom-radio styled-radio mb-3">
                        <input
                            className="custom-control-input"
                            type="radio"
                            name="noEnabled"
                            checked={enabledOff}
                            onChange={() => onEnabledOffChange()}
                            id="opt-02" />
                        <label className="custom-control-descfeedback" htmlFor="opt-02">{f({ id: 'app.no' })}</label>
                    </div>
                </Col>
            </div>

            <div className="form-group row d-flex align-items-center mb-5">
                <label className="col-lg-4 form-control-label d-flex justify-content-lg-end">Subir PDF</label>
                <div className="col-lg-5">

                    <div className="input-group">
                        <input type="text" name="pdfName" id="pdfName" value={pdfName} className="form-control" />
                        <input
                            id="pdf"
                            name="pdf"
                            type="file"
                            accept="application/pdf"
                            {...register('pdf')}
                            className="input-type-file"
                            onChange={
                                async (event) => {
                                    const file = event.target.files[0];
                                    setPdfName(file?.name || "");
                                }
                            }
                        />
                        <span className="input-group-btn">
                            <button  type="button" className="btn btn-secondary">
                                <i className="la la-file-pdf-o" />
                            </button>
                        </span>
                        {errors?.pdf &&
                            <div className="invalid-feedback">
                                {errors?.pdf.message}
                            </div>
                        }
                    </div>

                </div>
            </div>

            <div className="form-group row d-flex align-items-start mb-5">
                <label className="col-lg-4 form-control-label d-flex justify-content-lg-end">Subir imagen</label>
                <div className="col-lg-5">
                    <Dropzone
                        files={files}
                        getRootProps={getRootProps}
                        getInputProps={getInputProps}
                        removeFile={removeFile} />
                </div>
            </div>

            <div className="em-separator separator-dashed"/>

            <div className="d-flex flex-row-reverse">
                <button disabled={persisting} className="btn btn-shadow" type="reset">Limpiar</button>
                <button disabled={persisting} form="trailer-type-form"  className="btn btn-primary mr-1" type="submit">{persisting ? f({ id: 'app.saving' }) : f({ id: 'app.save' })}</button>
            </div>
        </Form>
    );
}

export default TrailerTypeForm;