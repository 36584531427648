import { deleteMethod, get, post, put } from "api/client";
import { convertToInputDateFormat } from "utils/dateUtils";

export const getInventory = async (dispatch, id, pageIndex, pageSize) => {
  try {
    dispatch({ type: "GET_INVENTORY" });
    const response = await get(`inventories/${id}`);


    dispatch({ type: "GET_INVENTORY_SUCCESS", payload: response });

  } catch (error) {
    dispatch({ type: "GET_INVENTORY_FAILURE", description: error });
  }
};
export const getInventoryForType = async (dispatch, type, pageIndex, pageSize, criteria) => {
  try {
    dispatch({ type: "GET_INVENTORIES_CRITERIA" }); // stocks: [], loading: true
    let urlQuery = `inventories?type=${type}&page-index=${pageIndex}&page-size=${pageSize}`;
    if (criteria) {
      urlQuery += `&criteria=${criteria}`;
    }
    //endpoint call
    const data = await get(urlQuery);
    dispatch({ type: "GET_INVENTORIES_CRITERIA_SUCCESS", payload: data }); // loading: false y members: action.payload
  } catch (error) {
    dispatch({
      type: "GET_INVENTORIES_CRITERIA_FAILURE",
      error: { code: 500, description: error },
    }); //loading: false, stocks: []
  }
};

export const getAllEnabledAndNotDeletedInventories = async (dispatch, pageIndex, pageSize) => {
  try {
    let urlQuery = `inventories?page-index=${pageIndex}&page-size=${pageSize}`;
    dispatch({ type: "GET_ENABLED_INVENTORIES" });
    const data = await get(urlQuery);
    dispatch({ type: "GET_ENABLED_INVENTORIES_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "GET_ENABLED_INVENTORIES_FAILURE",
      error: { code: 500, description: error },
    });
  } // catch
} // getAllEnabledAndNotDeletedInventories

export const persistInventory = async (dispatch, data) => {
  try {
    dispatch({ type: "PERSIST_INVENTORY" });
    let response = null;
    if (data.id) {
      response = await put(`inventories/${data.id}`, data);
    } else {
      response = await post(`inventories`, data);
    }

    if (response.status === 204 || response.status === 201 || response.status === 200) {
      dispatch({ type: "PERSIST_INVENTORY_SUCCESS" });
    } else {
      dispatch({ type: "PERSIST_INVENTORY_FAILURE", payload: response });
    }
    //TODO: Tratamiento de errores centralizado
  } catch (error) {
    dispatch({ type: "PERSIST_INVENTORY_FAILURE", payload: error });
  }
};

export const deleteInventory = async (dispatch, id) => {
  try {
    dispatch({ type: "DELETE_INVENTORY" });
    const response = await deleteMethod(`inventories/${id}`);

    if (response.status === 204 || response.status === 201) {
      dispatch({ type: "DELETE_INVENTORY_SUCCESS" });
    } else {
      dispatch({ type: "DELETE_INVENTORY_FAILURE", payload: response });
    }
    //TODO: Tratamiento de errores centralizado
  } catch (error) {
    dispatch({ type: "DELETE_INVENTORY_FAILURE", payload: error });
  }
};

export const getAvailableInventories = async (dispatch, request) => {

  // Si han solicitado materiales obtenemos las matriculas disponibles segun las fechas de la solicitud
  let url = '';
  let dateFrom;
  let dateUntil;

  if (request?.materialCollectionDate && request?.materialReturnDate) {
    dateFrom = convertToInputDateFormat(request.materialCollectionDate);
    dateUntil = convertToInputDateFormat(request.materialReturnDate);
  }

  if (dateFrom && dateUntil) {
    url += `available?date-from=${dateFrom}`;
    url += `&date-until=${dateUntil}`;
  }

  if (url && request?.id) {
    url += `&request-id=${request.id}`;
    try {
      dispatch({ type: "GET_AVAILABLE_INVENTORIES" });
      const data = await get(`inventories/` + url);
      dispatch({ type: "GET_AVAILABLE_INVENTORIES_SUCCESS", payload: data });
    } catch (error) {
      dispatch({
        type: "GET_AVAILABLE_INVENTORIES_FAILURE",
        error: { code: 500, description: error },
      }); // dispatch
    } // try-catch
  } // if
};

export const getCurrentRenting = async (dispatch, id) => {
  try {
    dispatch({ type: "GET_CURRENT_RENTING_INVENTORY" });
    const data = await get(`inventories/${id}/current-renting`);
    dispatch({ type: "GET_CURRENT_RENTING_INVENTORY_SUCCESS", payload: data });
  } catch (error) {
    console.error(error);
    dispatch({
      type: "GET_CURRENT_RENTING_INVENTORY_FAILURE",
      error: { code: 500, description: error }
    }); // dispatch
  } // try-catch
} // currentRenting

export const getRentingHistory = async (dispatch, pageSize, pageIndex, inventoryId) => {
  try {
    dispatch({ type: "GET_RENTING_HISTORY_FOR_INVENTORY" });
    const data = await get(`inventories/renting/by-criteria?pageIndex=${pageIndex}&pageSize=${pageSize}&inventory-id=${inventoryId}`);
    dispatch({ type: "GET_RENTING_HISTORY_FOR_INVENTORY_SUCCESS", payload: data });
  } catch (error) {
    console.error(error);
    dispatch({
      type: "GET_RENTING_HISTORY_FOR_INVENTORY_FAILURE",
      error: { code: 500, description: error }
    }); // dispatch
  } // try-catch
} // getRentingHistory