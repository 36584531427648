export const initialVanTypesState = {
  selectedTab: 0,
  vanTypes: [],
  member: null,
  loading: false,
  deleting: false,
  deleted: false,
  errorDeleting: false,
  vanType: { materialType: 'VAN' },
  saved: false,
  errorPersisting: false,
  vanInventories: [],
  vanTypesEnabled: [],
  vanInventory: {},
  persisting: false,
  vanTypesTotal: 0,
  vanTypesTotalPages: 0,
  vanTypesPageIndex: 1,
  vanInventoryPageIndex: 1,
  vanInventoryTotal: 0,
  vanInventoryTotalPages: 0,
  vanHistoryPageIndex: 1,
  vanHistoryTotal: 0,
  vanHistoryTotalPages: 0,
  vanInventoryHistory: [],
  vanMaintenanceHistory: [],
};

export const vanTypesReducer = (initialState = initialVanTypesState, action) => {
  switch (action.type) {

    case "SET_SELECTED_TAB":
      return { ...initialState, selectedTab: action.payload };

    case "GET_VAN_TYPES_CRITERIA":
      return { ...initialState, vanTypes: [], loading: true, vanType: { materialType: 'VAN' } };

    case "GET_VAN_TYPES_CRITERIA_SUCCESS":
      return {
        ...initialState,
        loading: false,
        vanTypes: action.payload.content,
        vanTypesTotal: action.payload.totalElements,
        vanTypesTotalPages: action.payload.totalPages,
      };

    case "GET_VAN_TYPES_CRITERIA_FAILURE":
      return { ...initialState, vanTypes: [], loading: false };

    case "PERSIST_VAN_TYPE":
      return { ...initialState, persisting: true, saved: false, errorPersisting: false };

    case "PERSIST_VAN_TYPE_SUCCESS":
      return { ...initialState, persisting: false, saved: true, errorPersisting: false };

    case "PERSIST_VAN_TYPE_FAILURE":
      return { ...initialState, persisting: false, saved: false, errorPersisting: true };

    case "RESET_SAVED":
      return { ...initialState, saved: false };

    case "RESET_ERROR":
      return { ...initialState, saved: false, deleted: false, errorPersisting: false, errorDeleting: false };

    case "GET_VAN_TYPE":
      return { ...initialState, loading: true };

    case "GET_VAN_TYPE_SUCCESS":
      return {
        ...initialState,
        vanType: action.payload,
        loading: false,
      };

    case "GET_VAN_TYPE_FAILURE":
      return { ...initialState, vanType: {}, loading: false };

    case "GET_VAN_INVENTORIES_CRITERIA":
      return { ...initialState, vanInventories: [], loading: true, vanInventory: {} };

    case "GET_VAN_INVENTORIES_CRITERIA_SUCCESS":
      return {
        ...initialState,
        loading: false,
        vanInventories: action.payload.content,
        vanInventoryTotal: action.payload.totalElements,
        vanInventoryTotalPages: action.payload.totalPages,
      };

    case "GET_VAN_INVENTORIES_CRITERIA_FAILURE":
      return { ...initialState, vanInventories: [], loading: false };

    case "PERSIST_VAN_INVENTORY":
      return { ...initialState, persisting: true, saved: false, errorPersisting: false };

    case "PERSIST_VAN_INVENTORY_SUCCESS":
      return { ...initialState, persisting: false, saved: true, errorPersisting: false };

    case "PERSIST_VAN_INVENTORY_FAILURE":
      return { ...initialState, persisting: false, saved: false, errorPersisting: true };

    case "GET_VAN_TYPES_ENABLED":
      return { ...initialState, vanTypesEnabled: [], loading: true };

    case "GET_VAN_TYPES_ENABLED_SUCCESS":
      return { ...initialState, vanTypesEnabled: action.payload, loading: false };

    case "GET_VAN_TYPES_ENABLED_FAILURE":
      return { ...initialState, vanTypesEnabled: [], loading: false };

    case "GET_VAN_INVENTORY":
      return { ...initialState, loading: true };

    case "GET_VAN_INVENTORY_SUCCESS":
      return {
        ...initialState,
        vanInventory: { ...action.payload, materialId: action.payload.material.id.toString() },
        loading: false,
      };

    case "GET_VAN_INVENTORY_FAILURE":
      return { ...initialState, vanInventory: {}, loading: false };

    case "CHANGE_VAN_TYPES_PAGE_INDEX":
      return { ...initialState, vanTypesPageIndex: action.payload };

    case "CHANGE_VAN_INVENTORY_PAGE_INDEX":
      return { ...initialState, vanInventoryPageIndex: action.payload };

    case "CHANGE_VAN_HISTORY_PAGE_INDEX":
      return { ...initialState, vanHistoryPageIndex: action.payload };

    case "SET_VAN_INVENTORY_HISTORY":
      return {
        ...initialState,
        vanInventoryHistory: action.payload.content,
        vanHistoryTotal: action.payload.totalElements,
        vanHistoryTotalPages: action.payload.totalPages,
      };

    case "DELETE_VAN_TYPE":
    case "DELETE_VAN_INVENTORY":
      return { ...initialState, deleting: true };

    case "DELETE_VAN_TYPE_SUCCESS":
    case "DELETE_VAN_INVENTORY_SUCCESS":
      return { ...initialState, deleting: false, deleted: true };

    case "DELETE_VAN_TYPE_FAILURE":
    case "DELETE_VAN_INVENTORY_FAILURE":
      return { ...initialState, deleting: false };

    case "RESET_DELETED":
      return { ...initialState, deleted: false };


    case "GET_VAN_INVENTORY_MAINTENANCE_HISTORY":
      return { ...initialState, loading: true };

    case "GET_VAN_INVENTORY_MAINTENANCE_HISTORY_SUCCESS":
      return {
        ...initialState,
        vanMaintenanceHistory: action.payload,
        loading: false,
      };

    case "GET_VAN_INVENTORY_MAINTENANCE_HISTORY_FAILURE":
      return { ...initialState, vanMaintenanceHistory: [], loading: false };

    default:
      return { ...initialState };
  }
};
