import cx from 'classnames';
import { yupResolver } from "@hookform/resolvers/yup";
import { FromDateCustomInput } from "components/RequestsBody/FromDateCustomInput";
import { bytesToMegabytes, calculateFilesSize, filesSizeExceedMaxAllowed, formatDecimal } from "helpers/helpers";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import DatePicker from "react-multi-date-picker";
import { NotificationManager } from "react-notifications";
import { Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from "state";
import { persistMaintenance } from "state/maintenances/actions";
import { formatDateSimpleString } from "utils/dateUtils";
import { materials, months, weekDays } from "utils/enums";
import * as Yup from "yup";
import styles from 'pages/Views.module.scss';

export const MaintenanceForm = ({ maintenance, inventory }) => {

    const { formatMessage: f } = useIntl();
    const SAVING_LABEL = f({ id: 'app.saving' });
    const SAVE_LABEL = f({ id: 'app.save' });

    const { persisting, saved, errorPersisting } = useSelector('maintenances');

    const [pdfName, setPdfName] = useState(maintenance ? maintenance.pdfFilePath : '');
    const [date, setDate] = useState(maintenance?.date ? new Date(maintenance.date) : null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const materialType = maintenance?.inventory.material.type || inventory.material.type;

    let maintenanceSchema = Yup.object().shape({
        date: Yup.date()
            .required("Debes especificar una fecha."),

        pdfName: Yup.string(),

        pdf: Yup.mixed()
            .test("filePresence", "Debes seleccionar un archivo PDF", function (value) {
                // Checks if any file is selected if pdfName is not set
                return pdfName !== "";
            })
            .test("fileFormat", "El documento debe ser un PDF", function (value) {
                // Checks if the file type is PDF if pdfName is set
                return pdfName !== "" || (!value || (value[0]?.type === "application/pdf"));
            })
    }).required();

    // Schema for BOAT material type
    const boatSchema = Yup.object().shape({
        hours: Yup.number()
            .typeError('Debes especificar un número de horas.')
            .min(1, "El número introducido debe ser mayor que 0")
    });

    // Schema for other material types
    const otherSchema = Yup.object().shape({
        kilometers: Yup.number()
            .typeError('Debes especificar un número de kilómetros.')
            .min(1, "El número introducido debe ser mayor que 0")
    });

    // Conditionally merge schemas based on materialType
    if (materialType === materials.BOAT) {
        maintenanceSchema = maintenanceSchema.concat(boatSchema);
    } else {
        maintenanceSchema = maintenanceSchema.concat(otherSchema);
    }

    const { register, control, handleSubmit, formState: { errors }, setError, clearErrors } = useForm({
        resolver: yupResolver(maintenanceSchema),
        defaultValues: {
            ...maintenance,
            pdfName: maintenance?.pdfFilePath || ""  //FIXME:
        },
    });

    useEffect(() => {
        if (saved) {
            const inventoryId = maintenance?.inventory?.id || inventory.id;
            if (!maintenance?.id) {
                NotificationManager.success("Creado correctamente");
                dispatch({ type: "SET_SELECTED_TAB", payload: 1 });
                navigate(`/${materialType}/inventories/${inventoryId}`);
            } else {
                NotificationManager.success("Modificado correctamente");
            }
            dispatch({ type: "RESET_PERSISTED" });
        } else if (errorPersisting) {
            NotificationManager.error("Se ha producido un error");
            dispatch({ type: "RESET_ERROR" });
        }
    }, [saved, errorPersisting]);

    const onSubmit = async (data) => {
        clearErrors('pdf');
        const docsSize = calculateFilesSize([], data.pdf);
        if (filesSizeExceedMaxAllowed(docsSize)) {
            setError('pdf', {
                type: 'custom',
                message: f(
                    { id: "form.errors.maxSize" },
                    {
                        size: formatDecimal(bytesToMegabytes(docsSize)),
                        maxSize: formatDecimal(bytesToMegabytes(process.env.REACT_APP_MAX_ATTACHED_FILES_SIZE))
                    })
            })
        } else {
            let maintenance = {
                id: data.id,
                date: formatDateSimpleString(date),
                description: data.comments,
                kilometers: data.kilometers || -1,
                hours: data.hours || -1,
                inventoryId: data.inventory?.id || inventory.id
            };

            if (data.pdf?.[0]) {
                maintenance.pdfFile = data.pdf[0];
            } // if
            await persistMaintenance(dispatch, maintenance);
        } // else
    }; // onSubmit


    return (
        <div className={cx(styles["widget"])}>
            <div className={cx(styles["widget-body"])}>

                <form id="maintenance-type-form"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <input
                        id="id"
                        name="id"
                        type="hidden"
                        {...register('id')}
                    />

                    <Row className="form-group row d-flex align-items-center mb-5">
                        <label className="col-lg-4 form-control-label d-flex justify-content-lg-end">{f({ id: 'maintenances.date.label' })} </label>
                        <Col className="col-lg-5">

                            <Controller
                                control={control}
                                name='date'
                                render={({
                                    field: { onChange, name, value },
                                    fieldState: { invalid, isDirty }, // TODO: is this actually necessary?
                                    formState: { errors },
                                }) => (
                                    <>
                                        <DatePicker
                                            value={new Date(value)}
                                            onChange={(newValue) => {
                                                onChange(new Date(newValue)); // Update the form value
                                                setDate(new Date(newValue)); // Update the state with the new value
                                            }}
                                            render={<FromDateCustomInput showIcon={false} />}
                                            containerClassName="d-block"

                                            weekDays={weekDays}
                                            months={months}
                                            format={"DD/MM/YYYY"}
                                        />

                                        {
                                            errors.date &&
                                            <div className="invalid-feedback">
                                                {errors?.date.message}
                                            </div>
                                        }
                                    </>
                                )}
                            />
                        </Col>
                    </Row>

                    {
                        materialType === materials.BOAT ?
                            <Row className="form-group row d-flex align-items-center mb-5">
                                <label className="col-lg-4 form-control-label d-flex justify-content-lg-end">{f({ id: 'maintenances.hours.label' })} </label>
                                <Col className="col-lg-5">
                                    <input
                                        type="number"
                                        name="hours"
                                        id="hours"
                                        {...register('hours')}
                                        className={errors?.hours ? "form-control form-control-invalid" : "form-control"}
                                        // defaultValue={maintenance.hours || ""}
                                        placeholder={f({ id: 'maintenances.hours.placeholder' })}
                                    />
                                    {
                                        errors?.hours && (
                                            <div className="invalid-feedback">
                                                {errors?.hours.message}
                                            </div>
                                        )
                                    }
                                </Col>
                            </Row>

                            :

                            <Row className="form-group row d-flex align-items-center mb-5">
                                <label className="col-lg-4 form-control-label d-flex justify-content-lg-end">{f({ id: 'maintenances.kilometers.label' })} </label>
                                <Col className="col-lg-5">
                                    <input
                                        type="number"
                                        name="kilometers"
                                        id="kilometers"
                                        {...register('kilometers')}
                                        className={errors?.kilometers ? "form-control form-control-invalid" : "form-control"}
                                        defaultValue={maintenance?.kilometers || null}
                                        placeholder={f({ id: 'maintenances.kilometers.placeholder' })}
                                    />
                                    {errors?.kilometers && (
                                        <div className="invalid-feedback">
                                            {errors?.kilometers.message}
                                        </div>
                                    )
                                    }
                                </Col>
                            </Row>

                    }

                    <div className="form-group row d-flex align-items-center mb-5">
                        <label className="col-lg-4 form-control-label d-flex justify-content-lg-end">{f({ id: 'form.comments.label' })}</label>
                        <div className={"col-lg-5"}>
                            <textarea
                                name="comments"
                                id="comments"
                                {...register('comments')}
                                rows="4"
                                defaultValue={maintenance?.description || ""}
                                className="form-control"
                                placeholder={f({ id: 'maintenances.comments.placeholder' })}>
                            </textarea>
                        </div>
                    </div>

                    <div className="form-group row d-flex align-items-center mb-5">
                        <label className="col-lg-4 form-control-label d-flex justify-content-lg-end">PDF*</label>

                        <div className="col-lg-5">

                            <div className="input-group">
                                <input type="text" name="pdfName" id="pdfName" value={pdfName} className="form-control" />
                                <input
                                    id="pdf"
                                    name="pdf"
                                    type="file"
                                    accept="application/pdf"
                                    {...register('pdf')}
                                    className="input-type-file"
                                    onChange={
                                        async (event) => {
                                            const file = event.target.files[0];
                                            setPdfName(file?.name)
                                        }
                                    }
                                />
                                <span className="input-group-btn">
                                    <button type="button" className="btn btn-secondary">
                                        <i className="la la-file-pdf-o" />
                                    </button>
                                </span>
                                {errors?.pdf &&
                                    <div className="invalid-feedback">
                                        {errors?.pdf.message}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="em-separator separator-dashed" />

                    <div className="d-flex flex-row-reverse">
                        <button
                            disabled={persisting}
                            className="btn btn-shadow"
                            type="reset"
                        >
                            Limpiar
                        </button>
                        <button
                            disabled={persisting}
                            form="maintenance-type-form"
                            className="btn btn-primary mr-1"
                            type="submit"
                        >
                            {persisting ? SAVING_LABEL : SAVE_LABEL}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    ) // return
}