import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import { styled } from '@mui/material/styles';
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Dropdown from "./Dropdown";

// Creando un componente estilizado para <ul>
const Item = styled('li')(({ theme }) => ({
  position: 'relative',
  fontFamily: theme.typography.fontFamily, // Aplica la fuente del tema al elemento li
//  marginRight: '0.3rem',
  '& a': {
    display: 'block',
    color: 'rgba(0, 0, 0, 0.87)',
    textDecoration: 'none',
    textAlign: 'left',
    fontSize: '0.875rem',
    letterSpacing: '0.2px',
    padding: '0.7rem 1rem',
    fontFamily: theme.typography.fontFamily, // Aplica la fuente del tema al elemento a
  },
  '& a:hover': {
    borderRadius: '0.375rem',
    backgroundColor: '#f2f2f2',
  },
  '& button': {
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.2px',
    fontSize: '0.875rem',
    color: 'rgba(0, 0, 0, 0.87)',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    width: '100%',
    textAlign: 'left',
    padding: '0.7rem 1rem',
    fontFamily: theme.typography.fontFamily, // Aplica la fuente del tema al elemento button
  },
  '& button:hover': {
    borderRadius: '0.375rem',
    backgroundColor: '#f2f2f2'
  },
  '& button.active': {
    borderRadius: '0.375rem',
    backgroundColor:  '#f2f2f2'
  },
  '& button span': {
    marginLeft: '3px',
  },
  '& button a': {
    padding: '0',
    width: '100%',
    color: 'rgba(0, 0, 0, 0.87)',
    fontFamily: theme.typography.fontFamily, // Opcional, aplica la fuente del tema al elemento a dentro de button si es necesario
  },
  '& .arrow::after': {
    content: '" "',
    display: 'inline-block',
    marginLeft: '0.28em',
    verticalAlign: '0.09em',
    borderTop: '0.42em solid',
    borderRight: '0.32em solid transparent',
    borderLeft: '0.32em solid transparent',
  },
  // Añade aquí más estilos si es necesario
}));

const MenuItem = ({ items, depthLevel }) => {
  const [dropdown, setDropdown] = useState(false);
  let ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);

  const onMouseEnter = () => {
    setDropdown(true);
  };

  const onMouseLeave = () => {
    setDropdown(false);
  };

  const toggleDropdown = () => {
    setDropdown((prev) => !prev);
  };

  const closeDropdown = () => {
    dropdown && setDropdown(false);
  };

  return (
    <Item
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={closeDropdown}>
      {items.url && items.submenu ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}
            className={dropdown ? "active": ""}
            onClick={() => toggleDropdown()}>
            <Link to={items.url}>{items.title}</Link>
            {depthLevel > 0 ? <KeyboardArrowRightOutlinedIcon fontSize={'small'}/> : <KeyboardArrowDownOutlinedIcon fontSize="small"/>}
          </button>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={dropdown}
          />
        </>
      ) : !items.url && items.submenu ? (
        <>
          <button
            type="button"
            className={dropdown ? "active": ""}
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}>
            {items.title}
            {depthLevel > 0 ? <span>&raquo;</span> : <KeyboardArrowDownOutlinedIcon fontSize="small"/>}
          </button>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={dropdown}
          />
        </>
      ) : (
        <Link to={items.url}>{items.title}</Link>
      )}
    </Item>
  );
};

export default MenuItem;
