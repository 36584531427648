import { MaintenanceForm } from "components/Form/Maintenance/MaintenanceForm";
import Loading from "components/Loading/Loading";
import { PageHeader } from "components/PageHeader";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "state";
import { getInventory } from "state/inventories";

export const AddMaintenancePage = () => {
    const { inventoryId } = useParams();
    const dispatch = useDispatch();

    const { inventory, loading } = useSelector('inventories');
    useEffect(() => {
        const getInventoryFunction = async () => {
            await getInventory(dispatch, inventoryId);
        }
        getInventoryFunction();
    }, []);

    

    return (

        loading || !inventory ?

            <Loading />
            :
            <>
                <PageHeader title={`Añadir mantenimiento a ${inventory.numberPlate}`} />
                <MaintenanceForm
                    inventory={inventory}
                />
            </>
    );
}