import { deleteMethod, get, put } from "api/client";
import { NotificationManager } from "react-notifications";
import { getAvailableInventories } from "state/inventories/actions";
import { materials } from "utils/enums";


export const getAllRequests = async (dispatch, pageIndex, pageSize, criteria, dateFromFilter, dateUntilFilter, dateType, status, filterByCity) => {
  try {
    dispatch({ type: "GET_REQUESTS" }); // stocks: [], loading: true
    let url = `requests?pageIndex=${pageIndex}&pageSize=${pageSize}&criteria=${criteria}&dateType=${dateType}&status=${status}`;
    if (dateFromFilter) {
      url += `&dateFromFilter=${dateFromFilter}`
    }
    if (dateUntilFilter) {
      url += `&dateUntilFilter=${dateUntilFilter}`
    }
    if (filterByCity) {
      url += `&filter-by-city=${filterByCity === true}`
    }
    const data = await get(url);
    //endpoint call
    dispatch({ type: "GET_REQUESTS_SUCCESS", payload: data }); // loading: false y members: action.payload
  } catch (error) {

    dispatch({
      type: "GET_REQUESTS_FAILURE",
      error: { code: 500, description: error },
    }); //loading: false, stocks: []
  }
};
export const getRequestById = async (dispatch, id) => {
  try {
    dispatch({ type: "GET_REQUEST" });
    const response = await get(`requests/${id}`);
    
    dispatch({ type: "GET_REQUEST_SUCCESS", payload: response });
    getAvailableInventories(dispatch, response.request);
  } catch (error) {
    dispatch({ type: "GET_REQUEST_FAILURE" });
  }
};

export const changeRequestStatus = async (dispatch, data) => {
  try {
    dispatch({ type: "CHANGE_REQUEST_STATUS" });
    let response = null
    response = await put(`requests/change-status/${data.id}?status=${data.status}`);

    if (response.status === 204) {
      dispatch({ type: "CHANGE_REQUEST_STATUS_SUCCESS" });
      NotificationManager.success("Estado modificado");
    } else {
      dispatch({ type: "CHANGE_REQUEST_STATUS_FAILURE", payload: response });
      NotificationManager.error("Error al modificar");
    }
    return true;
    //TODO: Tratamiento de errores centralizado
  } catch (error) {
    dispatch({ type: "CHANGE_REQUEST_STATUS_FAILURE", payload: error });
    NotificationManager.error("Error al modificar");
    return true;
  }
};

export const rejectRequestApi = async (dispatch, id) => {
  try {
    dispatch({ type: "REJECT_REQUEST_STATUS" });
    let response = null
    response = await put(`requests/${id}/reject`);

    if (response.status === 204) {
      dispatch({ type: "REJECT_REQUEST_STATUS_SUCCESS" });
      NotificationManager.success("Estado modificado");
    } else {
      dispatch({ type: "REJECT_REQUEST_STATUS_FAILURE", payload: response });
      NotificationManager.error("Error al modificar");
    }
    return true;
    //TODO: Tratamiento de errores centralizado
  } catch (error) {
    dispatch({ type: "REJECT_REQUEST_STATUS_FAILURE", payload: error });
    NotificationManager.error("Error al modificar");
    return true;
  }
};

export const persistModifiedRequest = async (dispatch, data, sendEmail) => {
  try {
    dispatch({ type: "PERSIST_MODIFIED_REQUEST" });
    let response;
    let url = `requests/${data.id}`;
    
    if (sendEmail) {
      url += '/accept'
    }
    
    response = await put(`${url}`, data);
    // 200 OK, 201 Created or 204 No content
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      dispatch({ type: "PERSIST_MODIFIED_REQUEST_SUCCESS" });
    } else {
      dispatch({ type: "PERSIST_MODIFIED_REQUEST_FAILURE", payload: response });
    }
    //TODO: Tratamiento de errores centralizado
  } catch (error) {

    dispatch({ type: "PERSIST_MODIFIED_REQUEST_FAILURE", payload: error });
  }
};

export const getAllMaterialTypes = async (dispatch, enabled) => {
  let urlQuery = `types/by-type?`;

  if (enabled !== undefined) {
    urlQuery += `enabled=${enabled}&`
  }

  urlQuery += "material-type-code="

  try {
    dispatch({ type: "GET_BOAT_TYPES" }); // stocks: [], loading: true

    const boatUrlQuery = urlQuery + materials.BOAT;
    const data = await get(boatUrlQuery);

    //endpoint call
    dispatch({ type: "GET_BOAT_TYPES_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "GET_BOAT_TYPES_FAILURE",
      error: { code: 500, description: error },
    }); //loading: false, stocks: []
  }

  try {
    dispatch({ type: "GET_TRAILER_TYPES" }); // stocks: [], loading: true
    const trailerUrlQuery = urlQuery + materials.TRAILER;
    const data = await get(trailerUrlQuery);

    //endpoint call
    dispatch({ type: "GET_TRAILER_TYPES_SUCCESS", payload: data });
  } catch (error) {

    dispatch({
      type: "GET_TRAILER_TYPES_FAILURE",
      error: { code: 500, description: error },
    }); //loading: false, stocks: []
  }

  try {
    dispatch({ type: "GET_VAN_TYPES" }); // stocks: [], loading: true
    const vanUrlQuery = urlQuery + materials.VAN;
    const data = await get(vanUrlQuery);
    
    //endpoint call
    dispatch({ type: "GET_VAN_TYPES_SUCCESS", payload: data });
  } catch (error) {

    dispatch({
      type: "GET_VAN_TYPES_FAILURE",
      error: { code: 500, description: error },
    }); //loading: false, stocks: []
  }
  return true;
};

export const deleteRequest = async (dispatch, id) => {
  try {
    dispatch({ type: "DELETE_REQUEST" });
    const response = await deleteMethod(`requests/${id}`);

    if (response.status === 204 || response.status === 201) {
      dispatch({ type: "DELETE_REQUEST_SUCCESS" });
    } else {
      dispatch({ type: "DELETE_REQUEST_FAILURE", payload: response });
    }
    //TODO: Tratamiento de errores centralizado
  } catch (error) {

    dispatch({ type: "DELETE_REQUEST_FAILURE", payload: error });
  }
};

export const getRequestGuests = async (dispatch, id) => {
  try {
    dispatch({ type: 'GET_REQUEST_GUESTS' });
    const response = await get(`requests/${id}/guests`);
    dispatch({ type: 'GET_REQUEST_GUESTS_SUCCESS', payload: response });
  } catch (error) {
    dispatch({ type: "GET_REQUEST_GUESTS_FAILURE" })
  } // try-catch
} // getRequestGuests

export const persistRequestGuests = async (dispatch, id, guests) => {
  try {
    dispatch({ type: 'PERSIST_REQUEST_GUESTS' });
    const response = await put(`requests/${id}/guests`, guests);
    dispatch({ type: 'PERSIST_REQUEST_GUESTS_SUCCESS', response });

  } catch (error) {

    dispatch({ type: "PERSIST_REQUEST_GUESTS_FAILURE" });
  }
}

export const persistRequestInformation = async (dispatch, id, data, accepted) => {
  let url = `requests/{id}`;
  if (accepted) {
    url += '/accept'
  }
  try {
    const response = await put(url, data);
    dispatch({type: 'PERSIST_REQUEST_INFORMATION_SUCCESS', response})
  } catch (error) {

  }
}

export const getInventoryRentingsByRequestId = async (dispatch, id) => {
  try {
    dispatch({ type: 'GET_RENTING_INVENTORIES_BY_REQUEST' });
    const response = await get(`inventories/renting?request-id=${id}`);
    dispatch({ type: 'GET_RENTING_INVENTORIES_BY_REQUEST_SUCCESS', payload: response });

  } catch (error) {

    dispatch({ type: "GET_RENTING_INVENTORIES_BY_REQUEST_FAILURE" });
  }
}
