import Cookies from 'js-cookie';
import { buildHeader, buildHeaderRefresh } from 'helpers/helpers';
const API_URL = process.env.REACT_APP_API_URL;

// Post
export const post = async (url, payload) => {

  const requestOptions = {
    method: "POST",
    headers: buildHeader(),    body: JSON.stringify(payload),
  };

  const response = await doFetch(url, requestOptions);    
  if (response.status === 204) {
    return { status: response.status };
  }

  if (response.status === 201) {
    return { status: response.status, data: await response.json() };
  }
  return { status: response.status, data: await response.json() };
 };

// Get
export const get = async (url) => {
  let requestOptions = {
    method: "GET",
    mode: "cors",
    headers:  buildHeader(),
  };

  const response = await doFetch(url, requestOptions);
  try {
    return await response.json();
  } catch (error) {
    return error;
  }

};

// Put
export const put = async (url, payload) => {
  const requestOptions = {
    method: "PUT",
    headers:  buildHeader(),
    body: JSON.stringify(payload),
  };

  const response = await doFetch(url, requestOptions);
  if (response.status === 204) {
    return { status: response.status };
  }
  return { status: response.status, data: await response.json() };
};

// Delete
export const deleteMethod = async (url) => {
  const requestOptions = {
    method: "DELETE",
    headers:  buildHeader()
  };
   const response = await doFetch(url, requestOptions);   if (response.status === 204) {
    return { status: response.status };
  }
  return { status: response.status, data: await response.json() };
};

export const formDataRequest = async (url, payload, method) => {
  const contentType = "multipart/form-data";
  let formData = new FormData();
   for (let i = 0; i < payload.photos?.length; i++) {
    //si no tiene id es porque es tipo FILE
    if (!payload.photos[i].id) {
      formData.append('multipleFiles', payload.photos[i]);
    }
  }

  for (let j = 0; j < payload.photoToDelete?.length; j++) {
    if (payload.photoToDelete[j]) {
      formData.append('photosToDelete', payload.photoToDelete[j].id);
    }
  }

  for (let key in payload) {
    formData.append(key, payload[key]);
  }

  const requestOptions = {
    method: method,
    body: formData, //pass the form data
    headers:  buildHeader(contentType)
  };

  const response = await doFetch(url, requestOptions, contentType);
  if (response.status === 201 || response.status === 204 || response.status === 200) {
    return { response, status: response.status };
  }

  return response.json();
};

const doFetch = async(url, requestOptions, contentType) => {
  let response = await fetch(`${API_URL}/${url}`, requestOptions);

  if (response.status === 401 || response.status === 403) {
    //refresh token and retry fetch
    sessionStorage.removeItem('user_id');
    await refreshToken();
    requestOptions = { ...requestOptions, headers: buildHeader(contentType) };
    response = await fetch(`${API_URL}/${url}`, requestOptions);
  } // if

  return response;
} // doFetch

const refreshToken = async () => {
  let result = false;
  const refreshTokenResponse = await fetch(`${API_URL}/auth/refresh-token`, {
    method: "POST",
    mode: "cors",
    headers:  buildHeaderRefresh(),
    body: JSON.stringify({ "refreshToken" : Cookies.get("cbGbcRf")}),
  }); // refreshTokenResponse

  if (refreshTokenResponse.status === 200) {
    result = true;
    const responseData = await refreshTokenResponse.json();
    sessionStorage.setItem('user_id', responseData.token);
  } // if

  return result;
} // refreshToken