import moment from 'moment';
import { useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useIntl } from "react-intl";

import { ConfirmationModal } from 'components/Shared/ConfirmationModal';
import { formatDateSimpleString } from 'utils/dateUtils';
import CalendarFilters from './CalendarFilters/CalendarFilters';
import './dashboard.css';
import { materials } from 'utils/enums';


export const Dashboard = ({
    onViewChange,
    events,
    getGuestsInRoom,
    guestsInRoom,
    setSelectedRoomEvent,
    selectedRoomEvent,
    rooms,
    setView,
}) => {

    const localizer = momentLocalizer(moment);
    const { formatMessage: f } = useIntl();

    const [showMore, setShowMore] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedRoom, setSelectedRoom] = useState(undefined);

    const ACCEPT_LABEL = f({ id: 'app.accept' });

    /**
     * Sets the new view and hides the exceding events, if any.
     * @param {string} view - The name of the new view (month, week, day).
     */
    const handleViewChange = (view) => {
        setView(view);
        setShowMore(false);
    } // handleViewChange

    /**
     * Handles a click on an event in the calendar.
     * @param {Object} event - The event that was clicked on.
     */
    const handleEventClick = (event) => {
        if (event.type === 'ROOM') {
            const roomEntity = rooms.find(room => room.id === event.id);

            setSelectedRoomEvent(event);
            setSelectedRoom(roomEntity);
            setShowModal(true);
            getGuestsInRoom(event);
        } // if
    } // handleEventClick

    /**
     * Returns the information of all the guests in a room on a certain day.
     * @returns the information of all the guests in the specified room on the specified day.
     */
    const showAllGuestsInfoInRoom = () => {
        let guestInfo = "No hay huéspedes en esta habitación";
        if (guestsInRoom.length > 0) {
            guestInfo = (
                guestsInRoom.map((guest) => (
                    <p key={guest.id}>
                        {formatSingleGuestInformation(guest)}
                    </p>
                )) // map
            ); // guestInfo
        } // if
        return guestInfo;
    }; // showGuestInfoInRoom

    /**
     * Returns the guest's formatted information.
     * @param {Object} guest - The guest whose info is to be formatted.
     * @returns the guest's formatted information.
     */
    const formatSingleGuestInformation = (guest) => {
        const name = `${guest.firstName} ${guest.lastName}`
        const checkinDate = formatDateSimpleString(guest.checkinDate);
        const checkoutDate = formatDateSimpleString(guest.checkoutDate);
        const requestLocator = guest.requestLocator;

        return `${name} (${checkinDate}-${checkoutDate}), ID reserva: ${requestLocator}`;
    } // formatSingleGuestInformation

    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    {/* Shows the guest info for a specific room-event */}
                    <ConfirmationModal
                        showModal={showModal}
                        onToggleClick={() => setShowModal(false)}
                        header={`${selectedRoom?.name} - ${formatDateSimpleString(selectedRoomEvent?.start)}`}
                        message={showAllGuestsInfoInRoom()}
                        okButtonLabel={ACCEPT_LABEL}
                        okAction={() => setShowModal(false)}
                    />
                    <CalendarFilters />
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12">
                    <div className="widget has-shadow">
                        <div className="widget-body">
                            <Calendar
                                culture={"es"}
                                selectable={false}
                                localizer={localizer}
                                events={events}
                                onView={handleViewChange}
                                style={{ height: 800 }}
                                showAllEvents={showMore}
                                onNavigate={onViewChange}
                                views={['month', 'week', 'day']}
                                onSelectEvent={handleEventClick}
                                onShowMore={() => setShowMore(true)}
                                messages={{
                                    date: 'Fecha',
                                    time: 'Hora',
                                    event: 'Reserva',
                                    allDay: 'Todo el día',
                                    week: 'Semana',
                                    day: 'Día',
                                    month: 'Mes',
                                    previous: '<',
                                    next: '>',
                                    yesterday: 'Ayer',
                                    tomorrow: 'Mañana',
                                    today: 'Hoy',
                                    agenda: 'Agenda',
                                    noEventsInRange: 'No hay reservas en estas fechas',
                                    showMore: function showMore(total) {
                                        return total + " eventos más";
                                    } // showMore
                                }} // messages
                                eventPropGetter={
                                    (event) => {
                                        let color;
                                        const eventType = event.type;

                                        if (eventType === materials.VAN) {
                                            color = "#8ecae6";
                                        } else if (eventType === materials.BOAT) {
                                            color = "#3e4b7f";
                                        } else if (eventType === materials.TRAILER) {
                                            color = "#08a6c3";
                                        } else { // rooms
                                            color = "#52ad46"
                                            if (event.occupation === event.maxOccupation) {
                                                color = "#ce4646"
                                            } // if
                                        } // else

                                        let newStyle = {
                                            backgroundColor: color,
                                            color: 'white',
                                            borderRadius: "0px",
                                            cursor: 'pointer',
                                        }; // newStyle

                                        return {
                                            className: "rbc-btn-group",
                                            style: newStyle
                                        }; // return
                                    } // event
                                } // eventPropGetter
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    ); // return
} // Dashboard