import Loading from "components/Loading/Loading";
import { PageHeader } from 'components/PageHeader';
import { EditMaterialForm } from 'components/Shared/Form/EditMaterialForm';
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "state";
import { getType } from 'state/types/actions';
import cx from "classnames";
import styles from "pages/Views.module.scss";
import { useIntl } from "react-intl";

const EditMaterialPage = () => {
  const {
    type,
  } = useSelector("types");

  const { id } = useParams("id");
  const dispatch = useDispatch();
  const { formatMessage: f } = useIntl();

  useEffect(() => {
    const getMaterialTypeById = async () => {
      await getType(dispatch, id);
    };
    getMaterialTypeById();
  }, []);

  const pageTitle = type && f({ id: `${type?.type?.toLowerCase()}s.title` });
  const widgetHeader = type && f({ id: `${type?.type?.toLowerCase()}.edit.title` });

  return (
    <div>
      <PageHeader title={pageTitle} />

      <div className={cx(styles["widget"])}>
        <div className={cx(styles["widget-header"], styles["bordered"], styles["no-actions"], "d-flex align-items-center")}>
          <h4>{widgetHeader}</h4>
        </div>

        <div className={cx(styles["widget-body"])}>
          {
            !type?.id
              ?
              <Loading />
              :
              <EditMaterialForm typeEntity={type.type} />
          }
        </div>
      </div>
    </div>

  );
};

export default EditMaterialPage;
