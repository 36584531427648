import { forwardRef } from "react";


export const UntilDateCustomInput = forwardRef(({ value, openCalendar, placeholder }, ref) => (
    <div className="input-group">
        <div>
            <span className="input-group-addon">
                <i className="la la-calendar" />
            </span>
        </div>
        <input onClick={openCalendar} type="text" value={value} className="form-control" id="date-from" placeholder={placeholder}/>
    </div>
));
