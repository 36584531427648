
import MenuItem from "./MenuItem";
import { styled } from '@mui/material/styles';

// Creando un componente estilizado para <ul>
const Menu = styled('ul')({
  display: 'flex',
  alignItems: 'center',
//  flexWrap: 'wrap',
  listStyle: 'none',
  paddingLeft: '1.2rem'
  // Añade aquí más estilos si es necesario
});

export const DesktopMenu = ({menuItemsData}) => {
  const depthLevel = 0;

  return (
      <Menu>
        {menuItemsData.map((menu, index) => {
          return <MenuItem items={menu} key={index} depthLevel={depthLevel} />;
        })}
      </Menu>
  );
};
