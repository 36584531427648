import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { createRoot } from 'react-dom/client';
import { IntlProvider } from "react-intl";
import App from "./App";
import { locales } from "./i18n";
import reportWebVitals from "./reportWebVitals";
import { StoreProvider } from "./state";

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <StoreProvider>
      <IntlProvider locale={"es"} messages={locales["es"]} defaultLocale="es">
        <App />
      </IntlProvider>
    </StoreProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();