import * as types from './types';

export const initialUsersState = {
    users: [],
    pageIndex: 1,
    loading: false,
    deleting: false,
    deleted: false,
    errorDeleting: false,
    total: 0,
    totalPages: 0,
    saved: false,
    persisting: false,
    errorPersisting: false,
    user: {},
    sendingEmailToUserId: undefined,
    emailOk: undefined
  };

  export const usersReducer = (initialState = initialUsersState, action) => {
    switch (action.type) {

      case types.GET_USERS:
        return { ...initialState, users: [], loading: true, total: 0, totalPages: 0, user: {} };

      case types.GET_USERS_SUCCESS:
        return {
          ...initialState,
          users: action.payload ? action.payload.content : [],
          total: action.payload ? action.payload.totalElements : 0,
          totalPages: action.payload ? action.payload.totalPages : 0,
          loading: false };

      case types.GET_USERS_FAILURE:
        return { ...initialState, users: [], loading: false, total: 0 };

      case types.SET_PAGE_INDEX:
        return { ...initialState, pageIndex: action.payload };

      case types.PERSIST_USER:
        return { ...initialState, saved: false, persisting: true, errorPersisting: false };

      case types.PERSIST_USER_SUCCESS:
        return { ...initialState, saved: true, persisting: false, errorPersisting: false };

      case types.PERSIST_USER_FAILURE:
        return { ...initialState, saved: false, persisting: false, errorPersisting: true };

      case types.RESET_SAVED:
        return { ...initialState, saved: false};

      case types.GET_USER:
        return { ...initialState, loading: true };

      case types.GET_USER_SUCCESS:
        return {
          ...initialState,
          user: action.payload,
          loading: false
        };

      case types.GET_USER_FAILURE:
        return { ...initialState, user: {}, loading: false };

      case types.RESEND_EMAIL:
          return { ...initialState, sendingEmailToUserId: action.payload };

      case types.RESEND_EMAIL_SUCCESS:
        return { ...initialState, sendingEmailToUserId: undefined, emailOk: true };

      case types.RESEND_EMAIL_FAILURE:
        return { ...initialState, sendingEmailToUserId: undefined, emailOk: false };

      case types.DELETE_USER:
        return { ...initialState, deleting: true };

      case types.DELETE_USER_SUCCESS:
        return { ...initialState, deleting: false, deleted: true };

      case types.DELETE_USER_FAILURE:
        return { ...initialState, deleting: false, errorDeleting: true };

      case types.RESET_DELETED:
        return { ...initialState, deleted: false, errorDeleting: false };

      case types.RESET_ERROR:
        return { ...initialState, saved: false, deleted: false, errorPersisting: false, errorDeleting: false };

      default:
        return { ...initialState };
    }
  };
  