import React from "react";
import {
  Pagination,
  PaginationItem,
  PaginationLink
} from "reactstrap"

export const PaginationComponent = ({
  pageIndex,
  onPageIndexChange,
  totalPages,
  totalItems,
  pageSize,
  items
}) => {
    const buildPagination = () => {
      let pages = [];

      pages.push(<PaginationItem key={-2} disabled={pageIndex === 1}>
        <PaginationLink first onClick={() => onPageIndexChange(1)} />
      </PaginationItem>);

      pages.push(<PaginationItem key={-1} disabled={pageIndex === 1}>
        <PaginationLink previous onClick={() => onPageIndexChange(pageIndex - 1)} />
      </PaginationItem>);

      let firstPage = 1;
      let lastPage = 10;
      if (totalPages > 10) {
        // se calculan la primera y última página que aparecerán en la paginación
        firstPage = pageIndex - 4;
        lastPage = pageIndex + 5;

        if (firstPage < 1) {
          lastPage = 10;
          while (firstPage < 1) {
            firstPage++;
          }
          lastPage += firstPage - 1 ;
        }

        if (lastPage > totalPages) {
          // se calcula la última página
          while (lastPage > totalPages) {
            lastPage--;
            firstPage--;
          }
        }
      }

      for (let i = firstPage; i <= (totalPages < 10 ? totalPages : lastPage); i++) {
        pages.push(<PaginationItem key={i} active={pageIndex === i} >
          <PaginationLink onClick={() => onPageIndexChange(i)}>{i}</PaginationLink>
        </PaginationItem>);
      }

      pages.push(<PaginationItem key={totalPages + 1} disabled={pageIndex === totalPages}>
        <PaginationLink next onClick={() => onPageIndexChange(pageIndex + 1)} />
      </PaginationItem>);

      pages.push(<PaginationItem key={totalPages + 2} disabled={pageIndex === totalPages}>
        <PaginationLink last onClick={() => onPageIndexChange(totalPages)} />
      </PaginationItem>);
      return pages;
    }

    const buildText = () => {
      return "Mostrando " + getStart() + "-" + getEnd() + " / " + totalItems + " resultados";
    }

    const getStart = () => {
      return ((pageIndex - 1)  * pageSize) + 1;
    }

    const getEnd = () => {
      return (pageIndex - 1) * pageSize + items.length;
    }

    return (
      items && items.length > 0 && <div className="d-flex justify-content-between widget-footer">
          <span>{buildText()}</span>
          <div className="d-flex justify-content-end pagination-padding-top">
            <Pagination aria-label="Page navigation example">
              {buildPagination()}
            </Pagination>
          </div>
        </div>

    )
}