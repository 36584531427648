import { post, get, put, formDataRequest, deleteMethod } from "api/client";

export const getAllVanTypes = async (dispatch, pageIndex, pageSize, criteria) => {
  try {
    dispatch({ type: "GET_VAN_TYPES_CRITERIA" });
    let urlQuery = `material/by-criteria?material-type-code=VAN&pageIndex=${pageIndex}&pageSize=${pageSize}`;
    if (criteria) {
      urlQuery += `&criteria=${criteria}`;
    }
    const data = await get(urlQuery);
    //endpoint call
    dispatch({ type: "GET_VAN_TYPES_CRITERIA_SUCCESS", payload: data }); // loading: false y members: action.payload
  } catch (error) {

    dispatch({
      type: "GET_VAN_TYPES_CRITERIA_FAILURE",
      error: { code: 500, description: error },
    }); //loading: false, stocks: []
  }
};

export const getAllVanInventories = async (dispatch, pageIndex, pageSize, criteria) => {
  try {
    dispatch({ type: "GET_VAN_INVENTORIES_CRITERIA" });
    let urlQuery = `inventories?type=VAN&pageIndex=${pageIndex}&pageSize=${pageSize}`;
    if (criteria) {
      urlQuery += `&criteria=${criteria}`
    }

    const data = await get(urlQuery);
    //endpoint call
    dispatch({ type: "GET_VAN_INVENTORIES_CRITERIA_SUCCESS", payload: data }); // loading: false y members: action.payload
  } catch (error) {

    dispatch({
      type: "GET_VAN_INVENTORIES_CRITERIA_FAILURE",
      error: { code: 500, description: error },
    }); //loading: false, stocks: []
  }
};

export const persistVanType = async (dispatch, data) => {
  try {
    dispatch({ type: "PERSIST_VAN_TYPE" });
    let response = null
    if (data.id) {
      response = await formDataRequest(`van-types/${data.id}`, data, "PUT");
    } else {
      response = await formDataRequest(`van-types`, data, "POST");
    }

    if (response.status === 204 || response.status === 201) {
      dispatch({ type: "PERSIST_VAN_TYPE_SUCCESS" });
    } else {
      dispatch({ type: "PERSIST_VAN_TYPE_FAILURE", payload: response });
    }
    //TODO: Tratamiento de errores centralizado
  } catch (error) {

    dispatch({ type: "PERSIST_VAN_TYPE_FAILURE", payload: error });
  }
};

export const getVanType = async (dispatch, id) => {
  try {
    dispatch({ type: "GET_VAN_TYPE" });
    const response = await get(`van-types/${id}`);


    dispatch({ type: "GET_VAN_TYPE_SUCCESS", payload: response });
  } catch (error) {

    dispatch({ type: "GET_VAN_TYPE_FAILURE" });
  }
};

export const persistVanInventory = async (dispatch, data) => {
  try {
    dispatch({ type: "PERSIST_VAN_INVENTORY" });
    let response = null
    if (data.id) {
      response = await put(`inventories/${data.id}`, data);
    } else {
      response = await post(`inventories`, data);
    }

    if (response.status === 204 || response.status === 201) {
      dispatch({ type: "PERSIST_VAN_INVENTORY_SUCCESS" });
    } else {
      dispatch({ type: "PERSIST_VAN_INVENTORY_FAILURE", payload: response });
    }
    //TODO: Tratamiento de errores centralizado
  } catch (error) {

    dispatch({ type: "PERSIST_VAN_INVENTORY_FAILURE", payload: error });
  }
};

export const getVanTypesEnabled = async (dispatch) => {
  try {
    dispatch({ type: "GET_VAN_TYPES_ENABLED" }); // stocks: [], loading: true
    const data = await get(`types/by-type?material-type-code=VAN&enabled=true`);
    //endpoint call
    dispatch({ type: "GET_VAN_TYPES_ENABLED_SUCCESS", payload: data }); // loading: false y members: action.payload
  } catch (error) {

    dispatch({
      type: "GET_VAN_TYPES_ENABLED_FAILURE",
      error: { code: 500, description: error },
    }); //loading: false, stocks: []
  }
};

export const getVanInventory = async (dispatch, id, pageIndex, pageSize) => {
  try {
    dispatch({ type: "GET_VAN_INVENTORY" });
    const response = await get(`inventories/${id}`);
    const responseHistory = await get(`inventories?inventory=${response.id}&materialType=${response.material.materialType}&pageIndex=${pageIndex}&pageSize=${pageSize}`);

    dispatch({ type: "GET_VAN_INVENTORY_SUCCESS", payload: response });
    dispatch({ type: "SET_VAN_INVENTORY_HISTORY", payload: responseHistory });

  } catch (error) {
    dispatch({ type: "GET_VAN_INVENTORY_FAILURE" });
  }
};

export const deleteVanType = async (dispatch, id) => {
  try {
    dispatch({ type: "DELETE_VAN_TYPE" });
    const response = await deleteMethod(`van-types/${id}`);

    if (response.status === 204 || response.status === 201) {
      dispatch({ type: "DELETE_VAN_TYPE_SUCCESS" });
    } else {
      dispatch({ type: "DELETE_VAN_TYPE_FAILURE", payload: response });
    }
    //TODO: Tratamiento de errores centralizado
  } catch (error) {

    dispatch({ type: "DELETE_VAN_TYPE_FAILURE", payload: error });
  }
};

export const deleteVanInventory = async (dispatch, id) => {
  try {
    dispatch({ type: "DELETE_VAN_INVENTORY" });
    const response = await deleteMethod(`inventories/${id}`);

    if (response.status === 204 || response.status === 201) {
      dispatch({ type: "DELETE_VAN_INVENTORY_SUCCESS" });
    } else {
      dispatch({ type: "DELETE_VAN_INVENTORY_FAILURE", payload: response });
    }
    //TODO: Tratamiento de errores centralizado
  } catch (error) {

    dispatch({ type: "DELETE_VAN_INVENTORY_FAILURE", payload: error });
  }
};


export const getVanMaintenanceHistory = async (dispatch, id, pageIndex, pageSize) => {
  try {
    dispatch({ type: "GET_VAN_INVENTORY_MAINTENANCE_HISTORY" });
    const response = await get(`maintenances?inventory_id=${id}&pageIndex=${pageIndex}&pageSize=${pageSize}`);


    dispatch({ type: "GET_VAN_INVENTORY_MAINTENANCE_HISTORY_SUCCESS", payload: response });

  } catch (error) {
    dispatch({ type: "GET_VAN_INVENTORY_MAINTENANCE_HISTORY_FAILURE" });
  }
};