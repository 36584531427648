import { MaintenanceForm } from "components/Form/Maintenance/MaintenanceForm";
import Loading from "components/Loading/Loading";
import { PageHeader } from "components/PageHeader";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "state";
import { getMaintenance } from "state/maintenances/actions";
import cx from 'classnames';
import styles from 'pages/Views.module.scss';

export const EditMaintenancePage = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    const { maintenance, loading } = useSelector('maintenances');

    useEffect(() => {
        const getMaintenanceFunction = async () => {
            await getMaintenance(dispatch, id);
        }
        getMaintenanceFunction();
    }, []);

    return (

        loading || !maintenance ?

            <Loading />
            :
            <>
                <PageHeader title={"Editar mantenimiento"} />

                <div className={cx(styles["widget-header"], styles["bordered"], styles["no-actions"], "d-flex align-items-center")}>
                    <h4>{maintenance.inventory?.numberPlate}</h4>
                </div>
                <MaintenanceForm
                    maintenance={maintenance}
                />
            </>
    ); // return
} // EditMaintenancePage