export const initialInventoriesState = {
    loading: false,
    persisting: false,
    saved: false,
    inventory: undefined,
    inventories: [],
    inventoryPageIndex: 1,
    inventoryTotal: 0,
    inventoryTotalPages: 0,
    rentingHistory: [],
    inventoriesForRequest: [],
    currentRenting: null,
    rentingPageIndex: 0,
};

export const inventoriesReducer = (initialState = initialInventoriesState, action) => {
    switch (action.type) {

        case "CHANGE_INVENTORY_PAGE_INDEX":
            return { ...initialState, inventoryPageIndex: action.payload };

        case "GET_INVENTORIES_CRITERIA":
            return { ...initialState, inventories: [], loading: true, inventory: undefined };

        case "GET_INVENTORIES_CRITERIA_SUCCESS":
            return {
                ...initialState,
                inventories: action.payload.content,
                inventoryTotal: action.payload.totalElements,
                inventoryTotalPages: action.payload.totalPages,
                loading: false
            };

        case "GET_INVENTORIES_CRITERIA_FAILURE":
            return { ...initialState, inventories: [], loading: false };

        case "GET_ENABLED_INVENTORIES":
            return { ...initialState, inventoriesForRequest: [], loading: true, inventory: undefined };

        case "GET_ENABLED_INVENTORIES_SUCCESS":
            return {
                ...initialState,
                inventoriesForRequest: action.payload.content,
                inventoryTotal: action.payload.totalElements,
                inventoryTotalPages: action.payload.totalPages,
                loading: false
            };

        case "GET_ENABLED_INVENTORIES_FAILURE":
            return { ...initialState, inventoriesForRequest: [], loading: false };

        case "PERSIST_INVENTORY":
            return { ...initialState, persisting: true, saved: false, errorPersisting: false };

        case "PERSIST_INVENTORY_SUCCESS":
            return { ...initialState, persisting: false, saved: true, errorPersisting: false };

        case "PERSIST_INVENTORY_FAILURE":
            return { ...initialState, persisting: false, saved: false, errorPersisting: true };

        case "GET_INVENTORY":
            return { ...initialState, loading: true };

        case "GET_INVENTORY_SUCCESS":
            return {
                ...initialState,
                inventory: { ...action.payload, materialId: action.payload.material?.id.toString() },
                loading: false,
            };

        case "GET_INVENTORY_FAILURE":
            return { ...initialState, inventory: undefined, loading: false };

        case "DELETE_INVENTORY":
            return { ...initialState, deleting: true };

        case "DELETE_INVENTORY_SUCCESS":
            return { ...initialState, deleting: false, deleted: true };

        case "DELETE_INVENTORY_FAILURE":
            return { ...initialState, deleting: false, errorDeleting: true };

        case "RESET_DELETED":
            return { ...initialState, deleted: false, errorDeleting: false };

        case "GET_CURRENT_RENTING_INVENTORY":
            return { ...initialState, currentRenting: null, loading: true };

        case "GET_CURRENT_RENTING_INVENTORY_SUCCESS":
            return { ...initialState, currentRenting: action.payload, loading: false };

        case "GET_CURRENT_RENTING_INVENTORY_FAILURE":
            return { ...initialState, currentRenting: null, loading: false };

        case "GET_RENTING_HISTORY_FOR_INVENTORY":
            return { ...initialState, rentingHistory: [], loading: true };

        case "GET_RENTING_HISTORY_FOR_INVENTORY_SUCCESS":
            return { ...initialState, rentingHistory: action.payload, loading: false };

        case "GET_RENTING_HISTORY_FOR_INVENTORY_FAILURE":
            return { ...initialState, rentingHistory: [], loading: false };

        case "CHANGE_RENTING_HISTORY_PAGE_INDEX":
            return { ...initialState, rentingPageIndex: action.payload };

        default:
            return { ...initialState };
    }
};