import React, { useEffect, useState } from 'react';
import { useIntl } from "react-intl";
import { Spinner } from 'reactstrap';
import { SearchBarAndButtonHeader } from 'components/SearchBarAndButtonHeader';
import { Table } from 'components/Table';
import { useNavigate, Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { PaginationComponent } from 'components/Shared/PaginationComponent/PaginationComponent';import { ConfirmationModal } from 'components/Shared/ConfirmationModal/ConfirmationModal';
import { useSelector, useDispatch } from 'state';
import { setPageIndex, resendEmail, deleteUser } from 'state/users';
import { PAGE_SIZE } from 'utils/utils';
import { get } from "api/client";
import * as types from 'state/users/types';

const tableHeaders = () => {
    return (
        <tr>
            <th scope="col">Nombre y apellidos</th>
            <th scope="col">Email</th>
            <th scope="col">Estado</th>
            <th scope="col">Sincronización</th>
            <th scope="col" className="width-10-percent">Acciones</th>
        </tr>
    )
}

const buildName = (user) => {
    let name = user.name + ' ' + user.surname;

    if (user.surname2) {
        name += ' ' + user.surname2;
    }

    return name
}
const columnsData = (array, onEdit, onResendEmail, sendingEmailToUserId, onDeleteClick) => {
    return array.map((data) => {
        return (
            <tr key={data.id}>
                <td>{buildName(data)}</td>
                <td>{data.email}</td>
                <td>{data.enabled ?
                    <span className="badge-text badge-text-small success">Activo</span>
                    :
                    <span className="badge-text badge-text-small danger">Inactivo</span>
                }</td>
                <td>{data.microsoftId ?
                    <span className="badge-text badge-text-small success">Sincronizado</span>
                    :
                    <span className="badge-text badge-text-small danger">No sincronizado</span>
                }</td>
                <td className="td-actions">
                    <Link to={"/users/" + data.id}><i className="la la-edit edit"/></Link>
                    {!data.enabled &&
                        <a role="button" label="Reenviar email de bienvenida" onClick={() => onResendEmail(data.id)}>
                            {sendingEmailToUserId === data.id ?
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        className="mr-1"
                                        aria-hidden="true"
                                        variant="primary"
                                    />
                                :
                                    <i className="la la-envelope edit"/>
                            }
                        </a>
                    }
                    <a role="button" onClick={() => onDeleteClick(data)}><i className="la la-trash delete"/></a>
                </td>
            </tr>
        )
    })
}

const UsersBody = (props) => {
    const { formatMessage: f } = useIntl();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { pageIndex, total, totalPages, sendingEmailToUserId, emailOk, deleting, deleted, errorDeleting } = useSelector('users');

    const [ showDeleteForm, setShowDeleteForm ] = useState(false);
    const [ allowedDelete, setAllowedDelete ] = useState(true);
    const [ userToDelete, setUserToDelete ] = useState(undefined);

    useEffect(() => {
        if (!sendingEmailToUserId && emailOk !== undefined) {
            if (emailOk) {
                NotificationManager.success("El email se ha enviado correctamente");
            } else {
                NotificationManager.error("Ha ocurrido un error al enviar el email");
            }
        }

    }, [sendingEmailToUserId]);

    useEffect(() => {
        if (deleted) {
            NotificationManager.success("Eliminado correctamente");
            dispatch({ type: types.RESET_DELETED });
            setUserToDelete(undefined);
            setShowDeleteForm(false);
        } else if (errorDeleting) {
            NotificationManager.error("Se ha producido un error");
            dispatch({ type: types.RESET_ERROR });
        }
    }, [deleted, errorDeleting]);

    const onEdit = (id) => {
        navigate("/users/" + id);
    }

    const onPageIndexChange = (newPageIndex) => {
        setPageIndex(dispatch, newPageIndex);
    }

    const onResendEmail = (userId) => {
        resendEmail(dispatch, userId);
    }

    const onDeleteClick = async (user) => {
        // se comprueba si el borrado es posible
        const urlQuery = 'users/check-delete/' + user.id;
        const allowed = await get(urlQuery);
        if (allowed) {
            setUserToDelete(user);
        }
        setAllowedDelete(allowed);
        setShowDeleteForm(true);
    }

    const onCancelDeleteClick = () => {
        setShowDeleteForm(false);
    }

    const onDeleteConfirm = async () => {
        await deleteUser(dispatch, userToDelete.id);
    }

    return (
        <div className="tab-pane fade show active mt-3" id="j-tab-1" role="tabpanel" aria-labelledby="just-tab-1">
            <ConfirmationModal
                showModal={showDeleteForm}
                onToggleClick={() => setShowDeleteForm(false)}
                header={f({id: "user.delete.title"})}
                message={!allowedDelete ? f({ id: "user.delete.notAllowed" }) : (userToDelete && f({id: "user.delete.message"}, { description: userToDelete.email}))}
                cancelAction={allowedDelete && onCancelDeleteClick}
                cancelButtonLabel={f({id: "app.cancel"})}
                okAction={allowedDelete ? onDeleteConfirm : onCancelDeleteClick}
                okButtonLabel={allowedDelete ? f({id: "app.delete"}) : f({id: "app.close"})}
                loading={deleting}
                loadingMessage={f({id: "app.deleting"})}
            />
            <SearchBarAndButtonHeader
                text={f({ id: 'users.bodyTitle' })}
                url="/users/new"
                showAddButton={true}
                filterText
                criteria={props.criteria}
                criteriaChange={props.setCriteria}
            />
            <Table headers={tableHeaders()} columnsData={columnsData(props.users, onEdit, onResendEmail, sendingEmailToUserId, onDeleteClick)} pagination={<PaginationComponent
              items={props.users}
              pageIndex={pageIndex}
              pageSize={PAGE_SIZE}
              totalItems={total}
              onPageIndexChange={onPageIndexChange}
              totalPages={totalPages}
            />} />

        </div>


    );
}

export default UsersBody;