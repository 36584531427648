export const initialRoomsState = {
  rooms: [],
  loading: false,
  room: {},
  saved: false,
  errorPersisting: false,
  persisting: false,
  pageIndex: 1,
  total: 0,
  totalPages: 0,
  deleting: false,
  deleted: false,
  errorDeleting: false,
  availableRoomsByGuest: {},
  roomsWithOccupation: [],
  roomsWithOccupationByDate: undefined,
  guestsInRoom: []
};

export const roomsReducer = (initialState = initialRoomsState, action) => {
  switch (action.type) {

    case "GET_ROOMS":
      return { ...initialState, rooms: [], loading: true, room: {} };

    case "GET_ROOMS_SUCCESS":
      return {
        ...initialState,
        loading: false,
        rooms: action.payload.content,
        total: action.payload.totalElements,
        totalPages: action.payload.totalPages,
      };

    case "GET_ROOMS_FAILURE":
      return { ...initialState, rooms: [], loading: false };

    case "PERSIST_ROOM":
      return { ...initialState, saved: false, persisting: true, errorPersisting: false };

    case "PERSIST_ROOM_SUCCESS":
      return { ...initialState, saved: true, persisting: false, errorPersisting: false };

    case "PERSIST_ROOM_FAILURE":
      return { ...initialState, saved: false, persisting: false, errorPersisting: true };

    case "RESET_SAVED":
      return { ...initialState, saved: false };

    case "RESET_ERROR":
      return { ...initialState, saved: false, deleted: false, errorPersisting: false, errorDeleting: false };

    case "GET_ROOM":
      return { ...initialState, loading: true };

    case "GET_ROOM_SUCCESS":
      return {
        ...initialState,
        room: action.payload,
        loading: false,
      };

    case "GET_ROOM_FAILURE":
      return { ...initialState, room: {}, loading: false };

    case "CHANGE_ROOMS_PAGE_INDEX":
      return { ...initialState, pageIndex: action.payload };

    case "DELETE_ROOM":
      return { ...initialState, deleting: true };

    case "DELETE_ROOM_SUCCESS":
      return { ...initialState, deleting: false, deleted: true };

    case "DELETE_ROOM_FAILURE":
      return { ...initialState, deleting: false, errorDeleting: true };

    case "RESET_DELETED":
      return { ...initialState, deleted: false, errorDeleting: false };

    case "GET_AVAILABLE_ROOMS":
      return { ...initialState, availableRoomsByGuest: {}, loading: true };

    case "GET_AVAILABLE_ROOMS_SUCCESS":
      return { ...initialState, availableRoomsByGuest: action.payload, loading: false };

    case "GET_AVAILABLE_ROOMS_FAILURE":
      return { ...initialState, availableRoomsByGuest: {}, loading: false };

    case "GET_ROOMS_WITH_OCCUPATION":
      return { ...initialState, roomsWithOccupation: [], loading: true };

    case "GET_ROOMS_WITH_OCCUPATION_SUCCESS":
      return { ...initialState, roomsWithOccupation: action.payload, loading: false };

    case "GET_ROOMS_WITH_OCCUPATION_FAILURE":
      return { ...initialState, roomsWithOccupation: [], loading: false };

    case "GET_OCCUPATION_BY_DATE_RANGE":
      return { ...initialState, roomsWithOccupationByDate: undefined, loading: true };

    case "GET_OCCUPATION_BY_DATE_RANGE_SUCCESS":
      return { ...initialState, roomsWithOccupationByDate: action.payload, loading: false };

    case "GET_OCCUPATION_BY_DATE_RANGE_FAILURE":
      return { ...initialState, roomsWithOccupationByDate: undefined, loading: false };

    case "GET_GUESTS_IN_ROOM_IN_DATE":
      return { ...initialState, guestsInRoom: [], loading: true };

    case "GET_GUESTS_IN_ROOM_IN_DATE_SUCCESS":
      return { ...initialState, guestsInRoom: action.payload, loading: false };

    case "GET_GUESTS_IN_ROOM_IN_DATE_FAILURE":
      return { ...initialState, guestsInRoom: [], loading: false };

      case "RESET_ROOM_OCCUPATION_BY_DATE":
        return { ...initialState, roomsWithOccupationByDate: [], loading: false };

    default:
      return { ...initialState };
  }
};
