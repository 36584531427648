const reactSelectCustom = {
  control: (styles, state) => ({
    ...styles,
    color: '#20294c',
    backgroundColor: ' #f8f8f8',
    borderColor: '#f8f8f8',
    fontWeight: 600,
    lineHeight: 1.2,
    border: '1px solid transparent',
    fontSize: '0.85rem',
    borderRadius: '60px',
    transition: 'all 0.2s ease-in-out',
    position: 'relative',
    overflow: 'hidden',
    paddingLeft: '20px',
    cursor: 'pointer',
    "&:hover": {
      borderColor: "#f8f8f8"
    },
    boxShadow: '0 0 0 1px #f8f8f8',
  }),
  container: (styles, state) => ({
    ...styles,
    width: '100%'
  }),
  indicatorSeparator: (styles) => ({ display: "none" }),
  singleValue: (styles) => ({
    ...styles,
    fontWeight: "bold",
    fontSize: '0.85rem',
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: ' #f8f8f8',
    fontSize: '0.85rem'  }),
    multiValueLabel: (styles) => ({
      ...styles,
      fontSize: '0.85rem'  }),
  valueContainer: (styles) => ({ ...styles, padding: 0 }),
};

export default reactSelectCustom;
