
import { Button, Grid, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
// import { useChangePasswordMutation } from 'state/api/loggedUserApi';
import { yupResolver } from '@hookform/resolvers/yup';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import ChangePasswordImage from "assets/images/change-pass.png";
import ErrorMessage from 'components/Shared/ErrorMessage/ErrorMessage';
import { FormInput } from 'components/Shared/Form/FormInput';
import * as yup from 'yup';

const validationSchema = yup.object({
  currentPassword: yup
    .string()
    .required('La contraseña actual es obligatoria'),
  newPassword: yup
    .string()
    .required('La nueva contraseña es obligatoria')
    .min(8, 'La contraseña debe tener al menos 8 caracteres'),
  confirmPassword: yup
    .string()
    .required('La confirmación de la contraseña es obligatoria')
    .oneOf([yup.ref('newPassword'), null], 'Las contraseñas deben coincidir')
}).required();

export const ChangePasswordSuccess = () => {
  return (<Grid container spacing={2}
    sx={{ paddingTop: '2rem' }}
    alignItems="center"
    justifyContent="center">

    <Grid item xs={12} sm={8} lg={4} >
      <Card>
        <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
          <Typography variant="h1" sx={{ textAlign: 'center', marginBottom: '2rem' }}>
            La contraseña se ha cambiado con éxito.
          </Typography>
          <img
            src={ChangePasswordImage}
            height={200}
            alt='Password cambiada'
          />
        </CardContent>
      </Card>
    </Grid>
  </Grid>
  )
}

const ChangePasswordPage = () => {

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  // 👇 API Change Password Mutation
  // const [changePassword, { error: apiError, isSuccess }] = useChangePasswordMutation();

  const {
    handleSubmit,
    formState: { errors },
  } = methods;


  const onSubmitHandler = (values) => {
    // changePassword(values);
  };

  // useFormErrorHandling(setError, apiError);

  // if (isSuccess) {
  //   return ( <ChangePasswordSuccess/> )
  // } 

  return (
    <Grid container spacing={2}
      sx={{ paddingTop: '2rem' }}
      alignItems="center"
      justifyContent="center">

      <Grid item xs={12} sm={8} lg={4} >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmitHandler)}
            noValidate
            autoComplete='off'
            width='100%'>
            <Card>
              <CardHeader title="Cambiar contraseña" />
              <CardContent>
                {errors.general && <ErrorMessage message={errors.general.message} />}
                <div style={{display: 'flex', flexDirection: 'column', gap: '2rem'}}>

                  <FormInput name='currentPassword' label='Contraseña actual' type='password' />
                  <FormInput name='newPassword' label='Nueva contraseña' type='password' />
                  <FormInput
                    name='confirmPassword'
                    label='Repite la contraseña'
                    type='password'
                  />
                </div>
                {errors.general && <p>{errors.general.message}</p>}

              </CardContent>
              <CardActions>
                <Button
                  variant='contained'
                  disableElevation
                  type='submit'
                >
                  Actualizar contraseña
                </Button>
              </CardActions>
            </Card>
          </form>

        </FormProvider>
      </Grid>
    </Grid>
  );
};

export default ChangePasswordPage;
