import { useEffect } from 'react';

export const Dropzone = (props) => {

    const thumbsContainer = {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16
    };

    const thumb = {
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        width: 100,
        height: 100,
        padding: 4,
        boxSizing: 'border-box'
    };

    const thumbInner = {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden'
    };

    const img = {
        display: 'block',
        width: 'auto',
        height: '100%'
    };

    const marginRight15 = {
        marginRight: '15px'
    };

    const { files, getRootProps, getInputProps, removeFile } = props;

    const thumbs = files.map(file => (
        <div key={file.customId} style={marginRight15}>
            <div style={thumb} key={file.name}>
                <div style={thumbInner}>
                    <img
                        alt={file.name}
                        src={file.preview}
                        style={img}
                        // Revokes data uri after image is loaded
                        onLoad={() => { URL.revokeObjectURL(file.preview) }}
                    />
                </div>
            </div>
            <a href={() => false} onClick={() => removeFile(file.customId)}><i className="la la-trash delete" /></a>
        </div>
    ));

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);

    return (
        <div className="file-upload-wrapper">

            <div className="card card-body view file-upload">
                <section className="container">
                    <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        <i className="la la-cloud-upload" /><p>Arrastre o suba los archivos</p>
                    </div>
                </section>

            </div>
            <aside style={thumbsContainer}>
                {thumbs}
            </aside>
        </div>
    );
}