import { EditInventoryContainer } from 'components/EditInventoryContainer';
import Loading from "components/Loading/Loading";
import { PageHeader } from 'components/PageHeader';
import styles from 'pages/Views.module.scss';
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "state";
import { getInventory } from 'state/inventories';
import { getMaintenanceHistoryForInventory } from 'state/maintenances/actions';
import { PAGE_SIZE } from "utils/utils";
import { PAGE_TITLES } from '../../utils/utils';
import { getCities } from 'state/citiesSlice';
import { getCurrentRenting } from 'state/inventories/actions';

export const EditInventoryPage = () => {
    const {
        historyPageIndex,
    } = useSelector('types');

    const {
        inventory,
        rentingHistory
    } = useSelector('inventories');

    const {
        maintenanceHistory,
        maintenancePageIndex,
        maintenanceTotal,
        maintenanceTotalPages,
    } = useSelector('maintenances');

    const { id } = useParams('id');
    const dispatch = useDispatch();

    const onMaintenancePageChange = (page) => {
        dispatch({ type: `CHANGE_MAINTENANCE_PAGE_INDEX`, payload: page })
    } // onMaintenancePageChange

    const onRentingHistoryPageChange = (page) => {
        dispatch({ type: `CHANGE_RENTING_HISTORY_PAGE_INDEX`, payload: page })
    } // onPageChange

    const materialType = inventory?.material?.type.toLowerCase();

    useEffect(() => {
        const getInventoryData = async () => {
            await getInventory(dispatch, id, historyPageIndex - 1, PAGE_SIZE);
            await getMaintenanceHistoryForInventory(dispatch, id, maintenancePageIndex - 1, PAGE_SIZE);
        }; // getInventoryData

        getInventoryData();
    }, [maintenancePageIndex]);

    useEffect(() => {
        const getCurrentRentingFunction = async() => {
            await getCurrentRenting(dispatch, id);
        } 
        getCurrentRentingFunction();
        getCities();
    }, [])


    return (
        <div>
            <PageHeader title={PAGE_TITLES[materialType]} />
            {
                !inventory ?
                    <Loading />
                    :
                    <EditInventoryContainer
                        inventoryType={materialType}
                        inventory={inventory}
                        styles={styles}
                        materialType={materialType}
                        rentHistory={{
                            history: rentingHistory.content,
                            pageIndex: rentingHistory.pageable?.pageNumber + 1,
                            totalItems: rentingHistory.pageable?.pageSize,
                            onPageChange: (page) => onRentingHistoryPageChange(page),
                            totalPages: rentingHistory.totalPages
                        }}
                        maintenanceHistory={{
                            history: maintenanceHistory,
                            pageIndex: maintenancePageIndex,
                            totalItems: maintenanceTotal,
                            onPageChange: (page) => onMaintenancePageChange(page),
                            totalPages: maintenanceTotalPages,
                        }}
                    />
            }
        </div>
    ); // return
}; // Edit