import cx from 'classnames';
import { FilterText } from 'components/FilterText';
import { AddButton } from 'components/Shared/AddButton/AddButton';
import styles from './SearchBarAndButtonHeader.module.scss';
import { useSelector } from 'state';

export const SearchBarAndButtonHeader = (
    {
        text,
        showAddButton,
        url,
        filterText,
        criteriaChange,
    }
) => {
    const {
        criteria,
    } = useSelector('requests');

    return (
        <div className={"tab-pane fade show active mt-3"} id="j-tab-1" role="tabpanel" aria-labelledby="just-tab-1">
            <div
                className={
                    cx(
                        styles["bordered"],
                        styles["widget-header"],
                        styles["no-actions"],
                        "no-actions d-flex justify-content-between align-items-center pt-2",
                        filterText ? "padding-right-10" : ""
                    )
                }
            >
                <h4>{text}</h4>
                {
                    showAddButton ?
                        <AddButton url={url} />
                        :
                        filterText &&

                        <FilterText
                            criteriaChange={criteriaChange}
                            criteria={criteria}
                        />
                }
            </div>
            {
                showAddButton &&
                filterText &&
                <div className="d-flex justify-content-start mt-20 mb-20 padding-right-10">
                    <FilterText criteriaChange={criteriaChange} value={criteria} />
                </div>
            }
        </div>
    ); // return
}