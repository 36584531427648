import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "state";
import { getRoom } from "state/rooms";
import cx from 'classnames';
import styles from 'pages/Views.module.scss';
import { PageHeader } from 'components/PageHeader';
import { useIntl } from "react-intl";
import RoomForm from './form'
import Loading from "components/Loading/Loading";

const EditRoom = () => {
  const { loading, room } = useSelector("rooms");
  const { id } = useParams("id");
  const dispatch = useDispatch();
  const { formatMessage: f } = useIntl();

  useEffect(() => {
    const getRoomData = async () => {
      await getRoom(dispatch, id);
    };
    getRoomData();
  }, []);

  return (
    <div>
      <PageHeader title="Habitaciones" />

      <div className={cx(styles["widget"])}>
        <div className={cx(styles["widget-header"], styles["bordered"], styles["no-actions"], "d-flex align-items-center")}>
          <h4>{f({ id: 'room.edit.title' })}</h4>
        </div>

        <div className={cx(styles["widget-body"])}>
          {loading || !room.id ?
            <Loading />
            :
            <RoomForm />
          }
        </div>
      </div>
    </div>

  );
};

export default EditRoom;
