import cx from 'classnames';
import { PageHeader } from 'components/PageHeader';
import styles from 'pages/Views.module.scss';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'state';
import { getAllRooms } from 'state/rooms';
import { PAGE_SIZE } from 'utils/utils';
import RoomsBody from './list';

const RoomManagement = () => {

	// const {
	// 	pageIndex,
	// 	deleted
	// } = useSelector('rooms');
	const test = useSelector('rooms');

	const {
		loading,
		rooms,
		pageIndex,
		total,
		totalPages,
		deleted
	} = test;
	console.log("🚀 ~ RoomManagement ~ test:", test)

	const dispatch = useDispatch();

	const [criteria, setCriteria] = useState(undefined);

	useEffect(() => {
		updateData();
	}, [pageIndex, dispatch, criteria]);

	useEffect(() => {
		if (!deleted) {
			updateData();
		}
	}, [deleted]);

	const updateData = () => {
		const getRoomsFunction = async () => {
			await getAllRooms(dispatch, pageIndex - 1, PAGE_SIZE, criteria);
		}
		getRoomsFunction();
	}

	return (
		<div>
			<PageHeader title="Habitaciones" />
			<div className={cx(styles["widget"])}>
				<div className={cx(styles["widget-body"])}>
					<RoomsBody
						rooms={rooms}
						pageIndex={pageIndex}
						total={total}
						totalPages={totalPages}
						pageSize={PAGE_SIZE}
						criteria={criteria}
						setCriteria={setCriteria}
					/>

				</div>
			</div>
		</div>
	);
}

export default RoomManagement;