import cx from 'classnames';
import styles from 'pages/Views.module.scss';
import Select from "react-select";
import { useSelector } from 'state';
import reactSelectCustom from 'utils/reactSelectCustomProps';

export const AvailableRooms = ({
    guests,
    rooms,
    availableRoomsByGuest,
    changeGuestRoom,
    invalidGuests,
    isDisabled,
}) => {

    const { roomDatesHaveChanged } = useSelector('requests');

    /**
     * Searches for a room and returns it as an option.
     * @param {number} roomId - The room ID.
     * @returns {Object|null} The room as an option if it was found, null otherwise.
     */
    const findRoomAsOption = (roomId) => {
        let roomAsOption = null;

        if (!roomDatesHaveChanged) {

            const foundRoom = rooms.find(room => room.id === roomId);

            if (foundRoom) {
                roomAsOption = {
                    label: labelForAvailableRooms(foundRoom),
                    value: foundRoom?.id
                }; // return
            } // if
        } // if

        return roomAsOption;
    } // findRoomAsOption

    /**
     * Generates a label for available rooms, including an icon and availability information.
     *
     * @param {Object} room - The room object containing information about the room.
     * @param {string} room.roomGender - The gender designation of the room ('M' for male, 'F' for female, or other).
     * @param {string} room.name - The name of the room.
     * @param {number} room.emptyBeds - The number of empty beds in the room.
     * @param {number} room.numberOfBeds - The total number of beds in the room.
     * @returns {JSX.Element} A JSX element containing the label for the room if provided, 
     *                        undefined otherwise.
     */
    const labelForAvailableRooms = (room) => {
        let label;

        if (room) {
            label = (
                <>
                    {getIconForRoom(room.roomGender)}
                    {`${room.name} (${room.emptyBeds}/${room.numberOfBeds} disponibles)`}
                </>
            ); // label
        } // if

        return label;
    } // labelForAvailableRooms

    /**
     * Returns an icon based on the gender of the room.
     *
     * @param {string|null} gender - The gender designation of the room ('M' for male, 'F' for female) or null.
     * @returns {JSX.Element} A JSX element containing the icon for the room's gender.
     */
    const getIconForRoom = (gender) => {
        let classname = 'no-gender-icon-select';

        if (gender === 'M') {
            classname = "las la-mars";
        } else if (gender === 'F') {
            classname = "las la-venus";
        } // if-else

        return <i className={classname} style={{ fontSize: '20px', marginRight: '20px' }} />;
    } // getIconForRoom

    /**
     * Formats available rooms for a specific guest, creating an array of room options.
     *
     * @param {number} guestId - The ID of the guest for whom available rooms are being formatted.
     * @param {Object} availableRoomsByGuest - An object containing arrays of available rooms, keyed by guest ID.
     * @returns {Array} An array of formatted room objects with label and value properties.
     */
    const formatAvailableRoomsForGuest = (guestId) => {
        let formattedAvailableRooms = [];
        let roomsWithFreeBeds = availableRoomsByGuest[guestId];

        roomsWithFreeBeds?.forEach(roomWithBeds => {
            const formattedRoom = {
                label: labelForAvailableRooms(roomWithBeds),
                value: roomWithBeds.id
            }; // formattedRoom

            formattedAvailableRooms.push(formattedRoom);
        }); // forEach

        return formattedAvailableRooms;
    } // formatAvailableRoomsForGuest

    return guests?.length > 0 && (

        <div className={cx(styles["widget-body"], styles["min-height-0"], "pb-0")}>
            <div className="widget-header bordered no-actions d-flex align-items-center">
                <div className="section-title my-3 pl-3">
                    <h4>Habitaciones disponibles</h4>
                </div>
            </div>

            {
                guests?.map((guest) => {
                    return (
                        <div
                            key={guest.id}
                            style={{ display: "flex", flexDirection: "row", alignItems: "baseline", marginTop: "10px", marginLeft: '1.5rem' }}
                        >
                            <label className="col-lg-4 form-control-label">{guest.firstName + ' ' + guest.lastName}</label>
                            <div className="col-lg-6 form-group" style={{ marginBottom: '10px' }}>
                                <Select
                                    options={formatAvailableRoomsForGuest(guest.id)}
                                    styles={reactSelectCustom}
                                    onChange={(newRoom) => changeGuestRoom(guest.id, newRoom?.value)}
                                    value={findRoomAsOption(guest.room)}
                                    isSearchable={false}
                                    isClearable={true}
                                    isDisabled={isDisabled}
                                    noOptionsMessage={() => "No hay habitaciones disponibles"}
                                    placeholder={"Seleccione una habitación..."}
                                />
                                {
                                    invalidGuests.includes(guest.id) &&
                                    <div style={{ paddingTop: '1rem', textAlign: 'center' }}>
                                        <label style={{ color: 'red', fontSize: '1rem' }}>
                                            La habitación seleccionada <br />
                                            no es válida para este huésped
                                        </label>
                                    </div>
                                }
                            </div>
                        </div>
                    ); // return
                }) // map
            }
        </div>
    ); // return
} // AvailableRooms