import { useEffect, useState } from 'react';
import { useIntl } from "react-intl";
import { NotificationManager } from 'react-notifications';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'state';

import Loading from 'components/Loading/Loading';
import { SearchBarAndButtonHeader } from 'components/SearchBarAndButtonHeader';
import { ConfirmationModal } from 'components/Shared/ConfirmationModal/ConfirmationModal';
import { PaginationComponent } from 'components/Shared/PaginationComponent/PaginationComponent';
import { Table } from 'components/Table';
import { deleteRoom } from "state/rooms";

const RoomsBody = (props) => {
	const { rooms, pageIndex, total, totalPages, criteria, setCriteria, pageSize } = props;
	const { formatMessage: f } = useIntl();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { deleted, errorDeleting, deleting, loading } = useSelector('rooms');
	const [showDeleteForm, setShowDeleteForm] = useState(false);
	const [roomToDelete, setRoomToDelete] = useState(undefined);

	const YES_LABEL = f({ id: 'room.enabled.yes' });
	const NO_LABEL = f({ id: 'room.enabled.no' });

	useEffect(() => {
		if (deleted) {
			NotificationManager.success("Eliminado correctamente");
			dispatch({ type: "RESET_DELETED" });
			setRoomToDelete(undefined);
			setShowDeleteForm(false);
		} else if (errorDeleting) {
			NotificationManager.error("Se ha producido un error");
			dispatch({ type: "RESET_ERROR" });
		}
	}, [deleted, errorDeleting, dispatch]);


	/**
	 * Navigates to the edit page for the specified room ID.
	 *
	 * @param {number} id - The ID of the room to edit.
	 */
	const onEdit = (id) => {
		navigate("/rooms/" + id);
	}; // onEdit

	/**
	 * Sets the room to delete and displays the delete confirmation form.
	 *
	 * @param {Object} room - The room object to delete.
	 */
	const onDeleteClick = (room) => {
		setRoomToDelete(room);
		setShowDeleteForm(true);
	}; // onDeleteClick

	/**
	 * Clears the room to delete and hides the delete confirmation form.
	 */
	const onCancelDeleteClick = () => {
		setRoomToDelete(undefined);
		setShowDeleteForm(false);
	}; // onCancelDeleteClick

	/**
	 * Handles the delete confirmation action.
	 */
	const onDeleteConfirm = async () => {
		await deleteRoom(dispatch, roomToDelete.id);
	}; // onDeleteConfirm

	/**
	 * Renders table headers for the rooms table.
	 *
	 * @returns {JSX.Element} A table row element containing the table headers.
	 */
	const tableHeaders = () => {
		return (
			<tr>
				<th scope="col">Nombre</th>
				<th scope="col">Nº de camas</th>
				<th scope="col">Estado</th>
				<th scope="col" className="width-10-percent">Acciones</th>
			</tr>
		); // return
	}; // tableHeaders

	/**
	 * Renders table rows for each room in the array.
	 *
	 * @param {Array} array - The array of room objects to render.
	 * @param {function} onEdit - The callback function to call when the edit button is clicked.
	 * @returns {JSX.Element[]} An array of table row elements.
	 */
	const columnsData = (array, onEdit) => {
		return array?.map((data) => {
			return (
				<tr key={data.brand + data.model}>
					<td>{data.name}</td>
					<td>{data.numberOfBeds}</td>
					<td>
						{data.enabled ? (
							<span className="badge-text badge-text-small success">{YES_LABEL}</span>
						) : (
							<span className="badge-text badge-text-small danger">{NO_LABEL}</span>
						)}
					</td>
					<td className="td-actions">
						<a role="button" onClick={() => onEdit(data.id)}>
							<i className="la la-edit edit" />
						</a>
						<a role="button" onClick={() => onDeleteClick(data)}>
							<i className="la la-trash delete" />
						</a>
					</td>
				</tr>
			); // return
		}); // return
	}; // columnsData


	return (
		<div className="tab-pane fade show active mt-3" id="j-tab-1" role="tabpanel" aria-labelledby="just-tab-1">
			<ConfirmationModal
				showModal={showDeleteForm}
				onToggleClick={() => setShowDeleteForm(false)}
				header={f({ id: "room.delete.title" })}
				message={roomToDelete && f({ id: "room.delete.message" }, { description: roomToDelete.name })}
				cancelAction={onCancelDeleteClick}
				cancelButtonLabel={f({ id: "app.cancel" })}
				okAction={onDeleteConfirm}
				okButtonLabel={f({ id: "app.delete" })}
				loading={deleting}
				loadingMessage={f({ id: "app.deleting" })}
			/>
			<SearchBarAndButtonHeader
				text={f({ id: 'rooms.bodyTitle' })}
				url="/rooms/new"
				showAddButton={true}
				filterText
				criteria={criteria}
				criteriaChange={setCriteria}
			/>
			{loading ?
				<Loading />
				:
				<Table headers={tableHeaders()} columnsData={columnsData(rooms, onEdit)}
					pagination={
						<PaginationComponent
							items={rooms}
							pageIndex={pageIndex}
							pageSize={pageSize}
							totalItems={total}
							onPageIndexChange={(newPage) => dispatch({ type: "CHANGE_ROOMS_PAGE_INDEX", payload: newPage })}
							totalPages={totalPages}
						/>
					}
				/>
			}
		</div>
	); // return
} // RoomsBody

export default RoomsBody;