import React from "react";
import layoutStyles from './PublicLayout.module.scss';

// FIXME: if this is being imported, something should be on the Shared folder
import styles from 'pages/Views.module.scss';
import cx from "classnames";
import logo from "assets/images/logo.png";

export const PublicLayout = (props) => {
  const Component = props.component;
  const route = props.route;

  return (
    <div className={cx(layoutStyles["bg-fixed-01"])}>
      <div className={cx(styles["container-fluid"], "h-100 overflow-y")}>
        <div className="row flex-row h-100">
          <div className="col-12 my-auto">
            <div className={cx(layoutStyles["password-form"], "mx-auto")}>
              <div className={cx(layoutStyles["logo-centered"])}>
                  <a href={process.env.REACT_APP_URL_LANDING}>
                      <img src={logo} alt="Logo ESP Sailing Team" />
                  </a>
              </div>
              <Component route={route} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};