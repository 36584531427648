import { useIntl } from "react-intl";
import { Spinner } from "reactstrap";

export const ButtonBar = ({
    persisting,
    buttonSavingMessage,
    discardChanges,
    saveWithoutValidatingInventories,
    isDisabled,
    isRequestReadOnly,
    showModalForRequestRejection,
    isRejectButtonDisabled,
    askBeforeSendingEmail
}) => {

    const { formatMessage: f } = useIntl();

    const SAVING_CHANGES_LABEL = f({ id: "request.proposal.savingChanges" });

    return (
        <>
            <div className="em-separator separator-dashed" />
            <div className="d-flex align-items-center justify-content-center">
                {
                    persisting ?
                        <button
                            id="saving"
                            disabled

                            className="btn btn-info mr-1"
                            type="button">
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                className="mr-1"
                                aria-hidden="true"
                                variant="primary"
                            />
                            {buttonSavingMessage}
                        </button>
                        :
                        <>
                            <button
                                id="discard"
                                className='btn btn-warning'
                                onClick={() => discardChanges()}
                                style={{ marginRight: '1rem' }}
                            >
                                Descartar cambios
                            </button>
                            <button
                                id="save"
                                onClick={() => saveWithoutValidatingInventories()}
                                disabled={isDisabled || isRequestReadOnly()}
                                className="btn btn-primary"
                                type="button"
                            >
                                {persisting ? SAVING_CHANGES_LABEL : f({ id: 'request.proposal.saveChanges' })}
                            </button>
                            <button
                                className="btn btn-danger ml-10"
                                onClick={() => showModalForRequestRejection()}
                                disabled={isRejectButtonDisabled()}
                            >
                                Rechazar reserva
                            </button>
                            <button
                                id="send"
                                onClick={() => askBeforeSendingEmail()}
                                disabled={isDisabled}
                                className="btn btn-info ml-10"
                                type="button">
                                {
                                    persisting
                                        ?
                                        f({ id: 'request.proposal.sendingEmail' })
                                        :
                                        "Aceptar reserva y enviar email al usuario"
                                }
                            </button>
                        </>
                } {/* persisting */}
            </div>
        </>
    );
} // ButtonBar