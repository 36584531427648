import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';
import { ListItemIcon, ListItemText } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import logoRfev from 'assets/images/logo-2.png';
import { DesktopMenu } from 'components/Shared/Menu';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "state";
// import { useGetDefinitionsQuery } from 'state/api/definitionApi';
import { loggedUserApi } from 'state/api/loggedUserApi';
import { actions } from "state/auth";
import { logout } from 'state/auth/actions';

function getRole(role) {
  if (role === "ROLE_USER") {
    return "Usuario";
  } else if (role === "ROLE_ADMIN") {
    return "Administrador";
  } else {
    return "Rol no reconocido";
  }
}

const UserHeader = ({ firstName, lastName, role }) => {
  let initials = "";
  if (firstName && lastName) {
    // Gets the first letter from the name and the surname
    if (lastName) {
      initials = (firstName[0] + lastName[0]).toUpperCase();
    }
    // No lastname, get the first two letters from the name
    else {
      initials = (firstName[0] + firstName[1]).toUpperCase();
    }
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px', cursor: 'pointer' }}>
      <Avatar sx={{ backgroundColor: '#00ABC8', fontWeight: '500', fontSize: '.9rem' }}>{initials}</Avatar>
      <Box sx={{ fontSize: '.8rem', fontWeight: '500' }}>
        <Box sx={{ fontWeight: 600 }}>{`${firstName} ${lastName}`}</Box>
        <Box sx={{ color: '#5b6b79', fontWeight: 400 }}>{`${getRole(role)}`}</Box>
      </Box>
    </Box>
  );
};

const UserButton = ({ firstName, lastName }) => {
  let initials = "";

  if (firstName && lastName) {

    // TODO
    // Gets the first letter from the name and the surname
    if (lastName) {
      initials = (firstName[0] + lastName[0]).toUpperCase();
    }
    // No last name, it gets the first two letters from the name
    else {
      initials = (firstName[0] + firstName[1]).toUpperCase();
    }
  }

  return (
    <div className="user-header">
      <Avatar sx={{ backgroundColor: '#00ABC8', height: '40px', fontWeight: '500', fontSize: '.9rem' }}>{initials}</Avatar>
    </div>
  );
};

const menuData = () => {
  const menuItemsData = [
    {
      title: "Reservas",
      url: "/calendar",
    },
    {
      title: "Gestión de material",
      submenu: [
        {
          title: "Material",
          submenu: [
            {
              title: "Embarcaciones",
              url: "/boats"
            },
            {
              title: "Remolques",
              url: "/trailers"
            },
            {
              title: "Furgonetas",
              url: "/vans"
            },
          ]
        },
        {
          title: 'Habitaciones',
          url: '/rooms'
        }
      ],
    },
    {
      title: "Gestión de solicitudes",
      submenu: [
        {
          title: "Listado de pedidos",
          url: "/requests"
        },
      ]
    },
    {
      title: "Administración",
      submenu: [
        {
          title: "Usuarios",
          url: "/users"
        },
      ]
    }
  ];

  return menuItemsData;
}

export const NavBar = () => {

  // TODO: there has to be a better way to do this
  let { me } = useSelector('auth');
  if (!me) {
    me = JSON.parse(localStorage.getItem('me'));
  } else {
    me = JSON.parse(JSON.stringify(me));
  }

  const [anchorElementNav, setAnchorElementNav] = useState(null);
  const [anchorElementUser, setAnchorElementUser] = useState(null);
  const { formatMessage: f } = useIntl();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOpenUserMenu = (event) => {
    setAnchorElementUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElementNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElementUser(null);
  };

  // En la función que maneja el change password
  const handleChangePassword = () => {
    navigate("/change-password");
  }

  const handleLogout = () => {
    logout(dispatch); // Cleans slices

    // Resets RTK API state
    actions.logout(dispatch, navigate);
    dispatch(loggedUserApi.util.resetApiState());
    navigate("/login");
  };

  return (
    <AppBar position="fixed" elevation={0} sx={{ backgroundColor: '#FFF', height: '80px', boxShadow: 5, zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Container maxWidth="xxl">
        <Toolbar disableGutters>
          <Typography
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img src={logoRfev} alt="Logo RPA Invoice" height="80" />
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', paddingRight: '1.2rem', borderRight: '.5px solid #ececec' }}>
            <Box sx={{
              fontSize: '.9rem', color: '#FF8300', maxWidth: '150px',
              textOverflow: 'ellipsis',
              textWrap: 'nowrap',
              overflow: 'hidden', letterSpacing: '1px', fontWeight: 500
            }}>
              {me && me.client}
            </Box>
          </Box>
          <DesktopMenu menuItemsData={menuData()} />
          <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElementNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElementNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
            </Menu>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img src={logoRfev} alt="Logo" height="60" />
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>

          </Box>
          <Box sx={{ flexGrow: 0 }} onMouseEnter={handleOpenUserMenu}
            onMouseLeave={handleCloseUserMenu}
          >
            {me &&
              <UserButton
                firstName={me.name}
                lastName={me.surname}
              />
            }

            <Menu
              id="menu-appbar"
              anchorEl={anchorElementUser}
              keepMounted
              open={Boolean(anchorElementUser)}
              onClose={handleCloseUserMenu}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', minWidth: '19rem', padding: '.5rem 1rem', marginBottom: '8px', borderBottom: '.5px solid #ececec' }}>
                {me && <UserHeader firstName={me.name} lastName={me.surname} role={me.role} />}
              </Box>

              <MenuItem key={f({ id: 'app.changePassword' })} onClick={handleChangePassword} sx={{ backgroundColor: 'white', padding: '12px 16px' }}>
                <ListItemIcon>
                  <LockOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText sx={{
                  '& .MuiTypography-root': {
                    fontSize: '.875rem'
                  }
                }}>  {f({ id: 'app.changePassword' })}</ListItemText>

              </MenuItem>
              <MenuItem key={f({ id: 'app.logout' })} onClick={handleLogout} sx={{ backgroundColor: 'white', padding: '12px 16px' }}>
                <ListItemIcon>
                  <PowerSettingsNewOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText sx={{
                  '& .MuiTypography-root': {
                    fontSize: '.875rem'
                  }
                }}>  {f({ id: 'app.logout' })}</ListItemText>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  ); // return
} // NavBar
