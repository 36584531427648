import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { matchPath, useLocation } from "react-router-dom";

import cx from "classnames";
import { privateRoutes } from "config/routes.js";
import styles from "./PageHeader.module.css";

export const PageHeader = ({ title }) => {
    const { formatMessage: f } = useIntl();
    const location = useLocation();

    const [breadcrumbItems, setBreadcrumbItems] = useState([]);

    useEffect(() => {
        buildBreadcrumb(location, [...breadcrumbItems]);
    }, []);


    const buildBreadcrumb = (currentLocation, breadcrumbItemsCopy) => {
        const currentRoute = getCurrentRoute(currentLocation);
        const currentLocationIsActive = isActive(currentLocation);

        if (currentRoute) {
            breadcrumbItemsCopy.unshift(currentLocationIsActive ?
                <li key={currentRoute.title} className="breadcrumb-item active">
                    {f({ id: currentRoute.title })}
                </li>
                :
                <li key={currentRoute.title} className="breadcrumb-item">
                    <a href={currentRoute.path}>
                        {f({ id: currentRoute.title })}
                    </a>
                </li>
            ); // unshift

            if (currentRoute.previousPath) {
                const previousRouteIndex = getPreviousPathObject(currentRoute.previousPath);
                buildBreadcrumb(privateRoutes[previousRouteIndex], breadcrumbItemsCopy);
            } else {
                // home
                breadcrumbItemsCopy.unshift(
                    <li key="go-home" className={cx("breadcrumb-item", currentLocationIsActive && "active")}>
                        <a href="/">
                            <i className="las la-home" />
                        </a>
                    </li>
                ); // unshift
                setBreadcrumbItems(breadcrumbItemsCopy);
            } // else
        } // if 
    } // buildBreadcrumb

    const isActive = (currentLocation) => {
        return location === currentLocation;
    }

    // TODO: no returns inside if statements!
    const getCurrentRoute = (currentLocation) => {
        return privateRoutes.find((privateRoute) => {
            if (currentLocation?.pathname) {
                return matchPath(
                    {
                        path: privateRoute.path,
                        end: true
                    }, currentLocation?.pathname);
            } else {
                return currentLocation?.path === privateRoute.path;
            }
        });
    }

    const getPreviousPathObject = (pathToFind) => {
        return privateRoutes.map((privateRoute) => privateRoute.path).indexOf(pathToFind);
    }

    return (
        <div className="d-flex justify-content-between">
            <div className={cx(styles["page-header"])}>
                <div className="d-flex align-items-center">
                    <h2 className={"page-header-title"}>{title}</h2>
                </div>
            </div>
            {breadcrumbItems && breadcrumbItems.length > 0 ?
                <div>
                    <ul className="breadcrumb">
                        {breadcrumbItems}
                    </ul>
                </div>
                :
                null
            }
        </div>
    );

};