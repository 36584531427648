export const initialTrailerTypesState = {
  selectedTab: 0,
  trailerTypes: [],
  member: null,
  loading: false,
  deleting: false,
  deleted: false,
  errorDeleting: false,
  trailerType: { materialType: 'TRAILER' },
  saved: false,
  errorPersisting: false,
  trailerInventories: [],
  trailerTypesEnabled: [],
  trailerInventory: {},
  persisting: false,
  trailerTypesPageIndex: 1,
  trailerTypesTotal: 0,
  trailerTypesTotalPages: 0,
  trailerInventoryPageIndex: 1,
  trailerInventoryTotal: 0,
  trailerInventoryTotalPages: 0,
  trailerHistoryPageIndex: 1,
  trailerHistoryTotal: 0,
  trailerHistoryTotalPages: 0,
  trailerInventoryHistory: []
};

export const trailerTypesReducer = (initialState = initialTrailerTypesState, action) => {
  switch (action.type) {

    case "SET_SELECTED_TAB":
      return { ...initialState, selectedTab: action.payload };

    case "GET_TRAILER_TYPES_CRITERIA":
      return { ...initialState, trailerTypes: [], loading: true, trailerType: { materialType: 'TRAILER' } };

    case "GET_TRAILER_TYPES_CRITERIA_SUCCESS":
      return {
        ...initialState,
        loading: false,
        trailerTypes: action.payload.content,
        trailerTypesTotal: action.payload.totalElements,
        trailerTypesTotalPages: action.payload.totalPages,
      };

    case "GET_TRAILER_TYPES_CRITERIA_FAILURE":
      return { ...initialState, trailerTypes: [], loading: false };

    case "PERSIST_TRAILER_TYPE":
      return { ...initialState, persisting: true, saved: false, errorPersisting: false };

    case "PERSIST_TRAILER_TYPE_SUCCESS":
      return { ...initialState, persisting: false, saved: true, errorPersisting: false };

    case "PERSIST_TRAILER_TYPE_FAILURE":
      return { ...initialState, persisting: false, saved: false, errorPersisting: true };

    case "RESET_SAVED":
      return { ...initialState, saved: false };

    case "RESET_ERROR":
      return { ...initialState, saved: false, errorPersisting: false };

    case "GET_TRAILER_TYPE":
      return { ...initialState, loading: true };

    case "GET_TRAILER_TYPE_SUCCESS":
      return {
        ...initialState,
        trailerType: action.payload,
        loading: false,
      };

    case "GET_TRAILER_TYPE_FAILURE":
      return { ...initialState, trailerType: {}, loading: false };

    case "GET_TRAILER_INVENTORIES_CRITERIA":
      return {
        ...initialState,
        trailerInventories: [],
        loading: true,
        trailerInventory: {}
      };

    case "GET_TRAILER_INVENTORIES_CRITERIA_SUCCESS":
      return {
        ...initialState,
        loading: false,
        trailerInventories: action.payload.content,
        trailerInventoryTotal: action.payload.totalElements,
        trailerInventoryTotalPages: action.payload.totalPages,
      };

    case "GET_TRAILER_INVENTORIES_CRITERIA_FAILURE":
      return { ...initialState, trailerInventories: [], loading: false };

    case "PERSIST_TRAILER_INVENTORY":
      return { ...initialState, persisting: true, saved: false, errorPersisting: false };

    case "PERSIST_TRAILER_INVENTORY_SUCCESS":
      return { ...initialState, persisting: false, saved: true, errorPersisting: false };

    case "PERSIST_TRAILER_INVENTORY_FAILURE":
      return { ...initialState, persisting: false, saved: false, errorPersisting: true };

    case "GET_TRAILER_TYPES_ENABLED":
      return { ...initialState, trailerTypesEnabled: [], loading: true };

    case "GET_TRAILER_TYPES_ENABLED_SUCCESS":
      return { ...initialState, trailerTypesEnabled: action.payload, loading: false };

    case "GET_TRAILER_TYPES_ENABLED_FAILURE":
      return { ...initialState, trailerTypesEnabled: [], loading: false };

    case "GET_TRAILER_INVENTORY":
      return { ...initialState, loading: true };

    case "GET_TRAILER_INVENTORY_SUCCESS":
      return {
        ...initialState,
        trailerInventory: { ...action.payload, materialId: action.payload.material.id.toString() },
        loading: false,
      };

    case "GET_TRAILER_INVENTORY_FAILURE":
      return { ...initialState, trailerInventory: {}, loading: false };

    case "CHANGE_TRAILER_TYPES_PAGE_INDEX":
      return { ...initialState, trailerTypesPageIndex: action.payload };

    case "CHANGE_TRAILER_INVENTORY_PAGE_INDEX":
      return { ...initialState, trailerInventoryPageIndex: action.payload };


    case "CHANGE_TRAILER_HISTORY_PAGE_INDEX":
      return { ...initialState, trailerHistoryPageIndex: action.payload };

    case "SET_TRAILER_INVENTORY_HISTORY":
      return {
        ...initialState,
        trailerInventoryHistory: action.payload.content,
        trailerHistoryTotal: action.payload.totalElements,
        trailerHistoryTotalPages: action.payload.totalPages,
      };

    case "DELETE_TRAILER_TYPE":
    case "DELETE_TRAILER_INVENTORY":
      return { ...initialState, deleting: true };

    case "DELETE_TRAILER_TYPE_SUCCESS":
    case "DELETE_TRAILER_INVENTORY_SUCCESS":
      return { ...initialState, deleting: false, deleted: true };

    case "DELETE_TRAILER_TYPE_FAILURE":
    case "DELETE_TRAILER_INVENTORY_FAILURE":
      return { ...initialState, deleting: false };

    case "RESET_DELETED":
      return { ...initialState, deleted: false };

    case "GET_TRAILER_INVENTORY_MAINTENANCE_HISTORY":
      return { ...initialState, loading: true };

    case "GET_TRAILER_INVENTORY_MAINTENANCE_HISTORY_SUCCESS":
      return {
        ...initialState,
        trailerMaintenanceHistory: action.payload,
        loading: false,
      };

    case "GET_TRAILER_INVENTORY_MAINTENANCE_HISTORY_FAILURE":
      return { ...initialState, trailerMaintenanceHistory: [], loading: false };

    default:
      return { ...initialState };
  };
};

