import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "state";
import { getUser } from "state/users";
import cx from 'classnames';
import styles from 'pages/Views.module.scss';
import { PageHeader } from 'components/PageHeader';
import { useIntl } from "react-intl";
import UserForm from './form'
import Loading from "components/Loading/Loading";

const EditUser = () => {
  const { loading, user } = useSelector("users");
  const { id } = useParams("id");
  const dispatch = useDispatch();
  const { formatMessage: f } = useIntl();

  useEffect(() => {
    const getUserData = async () => {
      await getUser(dispatch, id);
    };
    getUserData();
  }, []);

  return (
    <div>
      <PageHeader title={f({id:"users.title"})} />

      <div className={cx(styles["widget"])}>
        <div className={cx(styles["widget-header"], styles["bordered"], styles["no-actions"], "d-flex align-items-center")}>
          <h4>{f({ id: 'users.edit.title' })}</h4>
        </div>

        <div className={cx(styles["widget-body"])}>
          {loading || !user.id ?
            <Loading />
            :
            <UserForm />
          }
        </div>
      </div>
    </div>

  );
};

export default EditUser;
