import {
    fetchBaseQuery
} from '@reduxjs/toolkit/query';
// import { Mutex } from 'async-mutex';

const baseUrl = `${process.env.REACT_APP_API_URL}`;

// Create a new mutex
const mutex = {}

const baseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.accessToken;
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        } // if
    } // prepareHeaders
}); // baseQuery

const customFetchBase = async (args, api, extraOptions) => {
    // wait until the mutex is available without locking it
    await mutex.waitForUnlock();
    let result = await baseQuery(args, api, extraOptions);
    if (result.error?.status === 401) {
        if (!mutex.isLocked()) {
            const release = await mutex.acquire();

            try {
                const refreshResult = await baseQuery(
                    { credentials: 'include', url: 'auth/refresh-token' },
                    api,
                    extraOptions
                );

                if (refreshResult.data) {
                    // Retry the initial query
                    result = await baseQuery(args, api, extraOptions);
                } else {
                    // api.dispatch(logout());
                    window.location.href = '/login';
                }
            } finally {
                // release must be called once the mutex should be released again.
                release();
            } // try-finally
        } else {
            // wait until the mutex is available without locking it
            await mutex.waitForUnlock();
            result = await baseQuery(args, api, extraOptions);
        } // if-else mutex
    } // if 401

    return result;
}; // customFetchBase

export default customFetchBase;
