import cx from 'classnames';
import { DatePickerInput } from 'components/Shared/DatePickerInput';
import styles from 'pages/Views.module.scss';
import { useState } from 'react';
import DatePicker from "react-multi-date-picker";
import { Col } from "reactstrap";
import { useDispatch } from 'state';
import { getTodaysDate } from 'utils/dateUtils';
import { months, weekDays } from "utils/enums";

export const BedProposalDates = ({
    request,
    disabled,
    setRequest
}) => {

    const dispatch = useDispatch();

    const [values, setValues] = useState(
        request.roomCheckInDate ?
            [new Date(request.roomCheckInDate), new Date(request.roomCheckOutDate)]
            :
            []
    ) // useState

    const setDates = (dates) => {
        setValues(dates);

        dates.forEach((date, index) => {
            if (index === 0) {
                setRequest((prevRequest) => ({
                    ...prevRequest,
                    roomCheckInDate: new Date(date).setHours(0, 0, 0, 0),
                    roomCheckOutDate: null
                }));
            } else {
                setRequest((prevRequest) => ({
                    ...prevRequest,
                    roomCheckOutDate: new Date(date).setHours(23, 59, 59, 999)
                }));
            }
        }); // forEach
        dispatch({ type: 'ROOM_DATES_HAVE_CHANGED', payload: true });
    } // setDates

    return request.roomNumberOfBeds > 0 && (

        <div className={cx(styles["widget-body"], styles["min-height-0"])}>
            <div id="beds">
                <div className="widget-header bordered no-actions d-flex align-items-center">
                    <div className="section-title my-3 pl-3">
                        <h4>Fechas de alojamiento</h4>
                    </div>
                </div>
                <div className={cx(styles["widget-body"], styles["min-height-0"])}>
                    <div className="form-group row d-flex align-items-center">
                        <label className="col-lg-3 form-control-label">Entrada - Salida</label>
                        <Col xs="6">
                            <div className="form-group">
                                <DatePicker
                                    value={values}
                                    weekDays={weekDays}
                                    months={months}
                                    range
                                    render={<DatePickerInput />}
                                    minDate={getTodaysDate()}
                                    containerClassName="d-block"
                                    format={"DD/MM/YYYY"}
                                    disabled={disabled}
                                    onChange={dateObjects => {
                                        setDates(dateObjects)
                                    }} />
                            </div>
                        </Col>

                    </div>
                </div>
            </div>
        </div>
    );
}