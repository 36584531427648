import { TextField as MuiTextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

/**
* Custom text TextField for app forms
* 
* @param {string} name - Field name
* @param {string} label - Text field label
* @param {string} helperText - Text field helper text
* @param {TextFieldProps} otherProps - Other props from MUI TextField
* 
*/

export const FormInput = ({ name, label, helperText, defaultValue, items, ...otherProps }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue || ""}
      render={({ field }) => (
        <MuiTextField
          {...field}
          fullWidth
          label={label}
          size="small"
          value={field.value}
          InputLabelProps={{shrink: true}}
          error={!!errors[name]}
          helperText={errors[name] ? (errors[name]?.message) : helperText}
          {...otherProps}
        />
      )} // render
    />
  ); // return
}; // FormInput
