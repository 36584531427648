export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';

export const SET_PAGE_INDEX = 'SET_PAGE_INDEX';

export const PERSIST_USER = 'PERSIST_USER';
export const PERSIST_USER_SUCCESS = 'PERSIST_USER_SUCCESS';
export const PERSIST_USER_FAILURE = 'PERSIST_USER_FAILURE';

export const RESET_SAVED = 'RESET_SAVED';
export const RESET_ERROR = 'RESET_ERROR';

export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const RESEND_EMAIL = 'RESEND_EMAIL';
export const RESEND_EMAIL_SUCCESS = 'RESEND_EMAIL_SUCCESS';
export const RESEND_EMAIL_FAILURE = 'RESEND_EMAIL_FAILURE';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';
export const RESET_DELETED = 'RESET_DELETED';
