import React, { useEffect, useState } from "react";
import { useDispatch } from 'state';
import { actions } from "state/auth";
import LoginForm from "./LoginForm";
import RecoverPasswordForm from "./RecoverPasswordForm";

export const LoginScreen = () => {
  const dispatch = useDispatch();

  const [showRecoverPasswordForm, setShowRecoverPasswordForm] = useState(false);

  useEffect(() => {
    actions.reset(dispatch);
  }, [showRecoverPasswordForm]);


  return (
    !showRecoverPasswordForm ?
      <LoginForm setShowRecoverPasswordForm={setShowRecoverPasswordForm} />
      :
      <RecoverPasswordForm setShowRecoverPasswordForm={setShowRecoverPasswordForm} />
  ); // return
}; // LoginScreen