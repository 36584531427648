import { ModifyRequest } from './ModifyRequest';
import { RequestInvoice } from './RequestInvoice/RequestInvoice';

export const EditRequestContainer = ({
    request,
}) => {;


    return (
        <div className="row">

            {/* Left side */}
            <RequestInvoice
                request={request} />

            {/* Right side */}
            <ModifyRequest />
        </div>
    ); // return
} // EditRequestContainer