import cx from 'classnames';
import { ConfirmationModal } from 'components/Shared/ConfirmationModal';
import styles from 'pages/Views.module.scss';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { NotificationManager } from 'react-notifications';
import { useDispatch, useSelector } from 'state';
import { getAvailableInventories } from 'state/inventories/actions';
import { getRequestById } from 'state/requests';
import { getInventoryRentingsByRequestId, persistModifiedRequest, rejectRequestApi } from 'state/requests/actions';
import { getAllRooms } from 'state/rooms';
import { getAvailableRoomsFromRequest } from 'state/rooms/actions';
import { verifyIfObjectIsNullish } from 'utils/utils';
import { ButtonBar } from '../RequestInvoice/ButtonBar';
import { MaterialSelection } from './MaterialSelection';
import { RoomSelection } from './RoomSelection';

export const ModifyRequest = () => {

    const { formatMessage: f } = useIntl();

    const {
        loading,
        saved,
        errorPersisting,
        persisting,
        guests,
        materialDatesHaveChanged,
        rentingsForRequest,
        request
    } = useSelector("requests");


    const {
        roomsWithOccupation,
        availableRoomsByGuest
    } = useSelector("rooms");

    const dispatch = useDispatch();

    const modifiedGuests = [...guests];

    const SAVING_CHANGES_LABEL = f({ id: "request.proposal.savingChanges" });
    const SENDING_EMAIL_LABEL = f({ id: "request.proposal.sendingEmail" });
    const SAVED_CHANGES = "Cambios guardados";

    // Modified request
    const [modifiedRequest, setModifiedRequest] = useState({ ...request });

    // Inventory-selection-related
    const [newAddedInventories, setNewAddedInventories] = useState({});

    // Validation
    const [invalidGuests, setInvalidGuests] = useState([]);

    // Modal
    const [modalConfirmAction, setModalConfirmAction] = useState(() => () => { });
    const [modalHeaderMessage, setModalHeaderMessage] = useState("");
    const [modalMessage, setModalMessage] = useState("");

    // Validation
    const [materialsWithNullInventory, setMaterialsWithNullInventory] = useState([]);

    // Material addition
    const [newMaterials, setNewMaterials] = useState([]);

    // Buttons and modals
    const [buttonSavingMessage, setButtonSavingMessage] = useState(SAVING_CHANGES_LABEL);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState(SAVED_CHANGES);

    /**
     * Checks if the request is cancelled.
     * @returns {boolean} true if the request is cancelled, false otherwise.
     */
    const isRejectButtonDisabled = () => {
        return request.status === "CANCELLED";
    }

    /**
    * Initialises the inventory select component.
    * 
    * Specifically done like this due to strange re-rendering issues.
    * which would set the initial map correctly, but not the useState variable.
    */
    const initInventorySelection = () => {

        const initialMap = {};

        // Rentings don't exist, so the requested materials are assigned
        if (rentingsForRequest.length === 0) {
            request.materialRequest.forEach(materialObject => {
                initialMap[materialObject.material.id] = null;
            }); // forEach
        } else {
            // Rentings exist, so they're assigned instead of the requested materials
            rentingsForRequest.forEach(renting => {
                const inventory = renting.inventory;
                const materialId = inventory.material.id;

                initialMap[materialId] = inventory.id;
            }); // forEach
        } // else

        return initialMap;
    } // initInventorySelection

    const [chosenInventoriesForMaterials, setChosenInventoriesForMaterials] = useState(initInventorySelection());

    /**
     * Checks if the request is accepted or cancelled
     * @returns true if the request is accepted or cancelled, false otherwise
    */
    const isRequestReadOnly = () => {
        return request.status === "ACCEPTED" || request.status === "CANCELLED";
    }

    /**
     * Checks if any of the material dates are selected
     * @returns true if any of the dates are not set, false otherwise
     */
    const getDisabledButton = () => {
        let materialDatesAreNotComplete = false;
        let materialPlacesAreNotComplete = false;
        let roomDatesAreNotComplete = false;

        /**
         * Checks if bed dates are valid only if there are materials to be requested
         * or new materials have been added
         */
        if (newMaterials.length > 0 || request.materialRequest.length > 0) {
            materialDatesAreNotComplete = (
                verifyIfObjectIsNullish(modifiedRequest.materialCollectionDate)
                ||
                verifyIfObjectIsNullish(modifiedRequest.materialReturnDate)
            ); // materialDatesAreNotComplete
            materialPlacesAreNotComplete = (
                verifyIfObjectIsNullish(modifiedRequest.materialCollectionPlace)
                ||
                verifyIfObjectIsNullish(modifiedRequest.materialReturnPlace)
            ); // materialPlacesAreNotComplete
        } // if

        // Checks if bed dates are valid only if there is a bed request
        if (request.roomNumberOfBeds) {
            roomDatesAreNotComplete = (
                verifyIfObjectIsNullish(modifiedRequest.roomCheckInDate)
                ||
                verifyIfObjectIsNullish(modifiedRequest.roomCheckOutDate)
            ); // roomDatesAreNotComplete
        } // if

        return materialDatesAreNotComplete || materialPlacesAreNotComplete || roomDatesAreNotComplete;
    } // getDisabledButton

    /**
     * Checks that all inventory select components are filled up.
     * @param {boolean} sendEmail - Whether the request is to be accepted or not.
     * @returns true if all inventory selections are filled up, false otherwise.
    */
    const validateInventories = (sendEmail) => {
        let invalidMaterialTypes = [];

        Object.keys(chosenInventoriesForMaterials).forEach(materialId => {
            // sendEmail makes it only check it if the accept button is clicked
            if (!chosenInventoriesForMaterials[materialId] && sendEmail) {
                invalidMaterialTypes.push(parseInt(materialId));
            } // if
        }) // map

        setMaterialsWithNullInventory(invalidMaterialTypes);

        return invalidMaterialTypes.length === 0;
    } // validateInventories

    /**
     * Rejects the request.
     */
    const rejectRequest = () => {
        const rejectRequestFunction = async () => {
            const id = request.id;
            await rejectRequestApi(dispatch, id);

            // Refreshes the component
            await getRequestById(dispatch, id);
        } // rejectRequestFunction
        rejectRequestFunction();
    } // rejectRequest

    /**
     * Shows a confirmation modal for rejecting a request.
     */
    const showModalForRequestRejection = () => {
        setShowConfirmationModal(true);
        setModalHeaderMessage(f({ id: 'request.reject.title' }));
        setModalMessage(f({ id: 'request.reject.message' }));
        setModalConfirmAction(() => rejectRequest);
    } // showModalForRequestRejection

    /**
     * Saves the request.
     * @param {boolean} sendEmail - Whether the request is to be accepted or not.
     */
    const persistChanges = async (sendEmail) => {
        let savingButtonMessage = SAVED_CHANGES;
        let successMessage = SAVING_CHANGES_LABEL;

        const dataToSave = getValuesToSave(modifiedRequest);

        dataToSave.guests = modifiedGuests;

        await persistModifiedRequest(dispatch, dataToSave, sendEmail);
        await getInventoryRentingsByRequestId(dispatch, request.id);

        // Refreshes the component values
        await getRequestById(dispatch, dataToSave.id);

        if (sendEmail) {
            savingButtonMessage = SENDING_EMAIL_LABEL;
            successMessage = 'Cambios guardados y correo enviado';
        } // if

        setButtonSavingMessage(savingButtonMessage);
        setSuccessMessage(successMessage);
        setShowConfirmationModal(false);
    } // persistChanges

    /**
     * Returns a guest given its ID.
     * @param {number} guestId - The guest's ID.
     * @returns the found guest if it exists, null otherwise.
    */
    const findGuest = (guestId) => {
        return guests.find(guest => guest.id === guestId);
    } // findGuest

    /**
     * Validates the selected room for each guest in the select component.
     * @returns true if there are no invalid guests, false otherwise.
     */
    const validateGuestRooms = (sendEmail) => {
        let guestsForEachRoom = new Map();
        let invalidGuestsArray = [];

        // Loops the guests with the new information
        modifiedGuests.forEach(modifiedGuest => {
            const roomId = modifiedGuest.room;
            const guestId = modifiedGuest.id;
            const room = roomsWithOccupation.find(room => room.id === roomId);

            if (!room && sendEmail) {
                // The room is null, so the guest is automatically invalid 
                // (only in case that the request is accepted and not only saved)
                invalidGuestsArray.push(guestId);
            } else if (room) {
                // Gets the guests in the map for that room
                const guestIdsInRoom = guestsForEachRoom.get(roomId) || [];
                if (guestIdsInRoom.length === 0 && roomId) {
                    // The room doesn't exist yet in the map; it's created and the guest is added 
                    guestsForEachRoom.set(roomId, [guestId]);
                } else {
                    // The room already exists in the map
                    let guestCanBeAddedToRoom = true;
                    guestIdsInRoom.forEach(guestIdInRoom => {
                        const guestInRoom = findGuest(guestIdInRoom);
                        // The guest cannot be assigned to that room as it is:
                        // - an opposite-gender room OR
                        // - already full
                        if (guestInRoom.gender !== modifiedGuest.gender || room?.emptyBeds - guestIdsInRoom.length === 0) {
                            guestCanBeAddedToRoom = false;
                        } // if

                    }); // forEach

                    if (guestCanBeAddedToRoom) {
                        // Updates map
                        guestIdsInRoom.push(guestId);
                        guestsForEachRoom.set(roomId, guestIdsInRoom);
                    } else {
                        // Marks guest for invalid label
                        invalidGuestsArray.push(guestId);
                    } // if-else canBeAdded
                } // if-else guestsInRoom
            } // if room
        }); // forEach

        setInvalidGuests(invalidGuestsArray);

        return invalidGuestsArray.length === 0;
    } // validateGuestRooms


    /**
     * Changes a guest's room.
     * @param {number} guestId - The guest's ID.
     * @param {number} roomId - The new room's ID.
    */
    const changeGuestRoom = (guestId, roomId) => {
        const modifiedIndex = modifiedGuests.findIndex(guest => guest.id === guestId);

        if (modifiedIndex !== -1) {
            modifiedGuests[modifiedIndex].room = roomId;
        } // if

        dispatch({ type: 'ROOM_DATES_HAVE_CHANGED', payload: false });
        setInvalidGuests([]);
    } // changeGuestRoom


    /**
     * Validates guests and inventories, and shows the modal if everything's correct.
    */
    const askBeforeSendingEmail = () => {
        setModalHeaderMessage(f({ id: "request.sendEmail.title" }));
        setModalMessage(f({ id: "request.sendEmail.message" },
            {
                additionalInfo: "Esta acción también implica el cambio de estado de la solicitud."
            }));
        setModalConfirmAction(() => () => persistChanges(true));
        const allGuestsAreValid = validateGuestRooms(true);
        const allInventoriesAreChosen = validateInventories(true);

        if (allGuestsAreValid && allInventoriesAreChosen) {
            setShowConfirmationModal(true);
        } // if
    } // askBeforeSendingEmail

    /**
     * Saves the request after validating the guests.
     */
    const saveWithoutValidatingInventories = () => {
        if (validateGuestRooms()) {
            persistChanges(false);
        } // if
    } // saveWithoutValidatingInventories

    /**
     * Refreshes the page without saving the changes.
     */
    const discardChanges = () => {
        const refresh = async () => {
            await getRequestById(dispatch, request.id);
        } // refresh

        refresh();
    } // discardChanges

    /**
     * Assigns the offered inventories to the modified requests.
     */
    const getValuesToSave = () => {
        let selectedInventories = [];
        Object.values(chosenInventoriesForMaterials).forEach(inventoryId => {
            if (inventoryId) {
                selectedInventories.push(inventoryId);
            }
        }); // forEach

        Object.values(newAddedInventories).forEach(newAddedInventories => {
            selectedInventories = selectedInventories.concat(newAddedInventories);
        }); // forEach

        modifiedRequest.offeredInventories = selectedInventories;
        modifiedRequest.materialCollectionDate = new Date(modifiedRequest.materialCollectionDate);

        const materialReturnDate = new Date(modifiedRequest.materialReturnDate);
        materialReturnDate.setHours(23, 59, 59, 59);
        modifiedRequest.materialReturnDate = new Date(materialReturnDate);

        modifiedRequest.roomCheckInDate =
            verifyIfObjectIsNullish(modifiedRequest.roomCheckInDate)
                ?
                modifiedRequest.roomCheckInDate
                :
                new Date(modifiedRequest.roomCheckInDate);
        modifiedRequest.roomCheckOutDate =
            verifyIfObjectIsNullish(modifiedRequest.roomCheckOutDate)
                ?
                modifiedRequest.roomCheckOutDate
                :
                new Date(modifiedRequest.roomCheckOutDate);

        return modifiedRequest;
    } // getValuesToSave

    /**
     * Fetches the rooms and the available rooms for each request
     * every time the component is rendered.
     */
    useEffect(() => {
        const getRoomInformation = async () => {
            getAllRooms(dispatch, 0, 100);
            if (request.id && request.roomNumberOfBeds && (request.roomCheckInDate || request.roomCheckOutDate)) {
                getAvailableRoomsFromRequest(dispatch, request);
            } // if
        } // getRoomInformation

        getRoomInformation();
        setMaterialsWithNullInventory([]);
        dispatch({ type: 'ROOM_DATES_HAVE_CHANGED', payload: false });
    }, []);

    /**
     * Handles the notification message when persisting the request.
     */
    useEffect(() => {
        setShowConfirmationModal(false);
        if (saved) {
            NotificationManager.success(successMessage);
            dispatch({ type: "RESET_SAVED" });
        } else if (errorPersisting) {
            NotificationManager.error('Se ha producido un error');
            dispatch({ type: "RESET_ERROR" });
        }
    }, [saved, errorPersisting]);

    /**
     * Updates the inventories when changing any material dates.
     */
    useEffect(() => {
        const getInventoriesFunction = async () => {

            await getAvailableInventories(dispatch, modifiedRequest);

            // Updates the map
            if (materialDatesHaveChanged) {
                let selectedMaterialAndInventories = { ...chosenInventoriesForMaterials };
                for (let materialId in selectedMaterialAndInventories) {
                    selectedMaterialAndInventories[materialId] = null;
                } // for

                setChosenInventoriesForMaterials(selectedMaterialAndInventories);
            } // if
            dispatch({ type: 'MATERIAL_DATES_HAVE_CHANGED', payload: false });
        } // getInventoriesFunction

        getInventoriesFunction();
    }, [materialDatesHaveChanged]);

    const isDisabled = persisting || isRequestReadOnly() || loading || getDisabledButton();

    /**
     * Removes the room of each guest and fetches the available rooms whenever the checkout date is valid.
     */
    useEffect(() => {

        modifiedGuests.forEach(guest => {
            guest.room = null;
        });

        if (modifiedRequest.roomCheckOutDate) {
            getAvailableRoomsFromRequest(dispatch, modifiedRequest);
        }

    }, [modifiedRequest.roomCheckInDate]);

    return (
        <div className="col-lg-12 col-xl-6 col-sm-6 col-xs-12 margin-top-card">
            <div className={cx(styles["widget"], styles["overflow-scroll"], "has-shadow", "w-100")}>
                <div className="widget-header bordered no-actions d-flex align-items-center">
                    <div className="section-title my-3 pl-3">
                        <h4>Material disponible</h4>
                    </div>
                </div>

                <ConfirmationModal
                    showModal={showConfirmationModal}
                    onToggleClick={() => setShowConfirmationModal(false)}
                    header={modalHeaderMessage}
                    message={modalMessage}
                    cancelAction={() => setShowConfirmationModal(false)}
                    cancelButtonLabel={f({ id: "app.cancel" })}
                    okAction={() => modalConfirmAction()}
                    okButtonLabel={f({ id: "app.confirm" })}
                    loading={persisting}
                    loadingMessage={f({ id: "app.updating" })}
                />

                <MaterialSelection
                    modifiedRequest={modifiedRequest}
                    setModifiedRequest={setModifiedRequest}
                    setNewAddedInventories={setNewAddedInventories}
                    setNewMaterials={setNewMaterials}
                    isRequestReadOnly={isRequestReadOnly}
                    chosenInventoriesForMaterials={chosenInventoriesForMaterials}
                    setChosenInventoriesForMaterials={setChosenInventoriesForMaterials}
                    materialsWithNullInventory={materialsWithNullInventory}
                    newMaterials={newMaterials}
                    newAddedInventories={newAddedInventories}
                    setMaterialsWithNullInventory={setMaterialsWithNullInventory}
                />

                <RoomSelection
                    modifiedGuests={modifiedGuests}
                    roomsWithOccupation={roomsWithOccupation}
                    availableRoomsByGuest={availableRoomsByGuest}
                    changeGuestRoom={changeGuestRoom}
                    invalidGuests={invalidGuests}
                    isRequestReadOnly={isRequestReadOnly}
                    modifiedRequest={modifiedRequest}
                    setModifiedRequest={setModifiedRequest}
                />

                <ButtonBar
                    persisting={persisting}
                    buttonSavingMessage={buttonSavingMessage}
                    discardChanges={discardChanges}
                    saveWithoutValidatingInventories={saveWithoutValidatingInventories}
                    isDisabled={isDisabled}
                    isRequestReadOnly={isRequestReadOnly}
                    showModalForRequestRejection={showModalForRequestRejection}
                    isRejectButtonDisabled={isRejectButtonDisabled}
                    askBeforeSendingEmail={askBeforeSendingEmail}
                />
            </div>
        </div>
    );
};