import React, { Fragment, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'state';
import { useParams, Link } from "react-router-dom";
import cx from "classnames";
import loginStyles from './ResetPasswordForm.module.scss';
import { FormText, Alert } from "reactstrap";

import { useIntl } from "react-intl";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { actions } from "state/auth";
import { regularExpression } from "utils/utils";

const ResetPasswordForm = () => {
  const { formatMessage: f } = useIntl();

  const dispatch = useDispatch();
  const { token } = useParams();
  const { loading, error, ok } = useSelector('auth');

  useEffect(() => {
    actions.reset(dispatch);
  }, []);

  const recoverPasswordSchema = Yup.object().shape({
    password: Yup.string().required(f({ id: "form.errors.required" })).matches(regularExpression.PASSWORD, f({ id: "form.errors.password.strong" })),
    repeatPassword: Yup.string().oneOf([Yup.ref('password')], f({ id: "form.errors.password.match" })).required(f({ id: "form.errors.required" }))
  });

  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: "onBlur",
    resolver: yupResolver(recoverPasswordSchema)
  });

  const onSubmit = async (data) => {
    data = {...data, token: token};
    await actions.resetPassword(dispatch, data);
  }

  return (
    <Fragment>
      <h3>{ f({ id: "resetPassword.title" })}</h3>
      {error && <Alert className="mt-1" color="danger">
        {error.description}
      </Alert>}
      {ok ?
          <React.Fragment>
            <Alert className="mt-1" color="success">
              {f({id: "resetPassword.ok" })}
            </Alert>
          </React.Fragment>
        :
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className={cx(loginStyles.group, "material-input")}>
              <input
                type="password"
                name="password"
                id="password"
                {...register('password')}
                placeholder={f({id: "resetPassword.password" })}
                />
              {errors?.password && (
                <FormText color="red" className="text-danger">{errors?.password.message}</FormText>
              )}
            </div>
            <div className={cx(loginStyles.group, "material-input")}>
              <input
                type="password"
                name="repeatPassword"
                id="repeatPassword"
                {...register('repeatPassword')}
                placeholder={f({id: "resetPassword.repeatPassword" })}
                />
              {errors?.repeatPassword && (
                <FormText color="red" className="text-danger">{errors?.repeatPassword.message}</FormText>
              )}
            </div>
            <div className="button text-center">
              <button disabled={loading}  className="btn btn-primary mr-1" type="submit">{f({id: loading ? "resetPassword.submitting" : "resetPassword.submit"})}</button>
            </div>
          </form>
      }
      <div className={cx(loginStyles.back)}>
        <Link to="/login">{ok ? f({ id: "app.login" }) : f({ id: "app.back" })}</Link>
      </div>
    </Fragment>
  );
};

export default ResetPasswordForm;
