import { useIntl } from 'react-intl';


export const FilterText = (props) => {
    const {formatMessage: f} = useIntl();
    return (
        <div className="col-4 d-flex align-items-center justify-content-end">
            <label className="label-filter">{f({ id: 'app.search.filter' })}</label>
            <input
                key="search"
                type="search"
                autoFocus="autoFocus"
                className="form-control input-filter"
                placeholder=""
                aria-controls="export-table"
                onChange={a => props.criteriaChange(a.target.value)}
                value={props.criteria}
            />
        </div>
    ); // return
} // FilterText
