import { materials } from "utils/enums";
import cx from 'classnames';

const { useRef } = require("react");
const { useOutsideAlerter } = require("utils/utils");

/**
 * Hook that alerts clicks outside of the passed ref
 */
export const NewMaterialTypeForRequestContainer = (props) => {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, props.hideMaterials);
    if (!props.disabled) {
        return (
            <div className={cx(props.styles["widget-body"], props.styles["min-height-0"])}>
                <div ref={wrapperRef} className={props.showAddMaterials ? "btn-group mr-1 mb-2 show" : "btn-group mr-1 mb-2"}>
                    <button type="button" className="btn btn-primary">Añadir</button>
                    <a
                        className="btn btn-primary dropdown-toggle la la-angle-down d-flex align-items-center"
                        onClick={props.onShowAddMaterials}
                        data-toggle="dropdown"
                        aria-expanded="false"/>
                    <div
                        className={props.showAddMaterials ? "dropdown-menu show dropdown-container-items" : "dropdown-menu"}
                        x-placement="bottom-start"
                    >
                        <a className="dropdown-item"
                            onClick={() => props.onAddNewMaterial(materials.BOAT)}
                        >
                            <i className="la la-ship" />
                            Embarcación
                        </a>
                        <a className="dropdown-item"
                            onClick={() => props.onAddNewMaterial(materials.TRAILER)}
                        >
                            <i className="la la-car" /> Remolque</a>
                        <a className="dropdown-item"
                            onClick={() => props.onAddNewMaterial(materials.VAN)}
                        >
                            <i className="la la-truck" /> Furgoneta</a>
                    </div>
                </div>
            </div>
        ) // return
    } // if
    return null;
} // AddNewContainer