import { PrivateLayout } from "components/Shared/Layout/PrivateLayout/PrivateLayout.js";
import { PublicLayout } from "components/Shared/Layout/PublicLayout/PublicLayout.js";
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { useSelector } from 'state';
import "../node_modules/line-awesome/dist/line-awesome/css/line-awesome.css";
import './App.css';
import { privateRoutes, publicRoutes } from "./config/routes.js";


function App() {

  const { token } = useSelector('auth');

  return (
    <div style={{ height: '100vh' }}>
      <Router>
        <Routes>
          {privateRoutes.map((route) => {
            const { component: Component, path, render } = route;
            return (
              <Route
                exact
                path={path}
                key={path}
                element={
                  token ? <PrivateLayout component={Component} route={route} render={render} /> : <Navigate to="/login" />
                }
              />
            );
          })}
          {publicRoutes.map((route) => {
            const { component: Component, path } = route;
            return (
              <Route
                exact
                path={path}
                key={path}
                element={
                  token ? <Navigate to="/" /> : <PublicLayout component={Component} route={route} />
                }
              />
            );
          })}
          <Route exact path="/" element={<Navigate to="/calendar" />} />
        </Routes>
        <NotificationContainer />
      </Router>
    </div>
  );
}

export default App;
