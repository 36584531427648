import { createSlice } from '@reduxjs/toolkit';
import { get } from 'api/client';

export const getCities = async () => {
    const cities = await get('cities/available-cities');
    initialCitiesState.cities = cities;
  }

export const initialCitiesState = {
  cities: [],
  loading: false,
  error: undefined
} // initialCitiesState

export const citiesSlice = createSlice({
  name: 'cities',
  initialCitiesState,
  reducers: {
  }, // reducers
}) // createSlice

export const {
  updateDynamicFiltersMapAndPageAttributes,
  changeDocumentPaginationInformation,
  resetDynamicFilters,
  setEditModal,
  setClickedButton,
  setDocument,
  setDocumentDefinitionId
} = citiesSlice.actions;

export default citiesSlice.reducer;
