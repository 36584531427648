import cx from 'classnames';
import { RentHistoryTable } from "components/RentHistoryTable";
import { SearchBarAndButtonHeader } from 'components/SearchBarAndButtonHeader';
import { ConfirmationModal } from 'components/Shared/ConfirmationModal';
import { PaginationComponent } from 'components/Shared/PaginationComponent/PaginationComponent';
import { Tabs } from 'components/Shared/Tabs';
import { Table } from 'components/Table';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { NotificationManager } from 'react-notifications';
import { useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { useDispatch, useSelector } from 'state';
import { getRentingHistory } from 'state/inventories/actions';
import { deleteMaintenance, getMaintenanceHistoryForInventory } from 'state/maintenances/actions';
import { formatMaintenanceHistoryData, formatRentHistoryData, getMaintenanceHistoryHeaders } from 'utils/tableUtils';
import { PAGE_SIZE } from 'utils/utils';
import { FormContainer } from "./FormContainer";


export const EditInventoryContainer = ({
    inventory,
    inventoryType,
    styles,
    materialType,
    maintenanceHistory,
    rentHistory
}) => {

    const navigate = useNavigate();
    const { formatMessage: f } = useIntl();
    const dispatch = useDispatch();

    const { deleting, deleted, errorDeleting } = useSelector('maintenances');

    const [criteria, setCriteria] = useState(null);
    const [selectedTab, setSelectedTab] = useState(0);

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [maintenanceToDelete, setMaintenanceToDelete] = useState(undefined);

    /**
     * Prepares the deletion of a maintenance.
     * @param {Object} maintenance - The maintenance to delete.
     */
    const onDeleteClick = (maintenance) => {
        setShowConfirmationModal(true);
        setMaintenanceToDelete(maintenance);
    }

    /**
     * Cancels the deletion of a maintenace (hides modal and de-selects the maintenace to delete).
     */
    const onCancelDeleteClick = () => {
        setShowConfirmationModal(false);
        setMaintenanceToDelete(undefined);
    } // onCancelDeleteClick

    /**
     * Confirms the deletion of a maintenance.
     * @param {number} maintenanceId - The maintenance's ID.
     */
    const onConfirmDeleteClick = async (maintenanceId) => {
        await deleteMaintenance(dispatch, maintenanceId);
        setShowConfirmationModal(false);
        setCriteria(null);
    } // onConfirmDeleteClick

    /**
     * Redirects to the maintenance edition page according to its ID.
     * @param {number} id - The maintenance's ID.
     */
    const onEditMaintenance = (id) => {
        navigate("/maintenances/" + id);
    }

    /**
     * Shows the notification when a maintenance is deleted.
     */
    useEffect(() => {
        if (deleted) {
            NotificationManager.success("Mantenimiento eliminado correctamente");
            dispatch({ type: "RESET_DELETED" });
            dispatch({ type: "SET_SELECTED_TAB", payload: 1 });
            getMaintenanceHistoryForInventory(dispatch, inventory.id, maintenanceHistory.pageIndex - 1, PAGE_SIZE, criteria);

        } else if (errorDeleting) {
            NotificationManager.error("Se ha producido un error");
            dispatch({ type: "RESET_ERROR" });
        } // if-else if
    }, [deleted, errorDeleting]);


    /**
     * Fetches the maintenances which match the search-bar text whenever it changes.
     */
    useEffect(() => {
        dispatch(getMaintenanceHistoryForInventory(dispatch, inventory.id, maintenanceHistory.pageIndex - 1, PAGE_SIZE, criteria));
    }, [criteria]);

    useEffect(() => {
        getRentingHistory(dispatch, PAGE_SIZE, 0, inventory.id);
    }, [])

    return (
        <Row className="flex-row">
            <Col className='col-lg-4'>
                <FormContainer
                    styles={styles}
                    inventory={inventory}
                    materialType={materialType}
                />
            </Col>
            <Col className='col-lg-8'>
                <div className={cx(styles["widget"])}>
                    <div className={cx(styles["widget-body"])}>
                        <Tabs
                            selectedTab={selectedTab}
                            tabTitles={["Historial de alquileres", "Historial de mantenimientos"]}
                            setSelectedTab={setSelectedTab}
                        />
                        {
                            selectedTab === 0 ?

                                <RentHistoryTable
                                    cx={cx}
                                    styles={styles}
                                    columnsData={formatRentHistoryData(rentHistory?.history)}
                                    pagination={
                                        <PaginationComponent
                                            items={rentHistory?.history || []}
                                            pageIndex={rentHistory?.pageIndex}
                                            pageSize={PAGE_SIZE}
                                            totalItems={rentHistory?.totalItems}
                                            onPageIndexChange={(newPage) => rentHistory.onPageChange(newPage)}
                                            totalPages={rentHistory?.totalPages}
                                        />
                                    } // pagination
                                />

                                :
                                <div className="d-flex flex-column justify-content-between h-700px">
                                    <div>
                                        <SearchBarAndButtonHeader
                                            text={"Listado de mantenimientos"}
                                            url={'maintenances/new'}
                                            showAddButton={true}
                                            filterText
                                            criteria={criteria}
                                            criteriaChange={setCriteria}
                                        />

                                        <ConfirmationModal
                                            showModal={showConfirmationModal}
                                            onToggleClick={() => setShowConfirmationModal(false)}
                                            header={f({ id: 'maintenances.delete.title' })}
                                            message={f({ id: 'maintenances.delete.message' },
                                                {
                                                    description:
                                                        maintenanceToDelete?.inventory.material.brand
                                                        + ' '
                                                        + maintenanceToDelete?.inventory.material.model
                                                })}
                                            cancelAction={onCancelDeleteClick}
                                            cancelButtonLabel={f({ id: "app.cancel" })}
                                            okAction={() => onConfirmDeleteClick(maintenanceToDelete?.id)}
                                            okButtonLabel={f({ id: "app.delete" })}
                                            loading={deleting}
                                            loadingMessage={f({ id: "app.deleting" })}
                                        />

                                        <div
                                            className={cx(styles["history-table-container"], "table-responsive table-scroll padding-right-10")}
                                            tabIndex="2">
                                            <Table
                                                cx={cx}
                                                styles={styles}
                                                columnsData={formatMaintenanceHistoryData(maintenanceHistory?.history, onEditMaintenance, onDeleteClick)}
                                                headers={getMaintenanceHistoryHeaders(inventoryType)}
                                                pagination={
                                                    <PaginationComponent
                                                        items={maintenanceHistory?.history || []}
                                                        pageIndex={maintenanceHistory?.pageIndex}
                                                        pageSize={PAGE_SIZE}
                                                        totalItems={maintenanceHistory?.totalItems}
                                                        onPageIndexChange={(newPage) => maintenanceHistory.onPageChange(newPage)}
                                                        totalPages={maintenanceHistory?.totalPages}
                                                    />
                                                } // pagination
                                            />
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </Col>
        </Row>
    ); // return
}; // EditInventoryContainer