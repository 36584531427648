import { get, post, put, deleteMethod } from "api/client";
import * as types from './types';

export const getUsers = async (dispatch, query) => {
  try {
    dispatch({ type: types.GET_USERS });

    let urlQuery = 'users?pageIndex=' + query.pageIndex + '&pageSize=' + query.pageSize;

    if (query.criteria) {
      urlQuery += '&criteria=' + query.criteria;
    }

    const data = await get(urlQuery);
    dispatch({ type: types.GET_USERS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: types.GET_USERS_FAILURE,
      error: { code: 500, description: error },
    });
  }
};

export const setPageIndex = (dispatch, pageIndex) => {
  dispatch( { type: types.SET_PAGE_INDEX, payload: pageIndex });
}

export const persistUser = async (dispatch, data) => {
  try {
    dispatch({ type: types.PERSIST_USER });
    let response = null
    if (data.id) {
      response = await put(`users/${data.id}`, data);
    } else {
      response = await post(`users`, data);
    }

    if (response.status === 204 || response.status === 201) {
      dispatch({ type: types.PERSIST_USER_SUCCESS });
    } else {
      dispatch({ type: types.PERSIST_USER_FAILURE, payload: response });
    }
    //TODO: Tratamiento de errores centralizado
  } catch (error) {

    dispatch({ type: types.PERSIST_USER_FAILURE, payload: error });
  }
};

export const getUser = async (dispatch, id) => {
  try {
    dispatch({ type: types.GET_USER });
    const response = await get(`users/${id}`);

    dispatch({ type: types.GET_USER_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: types.GET_USER_FAILURE});
  }
};

export const resendEmail = async (dispatch, id) => {
  try {
    dispatch({ type: types.RESEND_EMAIL, payload: id });
    await get(`users/resend-email?id=${id}`);

    dispatch({ type: types.RESEND_EMAIL_SUCCESS});
  } catch (error) {
    dispatch({ type: types.RESEND_EMAIL_FAILURE});
  }
};

export const deleteUser = async (dispatch, id) => {
  try {
    dispatch({ type: types.DELETE_USER });
    const response = await deleteMethod(`users/${id}`);

    if (response.status === 204 || response.status === 201) {
      dispatch({ type: types.DELETE_USER_SUCCESS });
    } else {
      dispatch({ type: types.DELETE_USER_FAILURE, payload: response });
    }
    //TODO: Tratamiento de errores centralizado
  } catch (error) {

    dispatch({ type: types.DELETE_USER_FAILURE, payload: error });
  }
};
