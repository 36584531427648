import { requestDateFilters } from "utils/enums";

export const initialRequestsState = {
  requests: [],
  loading: false,
  deleting: false,
  deleted: false,
  errorDeleting: false,
  request: null,
  saved: false,
  errorPersisting: false,
  boatInventories: [],
  vanInventories: [],
  trailerInventories: [],
  trailerMaterialTypes: [],
  trailerTypes: [],
  vanTypes: [],
  boatTypes: [],
  rooms: [],
  changedStatus: false,
  statusError: false,
  dateHasChange: false,
  persisting: false,
  updatingRequest: false,
  pageIndex: 1,
  total: 0,
  totalPages: 0,
  criteria: "",
  dateFromFilter: '',
  dateUntilFilter: '',
  dateType: requestDateFilters[0],
  cleaned: false,
  statusFilter: [],
  guests: [],
  filterByCity: false,
  rentingsForRequest: [],
  inventories: [],
  materialDatesHaveChanged: false,
  roomDatesHaveChanged: false,
};

export const requestsReducer = (initialState = initialRequestsState, action) => {
  switch (action.type) {

    case "SET_SEARCH_TEXT":
      return {
        ...initialState,
        criteria: action.payload,
        pageIndex: 1
      };

    case "SET_DATE_FROM_FILTER":
      return {
        ...initialState,
        dateFromFilter: action.payload,
        pageIndex: 1
      };

    case "SET_DATE_UNTIL_FILTER":
      return {
        ...initialState,
        dateUntilFilter: action.payload,
        pageIndex: 1
      };

    case "SET_DATE_TYPE":
      return {
        ...initialState,
        dateType: action.payload,
        dateUntilFilter: '',
        dateFromFilter: ''
      };

    case "SET_STATUS_FILTER":
      return {
        ...initialState,
        statusFilter: action.payload
      };

    case "CLEAN_FILTERS":
      return {
        ...initialState,
        dateType: requestDateFilters[0],
        dateUntilFilter: '',
        dateFromFilter: '',
        criteria: '',
        statusFilter: [],
        pageIndex: 1,
        filterByCity: false,
        cleaned: true
      }

    case "RESET_CLEAN_FILTERS":
      return {
        ...initialState,
        cleaned: false
      }

    case "GET_REQUESTS":
      return { ...initialState, requests: [], loading: true };

    case "GET_REQUESTS_SUCCESS":
      return {
        ...initialState,
        requests: action.payload.content,
        total: action.payload.totalElements,
        totalPages: action.payload.totalPages,
        loading: false
      };

    case "GET_REQUESTS_FAILURE":
      return { ...initialState, requests: [], loading: false };

    case "GET_REQUEST":
      return { ...initialState, loading: true };

    case "GET_REQUEST_SUCCESS":
      const request = action.payload;

      return {
        ...initialState,
        request: request,
        loading: false,
      };

    case "GET_REQUEST_FAILURE":
      return { ...initialState, request: {}, loading: false };

    case "GET_AVAILABLE_INVENTORIES":
      return { ...initialState, inventories: [], loading: true };

    case "GET_AVAILABLE_INVENTORIES_SUCCESS":
      return { ...initialState, inventories: action.payload, loading: false };

    case "GET_AVAILABLE_INVENTORIES_FAILURE":
      return { ...initialState, inventories: [], loading: false };

    case "SET_DATE_HAS_CHANGE":
      return { ...initialState, dateHasChange: true };

    case "RESET_DATE_HAS_CHANGE":
      return { ...initialState, dateHasChange: false };

    case "GET_TRAILER_TYPES":
      return { ...initialState, trailerTypes: [], loading: true };

    case "GET_TRAILER_TYPES_SUCCESS":
      return { ...initialState, trailerTypes: action.payload, loading: false };

    case "GET_TRAILER_TYPES_FAILURE":
      return { ...initialState, trailerTypes: [], loading: false };

    case "GET_VAN_TYPES":
      return { ...initialState, vanTypes: [], loading: true };

    case "GET_VAN_TYPES_SUCCESS":
      return { ...initialState, vanTypes: action.payload, loading: false };

    case "GET_VAN_TYPES_FAILURE":
      return { ...initialState, vanTypes: [], loading: false };

    case "GET_BOAT_TYPES":
      return { ...initialState, boatTypes: [], loading: true };

    case "GET_BOAT_TYPES_SUCCESS":
      return { ...initialState, boatTypes: action.payload, loading: false };

    case "GET_BOAT_TYPES_FAILURE":
      return { ...initialState, boatTypes: [], loading: false };

    case "GET_ROOMS":
      return { ...initialState, rooms: [], loading: true };

    case "GET_ROOMS_SUCCESS":
      return { ...initialState, rooms: action.payload, loading: false };

    case "GET_ROOMS_FAILURE":
      return { ...initialState, rooms: [], loading: false };

    case "UPDATE_REQUEST_PROPOSAL":
      return { ...initialState, requestProposal: action.payload, loading: false };

    case "PERSIST_MODIFIED_REQUEST":
      return { ...initialState, persisting: true, saved: false };

    case "PERSIST_MODIFIED_REQUEST_SUCCESS":
      const requestAux = { ...initialState.request };
      return { ...initialState, persisting: false, saved: true, request: requestAux };

    case "PERSIST_MODIFIED_REQUEST_FAILURE":
      return { ...initialState, persisting: false, saved: false, errorPersisting: true };

    case "RESET_SAVED":
      return { ...initialState, saved: false };

    case "RESET_ERROR":
      return { ...initialState, saved: false, deleted: false, errorPersisting: false, errorDeleting: false };

    case "GET_REQUEST_PROPOSAL":
      return { ...initialState, loading: true };

    case "GET_REQUEST_PROPOSAL_SUCCESS":
      return { ...initialState, loading: false };

    case "GET_REQUEST_PROPOSAL_FAILURE":
      return { ...initialState, loading: false };

    case "CHANGE_REQUEST_STATUS":
      return { ...initialState, updatingRequest: true, changedStatus: false };

    case "CHANGE_REQUEST_STATUS_SUCCESS":
      return { ...initialState, updatingRequest: false, changedStatus: true };

    case "CHANGE_REQUEST_STATUS_FAILURE":
      return { ...initialState, updatingRequest: false, changedStatus: false, errorStatus: true };

    case "RESET_STATUS":
      return { ...initialState, changedStatus: false };

    case "CHANGE_REQUESTS_PAGE_INDEX":
      return { ...initialState, pageIndex: action.payload };

    case "DELETE_REQUEST":
      return { ...initialState, deleting: true };

    case "DELETE_REQUEST_SUCCESS":
      return { ...initialState, deleting: false, deleted: true };

    case "DELETE_REQUEST_FAILURE":
      return { ...initialState, deleting: false, errorDeleting: true };

    case "RESET_DELETED":
      return { ...initialState, deleted: false, errorDeleting: false };

    case "GET_REQUEST_GUESTS":
      return { ...initialState, guests: [], loading: true };

    case "GET_REQUEST_GUESTS_SUCCESS":
      return { ...initialState, guests: action.payload, loading: false };

    case "GET_REQUEST_GUESTS_FAILURE":
      return { ...initialState, guests: [], loading: false };

    case "PERSIST_REQUEST_GUESTS":
      return { ...initialState, persisting: true, saved: false, errorPersisting: false };

    case "PERSIST_REQUEST_GUESTS_SUCCESS":
      return { ...initialState, persisting: false, saved: true, errorPersisting: false };

    case "PERSIST_REQUEST_GUESTS_FAILURE":
      return { ...initialState, persisting: false, saved: true, errorPersisting: true };

    case "REJECT_REQUEST_STATUS":
      return { ...initialState, persisting: true, saved: false, errorPersisting: false };

    case "REJECT_REQUEST_STATUS_SUCCESS":
      return { ...initialState, persisting: false, saved: true, errorPersisting: false };

    case "REJECT_REQUEST_STATUS_FAILURE":
      return { ...initialState, persisting: false, saved: true, errorPersisting: true };

    case "GET_RENTING_INVENTORIES_BY_REQUEST":
      return { ...initialState, rentingsForRequest: [], loading: true };

    case "GET_RENTING_INVENTORIES_BY_REQUEST_SUCCESS":
      return { ...initialState, rentingsForRequest: action.payload, loading: false };

    case "GET_RENTING_INVENTORIES_BY_REQUEST_FAILURE":
      return { ...initialState, rentingsForRequest: [], loading: false };

    case "UPDATE_RESERVATION_DATES":
      const newRequest = Object.assign({}, initialState.request);
      newRequest.roomCheckInDate = action.payload.roomCheckInDate;
      newRequest.roomCheckOutDate = action.payload.roomCheckOutDate;

      const modifiedGuests = [...initialState.guests];
      modifiedGuests.forEach(guest => {
        guest.room = null;
      });
      return { ...initialState, request: newRequest, guests: modifiedGuests, loading: false };

    case "MATERIAL_DATES_HAVE_CHANGED":
      return { ...initialState, materialDatesHaveChanged: action.payload };

    case "ROOM_DATES_HAVE_CHANGED":
      return { ...initialState, roomDatesHaveChanged: action.payload };

    default:
      return { ...initialState };
  }
};
