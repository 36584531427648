import cx from 'classnames';
import styles from 'pages/Views.module.scss';
import { ConfirmationModal } from 'components/Shared/ConfirmationModal/ConfirmationModal';
import { useEffect, useState } from 'react';
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "state";
import { changeRequestStatus } from "state/requests";
import { requestStatusLabels } from "utils/enums";
import './invoice.css';

import { GuestsTable } from 'components/GuestsTable';
import moment from "moment";
import 'moment/locale/es';
import { getRequestById, getRequestGuests } from 'state/requests/actions';
import { getRoomsWithOccupation } from 'state/rooms/actions';
import { ActionButton } from './ActionButton';
import { MaterialTable } from './MaterialTable/MaterialTable';
import { RoomTable } from './RoomTable';
moment.locale('es');

export const RequestInvoice = (props) => {
    const { request } = props
    const { formatMessage: f } = useIntl();
    const dispatch = useDispatch();

    const { updatingRequest, guests } = useSelector("requests");

    const [showActions, setShowActions] = useState(false);
    const [newStatus, setNewStatus] = useState(undefined);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const requestId = request?.id;

    useEffect(() => {
        if (!showConfirmationModal) {
            setNewStatus(undefined);
        }
    }, [showConfirmationModal]);

    const onClickActions = () => {
        setShowActions(!showActions)
    }

    const hideOptions = () => {
        setShowActions(false)
    }

    const onChangeStatusClick = (status) => {
        setNewStatus(status);
        setShowConfirmationModal(true);

    }
    const onAcceptRequest = async () => {
        await changeRequestStatus(dispatch, { id: request.id, status: newStatus });
        setShowConfirmationModal(false);

        //Para refrescar el componente de nuevo
        await getRequestById(dispatch, request.id);
    }

    useEffect(() => {

        const getRequestGuestsFunction = async () => {
            await getRoomsWithOccupation(dispatch, request);
            await getRequestGuests(dispatch, requestId);
        }

        if (requestId && request.roomNumberOfBeds && (request.roomCheckInDate || request.roomCheckOutDate)) {
            getRequestGuestsFunction();
        }
    }, [requestId]);

    return (

        <div className="col-lg-12 col-xl-6 col-sm-6 col-xs-12">
            <div className={cx(styles["widget"], styles["overflow-scroll"], "has-shadow", "w-100")}>
                <div className="invoice has-shadow h-100">
                    <ConfirmationModal
                        showModal={showConfirmationModal}
                        onToggleClick={() => setShowConfirmationModal(false)}
                        header={f({ id: "request.changeStatus.title" })}
                        message={f({ id: "request.changeStatus.message" }, { newStatus: requestStatusLabels[newStatus] })}
                        cancelAction={() => setShowConfirmationModal(false)}
                        cancelButtonLabel={f({ id: "app.cancel" })}
                        okAction={onAcceptRequest}
                        okButtonLabel={f({ id: "app.confirm" })}
                        loading={updatingRequest}
                        loadingMessage={f({ id: "app.updating" })}
                    />
                    <div className="invoice-container">
                        <div className="invoice-top">
                            <div className="row d-flex align-items-center">
                                <div className="col-xl-6 col-md-6 col-sm-6 col-6">
                                    <h1>Pedido</h1>
                                    <span>{"NUM. " + request.locator}</span>
                                </div>
                                <ActionButton
                                    onClickActions={onClickActions}
                                    setShowActions={setShowActions}
                                    showActions={showActions}
                                    onAcceptRequest={onChangeStatusClick}
                                    request={request}
                                    hideOptions={hideOptions}
                                    updatingRequest={updatingRequest}
                                    f={f}
                                />
                            </div>
                        </div>
                        <div className="invoice-header">
                            <div className="row d-flex align-items-baseline">
                                <div className="col-6 d-flex justify-content-start mb-2">
                                    <div className="details">
                                        <ul>
                                            <li className="company-name">{request.name + " " + request.surname}</li>
                                            <li>{"DNI: " + request.identityDocument.toUpperCase()}</li>
                                            <li>{"Email: " + request.email}</li>
                                            <li>{"Tel: " + request.phoneNumber}</li>
                                        </ul>
                                    </div>
                                </div>
                                {
                                    request.materialEventName &&
                                    <div className="col-6 d-flex justify-content-end mb-2">
                                        <div className="client-details">
                                            <ul>
                                                <li className="title">Concentración</li>
                                                <li>{request.materialEventName}</li>
                                            </ul>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        {
                            request.observations && <div className="invoice-header">
                                <div className="row d-flex align-items-center">
                                    <div className="col-12 d-flex justify-content-start mb-2">
                                        <div className="details">
                                            <ul>
                                                <li className="company-name">Observaciones</li>
                                                <li>{request.observations}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="invoice-date d-flex">
                            <span>{moment(request.createdDate, moment.ISO_8601).format("DD MMMM YYYY")}</span>
                        </div>

                        {request.materialRequest?.length > 0 &&
                            <MaterialTable
                                materials={request.materialRequest}
                                materialCollectionDate={request.materialCollectionDate}
                                materialCollectionPlace={request.materialCollectionPlace}
                                materialReturnDate={request.materialReturnDate}
                                materialReturnPlace={request.materialReturnPlace}
                                observation={request.observations}
                            />
                        }

                        {request.roomNumberOfBeds &&
                            <RoomTable
                                roomNumberOfBeds={request.roomNumberOfBeds}
                                roomCheckInDate={request.roomCheckInDate}
                                roomCheckOutDate={request.roomCheckOutDate}
                            />
                        }

                        {
                            guests?.length > 0 &&
                            <GuestsTable
                                guests={guests} />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}