import moment from "moment";
import 'moment/locale/es';
moment.locale('es');

export const GuestsTable = ({ guests }) => {
    return (

        <div className="col-xl-12 desc-tables">
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th className="text-center">DNI</th>
                            <th className="text-center">Nombre</th>
                            <th className="text-center">Apellidos</th>
                            <th className="text-center">Género</th>
                            <th className="text-center">Cargo</th>
                        </tr>
                    </thead>
                    <tbody className="table-body-border-none">
                        {
                            guests?.map((guest, index) => {
                                return (
                                    <tr key={`${index}-${guest.dni}`}>
                                        <td className="text-center">{guest.dni}</td>
                                        <td className="text-center">{guest.firstName}</td>
                                        <td className="text-center">{guest.lastName}</td>
                                        <td className="text-center">{guest.gender === 'M' ? 'Hombre' : 'Mujer'}</td>
                                        <td className="text-center">{guest.position}</td>
                                    </tr>
                                ); // return
                            }) // map
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}