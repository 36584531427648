import cx from 'classnames';
import { NavBar } from 'components/Shared/Navbar';
import styles from './PrivateLayout.module.scss';

export const PrivateLayout = (props) => {
  const render = props.render;
  const Component = props.component;
  const route = props.route;

  return (
    <div>
      <NavBar/>
      <div className={cx(styles["container-fluid"])}>
        <div className="row flex-row">
          <div className="col-xl-12">
            {/* entityType is passed as a prop to AddNewEntityPage */}
            <Component route={route} render={render} entityType={route.entityType} />
          </div>
        </div>
      </div>
    </div>
  );
};