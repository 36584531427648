import cx from 'classnames';
import { PageHeader } from 'components/PageHeader';
import styles from 'pages/Views.module.scss';
import { useIntl } from "react-intl";
import { forms, PAGE_TITLES } from 'utils/utils';

export const AddNewEntityPage = ({ entityType }) => {
    const { formatMessage: f } = useIntl();

    const Form = forms[entityType];

    return (
        <div>
            <PageHeader title={PAGE_TITLES[entityType]} />
            <div className={cx(styles["widget"])}>
                <div className={cx(styles["widget-header"], styles["bordered"], styles["no-actions"], "d-flex align-items-center")}>
                    <h4>{f({ id: `${entityType}.add.title` })}</h4>
                </div>
                <div className={cx(styles["widget-body"])}>
                    <Form />
                </div>
            </div>
        </div>
    ); // return
} // AddNewMaterialTypePage