import moment from "moment";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'state';

import Loading from 'components/Loading/Loading';
import { SearchBarAndButtonHeader } from 'components/SearchBarAndButtonHeader';
import { PaginationComponent } from 'components/Shared/PaginationComponent/PaginationComponent';
import { Table } from 'components/Table';

import {
    requestStatus,
    requestStatusLabels
} from "utils/enums";

import { PAGE_SIZE } from "utils/utils";
import { RequestFilters } from "./RequestFilters/RequestFilters";


export const RequestsBody = ({
    applyFilters,
    cleanFilters,
    filterByCity,
    setFilterByCity,
}) => {

    const { formatMessage: f } = useIntl();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {
        loading,
        requests,
        pageIndex,
        total,
        totalPages,
    } = useSelector('requests');

    /**
     * Renders material collection and return dates.
     * 
     * @param {Object} data - The data object containing date information.
     * @returns {string} The formatted date range or a dash if no dates are available.
     */
    const renderMaterialDates = (data) => {
        let dates = '-';

        if (data.materialCollectionDate) {
            dates = formatDateRange(data.materialCollectionDate, data.materialReturnDate);
        } // if

        return dates;
    }; // renderMaterialDates


    /**
     * Renders room check-in and check-out dates.
     *
     * @param {Object} data - The data object containing date information.
     * @returns {string} The formatted date range or a dash if no dates are available.
     */
    const renderRoomDates = (data) => {
        let dates = '-';

        if (data.roomCheckInDate) {
            dates = formatDateRange(data.roomCheckInDate, data.roomCheckOutDate);
        } // if

        return dates;
    }; // renderRoomDates


    /**
     * Formats a date range into a string.
     *
     * @param {string} startDate - The start date in ISO 8601 format.
     * @param {string} endDate - The end date in ISO 8601 format.
     * @returns {string} The formatted date range in "DD/MM/YYYY - DD/MM/YYYY" format.
     */
    const formatDateRange = (startDate, endDate) => {
        return (
            moment(startDate, moment.ISO_8601).format("DD/MM/YYYY") +
            " - " +
            moment(endDate, moment.ISO_8601).format("DD/MM/YYYY")
        ); // return
    }; // formatDateRange

    /**
     * Renders table headers for a data table.
     *
     * @returns {JSX.Element} A table row element containing the table headers.
     */
    const tableHeaders = () => {
        return (
            <tr>
                <th scope="col">ID pedido</th>
                <th scope="col">Solicitante</th>
                <th scope="col">Concentración</th>
                <th scope="col">Fecha solicitud</th>
                <th scope="col">Fecha material</th>
                <th scope="col">Fecha habitación</th>
                <th scope="col">Estado</th>
                <th scope="col" className="width-10-percent">Acciones</th>
            </tr>
        )
    }

    /**
     * Gets a request's chip according to its status
     * @param {string} status - The request's status to get the chip by
     * @returns the chip according to the status
     */
    const getStatusChip = (status) => {
        const statusClasses = {
            [requestStatus.CONFIRMATION_PENDING]: "badge-text badge-text-small alert",
            [requestStatus.CONFIRMED_AND_ACCEPTANCE_PENDING]: "badge-text badge-text-small info",
            [requestStatus.ACCEPTED]: "badge-text badge-text-small success",
            [requestStatus.CANCELLED]: "badge-text badge-text-small danger",
        };

        const label = requestStatusLabels[status];
        const className = statusClasses[status];
        let chip;

        if (label && className) {
            chip = (
                <span className={className}>
                    {label}
                </span>
            ); // return
        }; // if

        return chip;
    } // getStatusChip

    /**
     * Renders table rows for each request in the array.
     *
     * @param {Array} array - The array of data objects to render.
     * @returns {JSX.Element[]} An array of table row elements.
     */
    const columnsData = (array) => {
        return array.map((data) => {
            return (
                <tr key={data.locator}>
                    <td>{data.locator}</td>
                    <td>{data.name + ' ' + data.surname}</td>
                    <td>{data.materialEventName ? data.materialEventName : ""}</td>
                    <td>{moment(data.createdDate, moment.ISO_8601).format("DD/MM/YYYY")}</td>
                    <td>{renderMaterialDates(data)}</td>
                    <td>{renderRoomDates(data)}</td>
                    <td>
                        <span style={{ width: "100px" }}>
                            {getStatusChip(data.status)}
                        </span>
                    </td>
                    <td className="td-actions">
                        <a
                            role="button"
                            href={() => false}
                            onClick={() => onEdit(data.id)}
                        >
                            <i className="la la-edit edit" />
                        </a>
                    </td>
                </tr>
            ); // return
        }); // map-return
    } // columnsData
    /**
     * Navigates to the edit page for the specified request ID.
     *
     * @param {string} id - The ID of the request to edit.
     */
    const onEdit = (id) => {
        navigate("/requests/" + id);
    }

    return (
        <div className="tab-pane fade show active mt-3" id="j-tab-1" role="tabpanel" aria-labelledby="just-tab-1">

            <SearchBarAndButtonHeader
                text={f({ id: 'requests.bodyTitle' })}
                showAddButton={false}
                criteriaChange={(text) => dispatch({ type: "SET_SEARCH_TEXT", payload: text })}
                filterText={true}
            />

            <RequestFilters
                applyFilters={applyFilters}
                cleanFilters={cleanFilters}
                filterByCity={filterByCity}
                setFilterByCity={setFilterByCity}
            />

            {
                loading ?
                    <Loading />
                    :
                    <Table
                        headers={tableHeaders()}
                        columnsData={columnsData(requests, onEdit)}
                        pagination={
                            <PaginationComponent
                                items={requests}
                                pageIndex={pageIndex}
                                pageSize={PAGE_SIZE}
                                totalItems={total}
                                onPageIndexChange={(newPage) => dispatch({ type: "CHANGE_REQUESTS_PAGE_INDEX", payload: newPage })}
                                totalPages={totalPages}
                            />
                        } // PaginationComponent
                    />
            } {/* loading? */}


        </div>
    ); // return
} // RequestsBody

export default RequestsBody;