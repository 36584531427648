import { useEffect } from 'react';
import DatePicker from "react-multi-date-picker";
import Select from "react-select";
import { Row } from "reactstrap";
import { useDispatch, useSelector } from "state";
import { getCities } from 'state/citiesSlice';
import { addDaysToDate, getTodaysDate } from 'utils/dateUtils';
import { months, weekDays } from "utils/enums";
import reactSelectCustomProps from "utils/reactSelectCustomProps";
import { CustomDateInput } from './CustomDateInput';

export const MaterialProposalDates = ({
    disabled,
    request,
    setRequest
}) => {
    const { cities } = useSelector('cities');

    const dispatch = useDispatch();

    const convertCityToOption = (cityValue) => {
        let option = cityValue;
        if (!cityValue?.value) {
            option = cities.find(cityOption => cityOption.value === cityValue);
        }
        return option;
    } // convertCityToOption

    useEffect(() => {
        getCities();
    }, []);

    const onSetMaterialCollectionDate = (date) => {
        setRequest((prevRequest) => ({
            ...prevRequest,
            materialCollectionDate: date,
            materialReturnDate: null,
        }));

        dispatch({type: 'MATERIAL_DATES_HAVE_CHANGED', payload: true});
    }
    
    const onSetMaterialReturnDate = (date) => {
        setRequest((prevRequest) => ({
            ...prevRequest,
            materialReturnDate: date,
        }));
        dispatch({type: 'MATERIAL_DATES_HAVE_CHANGED', payload: true});
    }

    const onMaterialCollectionPlaceChange = (newCollectionPlace) => {
        setRequest((prevRequest) => ({
            ...prevRequest,
            materialCollectionPlace: newCollectionPlace.value,
        }));
    }

    const onMaterialReturnPlaceChange = (newReturnPlace) => {
        setRequest((prevRequest) => ({
            ...prevRequest,
            materialReturnPlace: newReturnPlace.value,
        }));
    }

    return (
        <Row>
            <div className="form-group row d-flex align-items-center mb-4">
                <label className="col-lg-3 form-control-label">Recogida</label>
                <div className="col-lg-5">
                    <div className="form-group">
                        <DatePicker
                            weekDays={weekDays}
                            months={months}
                            id="materialCollectionDate"
                            value={request.materialCollectionDate ? new Date(request.materialCollectionDate) : null}
                            onChange={(date) => onSetMaterialCollectionDate(date)}
                            containerClassName="d-block"
                            format={"DD/MM/YYYY"}
                            disabled={disabled}
                            minDate={getTodaysDate()}
                            render={<CustomDateInput />}
                        />
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="form-group">
                        <div className="input-group">
                            <Select
                                styles={reactSelectCustomProps}
                                options={cities}
                                noOptionsMessage={() => "No hay opciones disponibles"}
                                escapeClearsValue={true}
                                isDisabled={disabled}
                                placeholder="Seleccione una ciudad de recogida..."
                                value={convertCityToOption(request.materialCollectionPlace)}
                                onChange={(newOption) => onMaterialCollectionPlaceChange(newOption)}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="form-group row d-flex align-items-center mb-5">
                <label className="col-lg-3 form-control-label">Entrega</label>
                <div className="col-lg-5">
                    <div className="form-group">
                        <DatePicker
                            weekDays={weekDays}
                            months={months}
                            id="materialReturnDate"
                            value={request.materialReturnDate ? new Date(request.materialReturnDate) : null}
                            onChange={(date) => onSetMaterialReturnDate(date)}
                            containerClassName="d-block"
                            format={"DD/MM/YYYY"}
                            disabled={disabled}
                            render={<CustomDateInput />}
                            minDate={addDaysToDate(request?.materialCollectionDate, 1)}
                        />
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="form-group">
                        <div className="input-group">
                            <Select
                                styles={reactSelectCustomProps}
                                options={cities}
                                noOptionsMessage={() => "No hay opciones disponibles"}
                                escapeClearsValue={true}
                                isDisabled={disabled}
                                placeholder="Seleccione una ciudad de entrega..."
                                value={convertCityToOption(request.materialReturnPlace)}
                                onChange={(newOption) => onMaterialReturnPlaceChange(newOption)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Row>
    );
}