export const initialMaintenancesState = {
  loading: false,
  errorPersisting: false,
  persisting: false,
  saved: false,
  deleting: false,
  deleted: false,
  errorDeleting: false,
  maintenance: undefined,
  maintenancePageIndex: 1,
  maintenanceTotal: 0,
  maintenanceTotalPages: 0,
  maintenanceHistory: [],
};

export const maintenancesReducer = (initialState = initialMaintenancesState, action) => {
  switch (action.type) {

    case "CHANGE_MAINTENANCE_PAGE_INDEX":
      return { ...initialState, maintenancePageIndex: action.payload };

    case "GET_MAINTENANCE_HISTORY_FOR_INVENTORY":
      return { ...initialState, loading: true };

    case "GET_MAINTENANCE_HISTORY_FOR_INVENTORY_SUCCESS":
      return {
        ...initialState,
        maintenanceHistory: action.payload.content,
        maintenanceTotal: action.payload.totalElements,
        maintenanceTotalPages: action.payload.totalPages,
        loading: false
      };

    case "GET_MAINTENANCE_HISTORY_FOR_INVENTORY_FAILURE":
      return { ...initialState, maintenanceHistory: [], loading: false };

    case "GET_MAINTENANCE_BY_ID":
      return {
        ...initialState,
        loading: true,
        maintenance: undefined
      };

    case "GET_MAINTENANCE_BY_ID_SUCCESS":
      return {
        ...initialState,
        loading: false,
        maintenance: action.payload,
      };

    case "GET_MAINTENANCE_BY_ID_FAILURE":
      return {
        ...initialState,
        loading: false,
        maintenance: undefined,
      };

    case "PERSIST_MAINTENANCE":
      return {
        ...initialState,
        persisting: true,
        saved: false,
        errorPersisting: false
      };

    case "PERSIST_MAINTENANCE_SUCCESS":
      return {
        ...initialState,
        persisting: false,
        saved: true,
        errorPersisting: false
      };

    case "PERSIST_MAINTENANCE_FAILURE":
      return {
        ...initialState,
        persisting: false,
        saved: false,
        errorPersisting: true
      };


    case "RESET_PERSISTED":
      return { ...initialState, saved: false };

    case "DELETE_MAINTENANCE":
      return { ...initialState, deleting: true };

    case "DELETE_MAINTENANCE_SUCCESS":
      return { ...initialState, deleting: false, deleted: true, errorDeleting: false };

    case "DELETE_MAINTENANCE_FAILURE":
      return { ...initialState, deleting: false, deleted: false, errorDeleting: true };

    case "RESET_DELETED":
      return { ...initialState, deleted: false, errorDeleting: false };
    default:
      return { ...initialState };
  }
};
