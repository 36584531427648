import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import { Col, Form, Row } from "reactstrap";
import { useDispatch, useSelector } from 'state';
import { persistRoom } from "state/rooms";
import * as Yup from "yup";

const RoomForm = () => {

    const { saved, errorPersisting, room, persisting } = useSelector('rooms');
    const dispatch = useDispatch();
    const { formatMessage: f } = useIntl();
    const navigate = useNavigate();

    const [enabledOn, setEnabledOn] = useState(room.id ? room.enabled : null);
    const [enabledOff, setEnabledOff] = useState(room.id ? !room.enabled : null);

    useEffect(() => {
        if (saved) {
            if (!room.id) {
                NotificationManager.success("Creado correctamente");
                dispatch({ type: "RESET_SAVED" });
                dispatch({ type: "SET_SELECTED_TAB", payload: 1 });
                navigate("/rooms");
            } else {
                NotificationManager.success("Modificado correctamente");
                dispatch({ type: "RESET_SAVED" });
            }
        } else if (errorPersisting) {
            NotificationManager.error("Se ha producido un error");
            dispatch({ type: "RESET_ERROR" });
        }
    }, [saved, errorPersisting]);

    const roomSchema = Yup.object({
        name: Yup.string().required("Por favor, debes especificar un nombre."),
        enabled: Yup.string().required("Debes seleccionar una opción."),
        numberOfBeds: Yup.string().required("Por favor, debes especificar un número de camas."),
    }).required();

    const { register, handleSubmit, formState: { errors }, setValue } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(roomSchema),
        defaultValues: {
            ...room
        },
    });

    const onEnabledOnChange = () => {
        setEnabledOn(true)
        setEnabledOff(false)
        setValue("enabled", true);
    }

    const onEnabledOffChange = () => {
        setEnabledOff(true)
        setEnabledOn(false)
        setValue("enabled", false);
    }

    const onSubmit = async (data) => {
        let clonedData = {
            ...data,
            enabled: data.enabled === "true",
        };


        await persistRoom(dispatch, clonedData);
    };

    return (
        <Form id="room-form" onSubmit={handleSubmit(onSubmit)}>

            <input
                id="id"
                name="id"
                type="hidden"
                {...register('id')}
            />

            <input
                id="enabled"
                name="enabled"
                type="hidden"
                {...register('enabled')}
            />

            <Row className="form-group row d-flex align-items-center mb-5">
                <label className="col-lg-4 form-control-label d-flex justify-content-lg-end">{f({ id: 'room.name.label' })} </label>
                <Col className="col-lg-5">
                    <input
                        type="text"
                        name="name"
                        id="name"
                        {...register('name')}
                        className={errors?.name ? "form-control form-control-invalid" : "form-control"}
                        placeholder={f({ id: 'room.name.placeholder' })}
                    />
                    {errors?.name && (
                        <div className="invalid-feedback">
                            {errors?.name.message}
                        </div>
                    )
                    }
                </Col>
            </Row>

            <Row className="form-group row d-flex align-items-center mb-5">
                <label className="col-lg-4 form-control-label d-flex justify-content-lg-end">{f({ id: 'room.numberOfBeds.label' })} </label>
                <Col className="col-lg-5">
                    <input
                        type="number"
                        name="numberOfBeds"
                        id="numberOfBeds"
                        {...register('numberOfBeds')}
                        className={errors?.name ? "form-control form-control-invalid" : "form-control"}
                        placeholder={f({ id: 'room.numberOfBeds.placeholder' })}
                    />
                    {errors?.numberOfBeds && (
                        <div className="invalid-feedback">
                            {errors?.numberOfBeds.message}
                        </div>
                    )
                    }
                </Col>
            </Row>

            <div className="form-group row mb-5">
                <label className="col-lg-4 form-control-label d-flex justify-content-lg-end">{f({ id: 'room.enabled.label' })}</label>
                <Col className="col-lg-1">
                    <div className="custom-control custom-radio styled-radio mb-3">
                        <input
                            className="custom-control-input"
                            type="radio"
                            name="enabled"
                            checked={enabledOn}
                            onChange={() => onEnabledOnChange()}
                            id="opt-01"
                        />
                        <label className="custom-control-descfeedback" htmlFor="opt-01">{f({ id: 'room.enabled.yes' })}</label>
                    </div>
                    {errors?.enabled && (
                        <div className="invalid-feedback">
                            {errors?.enabled.message}
                        </div>
                    )
                    }
                </Col>
                <Col className="col-lg-2">
                    <div className="custom-control custom-radio styled-radio mb-3">
                        <input
                            className="custom-control-input"
                            type="radio"
                            name="noEnabled"
                            checked={enabledOff}
                            onChange={() => onEnabledOffChange()}
                            id="opt-02" />
                        <label className="custom-control-descfeedback" htmlFor="opt-02">{f({ id: 'room.enabled.no' })}</label>

                    </div>
                </Col>
            </div>

            <div className="em-separator separator-dashed" />

            <div className="d-flex flex-row-reverse">
                <button disabled={persisting} className="btn btn-shadow" type="reset">Limpiar</button>
                <button disabled={persisting} form="room-form" className="btn btn-primary mr-1" type="submit">{persisting ? f({ id: 'app.saving' }) : f({ id: 'app.save' })}</button>
            </div>
        </Form>
    );
}

export default RoomForm;