import cx from 'classnames';
import { useIntl } from "react-intl";
import styles from 'pages/Views.module.scss';
import { useParams } from "react-router-dom";
import { PageHeader } from 'components/PageHeader';
import { InventoryForm } from 'components/Form/Inventory';
import { PAGE_TITLES } from 'utils/utils';
import { useEffect } from 'react';
import { getCities } from 'state/citiesSlice';

const AddInventoryPage = () => {
    const { formatMessage: f } = useIntl();

    const { materialType: materialTypeUrl } = useParams();
    
    // Removes the last character
    const materialType = materialTypeUrl?.slice(0, -1);

    useEffect(() => {
        getCities();
    }, []);

    return (
        <div>
            <PageHeader title={PAGE_TITLES[materialType]} />

            <div className={cx(styles["widget"])}>
                <div className={cx(styles["widget-header"], styles["bordered"], styles["no-actions"], "d-flex align-items-center")}>
                    <h4>{f({ id: `${materialType}Inventory.add.title` })}</h4>
                </div>

                <div className={cx(styles["widget-body"])}>
                    <InventoryForm
                        materialType={materialType}
                    />
                </div>
            </div>
        </div>

    );
}

export default AddInventoryPage;