import moment from "moment";
import 'moment/locale/es';
import '../invoice.css';
moment.locale('es');

export const RoomTable = (props) => {
    return (

        <div className="col-xl-12 desc-tables">
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th className="text-left">Habitación</th>
                            <th className="text-center">Núm camas</th>
                            <th className="text-center">Entrada</th>
                            <th className="text-center">Salida</th>
                        </tr>
                    </thead>
                    <tbody className="table-body-border-none">
                        <tr>
                            <td className="text-left">Solicitud de habitación</td>
                            <td className="text-center">{props.roomNumberOfBeds}</td>
                            <td className="text-center">{moment(props.roomCheckInDate, moment.ISO_8601).format("DD.MM.YYYY")}</td>
                            <td className="text-center">{moment(props.roomCheckOutDate, moment.ISO_8601).format("DD.MM.YYYY")}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}