import logo from "assets/images/logo.png";
import cx from 'classnames';
import { Spinner } from 'reactstrap';
import styles from './Loading.module.scss';

const Loading = () => {
    return <div className="d-flex justify-content-center w-100">
        <div className={cx(styles.canvas, "d-flex flex-column align-items-center")}>
            <img src={logo} alt="logo" className={styles["loader-logo"]} />
            <Spinner
                as="span"
                animation="grow"
                role="status"
                className="mr-1"
                aria-hidden="true"
                variant="primary"
                style={{ width: "2.5rem", height: "2.5rem" }}
            />
        </div>
    </div>

}

export default Loading;