import Switch from "react-switch";
import { useDispatch, useSelector } from "state";
import { materials } from "utils/enums";

const CalendarFilters = () => {

    const {
        checkBoatTypes,
        checkTrailerTypes,
        checkVanTypes,
        checkRooms
    } = useSelector('dashboard');

    const dispatch = useDispatch();

    /**
     * Changes the switch status and dispatches the corresponding action.
     *
     * @param {boolean} status - The new status of the switch.
     * @param {string} switchType - The type of the switch (e.g., 'BOAT', 'TRAILER', 'VAN', 'ROOM').
     */
    const changeSwitchStatus = (status, switchType) => {
        // Mapping of switch types to their corresponding action types
        const actionTypes = {
            [materials.BOAT]: 'UPDATE_CHECK_BOAT_TYPES',
            [materials.TRAILER]: 'UPDATE_CHECK_TRAILER_TYPES',
            [materials.VAN]: 'UPDATE_CHECK_VAN_TYPES',
            'ROOM': 'UPDATE_CHECK_ROOMS'
        };

        // Dispatches the corresponding action if the switch type is valid
        if (actionTypes[switchType]) {
            dispatch({ type: actionTypes[switchType], payload: status });

            // Prevents re-rendering the bed information before turning the switch off
            if (switchType === 'ROOM' && !status) {
                dispatch({ type: 'RESET_ROOM_OCCUPATION_BY_DATE' });
            } // if
        } // if
    }; // changeSwitchStatus

    return (
        <div className="widget has-shadow">

            <div className="widget-header bordered d-flex align-items-center">
                <h2>Filtrar por...</h2>
            </div>

            <div className="widget-body">
                <div className="row">
                    <div className="col-3">
                        <div className="fc-event-container">
                            <div className="fc-event fc-bg-darkblue mt-0 d-flex justify-content-between align-items-center">
                                <div className="fc-content">
                                    <span className="fc-title">
                                        <i className="la la-ship" />
                                        Embarcaciones
                                    </span>
                                </div>
                                <Switch
                                    id="boat-switch"
                                    className={checkBoatTypes ? "boat-switch" : ""}
                                    onChange={(status) => changeSwitchStatus(status, materials.BOAT)}
                                    checked={checkBoatTypes}
                                    onHandleColor="#023047"
                                    handleDiameter={25}
                                    uncheckedIcon={false}
                                    height={13}
                                    width={45}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="fc-event-container">
                            <div className="fc-event fc-bg-blue ui-draggable ui-draggable-handle d-flex justify-content-between align-items-center">
                                <div className="fc-content">
                                    <span className="fc-title">
                                        <i className="la la-car" />
                                        Remolques
                                    </span>
                                </div>
                                <Switch
                                    id="trailer-switch"
                                    className={checkTrailerTypes ? "trailer-switch" : ""}
                                    onChange={(status) => changeSwitchStatus(status, materials.TRAILER)}
                                    checked={checkTrailerTypes}
                                    onHandleColor="#08a6c3"
                                    handleDiameter={25}
                                    uncheckedIcon={false}
                                    height={13}
                                    width={45}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                />
                            </div>

                        </div>
                    </div>
                    <div className="col-3">
                        <div className="fc-event-container">
                            <div className="fc-event fc-bg-lightblue ui-draggable ui-draggable-handle d-flex justify-content-between align-items-center">
                                <div className="fc-content">
                                    <span className="fc-title">
                                        <i className="la la-truck" />
                                        Furgonetas
                                    </span>
                                </div>
                                <Switch
                                    id="van-switch"
                                    className={checkVanTypes ? "van-switch" : ""}
                                    onChange={(status) => changeSwitchStatus(status, materials.VAN)}
                                    checked={checkVanTypes}
                                    onHandleColor="#8ecae6"
                                    handleDiameter={25}
                                    uncheckedIcon={false}
                                    height={13}
                                    width={45}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="fc-event-container">
                            <div className="fc-event fc-bg-orange ui-draggable ui-draggable-handle d-flex justify-content-between align-items-center">
                                <div className="fc-content">
                                    <span className="fc-title">
                                        <i className="la la-bed" />
                                        Habitaciones
                                    </span>
                                </div>
                                <Switch
                                    id="room-switch"
                                    className={checkRooms ? "room-switch" : ""}
                                    onChange={(status) => changeSwitchStatus(status, 'ROOM')}
                                    checked={checkRooms}
                                    onHandleColor="#f7aa1d"
                                    handleDiameter={25}
                                    uncheckedIcon={false}
                                    height={13}
                                    width={45}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CalendarFilters;