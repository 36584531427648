import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "state";

import { getAllMaterialTypes } from "state/requests";

import { EditRequestContainer } from 'components/EditRequestContainer';
import Loading from 'components/Loading/Loading';
import { PageHeader } from 'components/PageHeader';
import { getAllEnabledAndNotDeletedInventories, getAvailableInventories } from 'state/inventories/actions';
import { getInventoryRentingsByRequestId, getRequestById } from 'state/requests/actions';


export const EditRequestPage = () => {
  const { request } = useSelector("requests");
  const { inventoriesForRequest } = useSelector("inventories");
  const { id } = useParams("id");

  const dispatch = useDispatch();
  const [fetchDescriptions, setFetchDescriptions] = useState(false);

  useEffect(() => {
    const getRequestFunction = async () => {
      await getRequestById(dispatch, id);
      await getInventoryRentingsByRequestId(dispatch, id);
    }
    getRequestFunction();
  }, []);

  useEffect(() => {

    if (request?.id) {

      //Trae todos los tipos de material y todos los inventarios
      const allInventories = async () => {
        await getAvailableInventories(dispatch, request);
        const finish = await getAllMaterialTypes(dispatch);
        setFetchDescriptions(finish);
        await getAllEnabledAndNotDeletedInventories(dispatch, 0, 200);
      } // allInventories
      allInventories();
    } // if
  }, [request]);

  const isLoading = !fetchDescriptions || !request.id || inventoriesForRequest.length === 0;

  return (
    <>
      <PageHeader
        title={request?.id ? "Editar pedido " + request.locator : "Pedido"}
      />
      {
        isLoading || request.deleted === true ?
          <Loading />
          :
          <EditRequestContainer
            request={request}
          />
      }
    </>
  );
}