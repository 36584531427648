import { forwardRef } from "react";

export const FromDateCustomInput = forwardRef(({ value, openCalendar, placeholder, showIcon}, ref) => (
    
    <div className="input-group">
        <div hidden={showIcon || true}>
            <span className="input-group-addon">
                <i className="la la-calendar" />
            </span>
        </div>
        <input onClick={openCalendar} type="text" value={value} className="form-control" id="date-from" placeholder={placeholder}/>
    </div>
));